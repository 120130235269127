/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import {
  Button,
  Card,
  List,
  ListItem,
  ListItemButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import {
  getAllsearchFilters,
  // getParticalSearchFilters,
  setSelectedFilters,
} from "../../redux/librarySlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ModalComponent from "../../component/Modal";
// import InputSearchIcon from "../../assets/images/SearchIcon/InputSearchIcon";
// import UserModalToolbar from "../../pages/SearchResult/UserModalToolbar";
import { useAccount } from "../../component/context/AccountProvider";

function MiuiLibrarySelectPopup({ searchType, fetchListData, close, open,
  placeholder, ddname, activeTab, query, setSelected, genericFilterHandler, value }) {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const loadRef = useRef(null);

  const {
    companyList,
    governingLawList,
    departmentList,
    industryList,
    contractTitleList,
    clausesList,
    cikList,
    accessionNumberList,
    entityTypeList,
    formsList,
    stockExchangesList,
    countryList,
    stateOfIncorporationList,
    exhibitIDList,
    counterPartyList,
  } = useSelector((state) => state.library);

  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [loadOptions, setLoadOptions] = useState();
  const [filterName, setFilterName] = useState('');
  const { userDetails } = useAccount();



  useEffect(() => {
    if (ddname === "ContractType") {
      setLoadOptions(contractTitleList);
      debugger
      loadRef.current = contractTitleList;
    }
    if (ddname === "Industry") {
      setLoadOptions(industryList);
      loadRef.current = industryList;
    }
    if (ddname === "Department") {
      setLoadOptions(departmentList);
      loadRef.current = departmentList;
    }
    if (ddname === "Clauses") {
      setLoadOptions(clausesList);
      loadRef.current = clausesList;
    }
    if (ddname === "GoverningLaw") {
      setLoadOptions(governingLawList);
      loadRef.current = governingLawList;
    }
    if (ddname === "Country") {
      setLoadOptions(countryList);
      loadRef.current = countryList;
    }
    if (ddname === "CIK") {
      debugger
      setLoadOptions(cikList);
      loadRef.current = cikList;
    }
    if (ddname === "AccessionNumber") {
      setLoadOptions(accessionNumberList);
      loadRef.current = accessionNumberList;
    }
    if (ddname === "EntityType") {
      setLoadOptions(entityTypeList);
      loadRef.current = entityTypeList;
    }
    if (ddname === "Forms") {
      setLoadOptions(formsList);
      loadRef.current = formsList;
    }
    if (ddname === "StockExchanges") {
      setLoadOptions(stockExchangesList);
      loadRef.current = stockExchangesList;
    }
    if (ddname === "ExhibitID") {
      setLoadOptions(exhibitIDList);
      loadRef.current = exhibitIDList;
    }
    if (ddname === "StateOfIncorporation") {
      setLoadOptions(stateOfIncorporationList);
      loadRef.current = stateOfIncorporationList;
    }
    if (ddname === "CounterParty") {
      setLoadOptions(counterPartyList);
      loadRef.current = counterPartyList;
    }
  }, [
    governingLawList,
    departmentList,
    industryList,
    contractTitleList,
    clausesList,
    cikList,
    accessionNumberList,
    entityTypeList,
    formsList,
    stockExchangesList,
    countryList,
    stateOfIncorporationList,
    exhibitIDList,
    counterPartyList,
  ]);

  useEffect(() => {
    setSelectedOptions(value || []);
    setChecked(value.map((item) => item.name) || []);
  }, [value]);

  const [checked, setChecked] = useState([0]);



  const handleToggle = (item, value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    const currenSelectedIndex = selectedOptions.indexOf(item);
    const newSelected = [...selectedOptions];

    if (currentIndex === -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(currenSelectedIndex, 1);
    }
    setSelectedOptions(newSelected);
  };

  const handleSave = () => {
    debugger
    dispatch(setSelected(selectedOptions));
    dispatch(setSelectedFilters({ data: ddname, value: selectedOptions }));
    // dispatch(genericFilterHandler(userDetails.id ? userDetails.id : '', searchType, activeTab, query));
    dispatch(genericFilterHandler());

    close();
  };

  const handleScroll = () => {
    debugger;
    const container = containerRef.current;
    if (
      Math.floor(container.scrollHeight - container.scrollTop) ===
      container.clientHeight
    ) {
      debugger;
      console.log("loadRef", loadRef);
      dispatch(getAllsearchFilters(searchType, activeTab, query, loadRef.current?.data?.length + 10, ddname));
    }
  };

  useEffect(() => {
    debugger;
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);


  // const handleFilterByName = (event) => {
  //   debugger
  //   setFilterName(event.target.value);
  //   if (event.target.value.length >= 2) {
  //     handleInFilterSearch(event.target.name, event.target.value, 100)
  //   }
  //   else if (event.target.value.length === 0) {
  //     handleInFilterSearch(event.target.name, event.target.value, 10)
  //   }
  // };


  // const handleInFilterSearch = async (name, value, limit, action) => {
  //   //api call will come here

  //   let payload
  //   payload = {
  //     "_query": query,
  //     "_index": activeTab?.contractType ? 'contracts' : activeTab?.clause ? 'clauses' : activeTab?.definition ? 'definitions' : '',
  //     "partial_search_key":
  //       name === "Industry" ? 'industry' :
  //         name === "GoverningLaw" ? "governing_law" :
  //           name === "Country" ? "country" :
  //             name === "Department" ? "departments" :
  //               name === "Clauses" ? "clauses" :
  //                 name === "AccessionNumber" ? "accession_number" :
  //                   name === "EntityType" ? "entity_type" :
  //                     name === "Forms" ? "forms" :
  //                       name === "StockExchanges" ? "exchanges" :
  //                         name === "ExhibitID" ? "exhibit" :
  //                           name === "CIK" ? "cik_number" :
  //                             name === "StateOfIncorporation" ? "state_of_incorporation" :
  //                               name === "CounterParty" ? "party_counter_party" : ''
  //     ,
  //     "filters": {
  //       "company": [],
  //       "industry": name === "Industry" ? value ? [value] : [] : [],
  //       "governing_law": name === "GoverningLaw" ? value ? [value] : [] : [],
  //       "country": name === "Country" ? value ? [value] : [] : [],
  //       "departments": name === "Department" ? value ? [value] : [] : [],
  //       "clauses": name === "Clauses" ? value ? [value] : [] : [],
  //       "accession_number": name === "AccessionNumber" ? value ? [value] : [] : [],
  //       "entity_type": name === "EntityType" ? value ? [value] : [] : [],
  //       "forms": name === "Forms" ? value ? [value] : [] : [],
  //       "exchanges": name === "StockExchanges" ? value ? [value] : [] : [],
  //       "exhibit": name === "ExhibitID" ? value ? [value] : [] : [],
  //       "include_keywords": [],
  //       "exclude_keywords": [],
  //       "cik_number": name === "CIK" ? value ? [value] : [] : [],
  //       "state_of_incorporation": name === "StateOfIncorporation" ? value ? [value] : [] : [],
  //       "party_counter_party": name === "CounterParty" ? [value] : []
  //     },
  //   }

  //   dispatch(getParticalSearchFilters(payload, ddname, limit));
  //   if (action === 'close') {
  //     close()
  //   }
  // }

  return (
    <>
      <ModalComponent
        width="calc(100% - 30px)"
        maxHeight='412px'
        maxWidth="400px"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        // handleClose={() => handleInFilterSearch(ddname, '', 10, 'close')}
        handleClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-filter-modal-container"
      >
        <div className="custom-filter-modal">
          <Card>
            <Stack>
              <h3 className="modal-heading"> Select {placeholder} </h3>
            </Stack>
          </Card>


          {/* <div className="filter-search-container">
            <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} ddname={ddname} />
          </div> */}
          <List
            ref={containerRef}
            sx={{
              height: "302px",
              overflow: "auto",
              width: "100%",
              // maxWidth: 360,
              bgcolor: "background.paper",
              padding: "10px 6px",
              borderBottom: "1px solid #d1d9e0",
              fontFamily: "Satoshi"
            }}
          >
            {console.log("loadOptions", loadOptions)}
            {loadOptions?.data.length ? (
              loadOptions?.data?.map((item, i) => {
                const labelId = `checkbox-list-label-${item.name}`;
                return (
                  <li key={i} disablePadding className="filter-list-item customcheck">
                    <ListItemButton
                      dense
                      disablePadding
                      sx={{ paddingTop: '0', paddingBottom: '0' }}
                    >
                      {console.log("checked", checked)}
                      <input type="checkbox" id={item.name} checked={checked.indexOf(item?.name) !== -1} />
                      <label htmlFor={item.name} onClick={handleToggle(item, item.name)}> {item.name} </label>

                    </ListItemButton>
                  </li>
                );
              })
            ) : (
              <ListItem> No Data Found </ListItem>
            )}
          </List>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
            className="modal-footer-actions"
          >
            <Button color="primary"
              // onClick={() => handleInFilterSearch(ddname, '', 10, 'close')}
              onClick={close}
              className="cancel-btn"
            >
              Cancel
            </Button>
            {loadOptions?.data.length ? (
              <Button
                variant="contained"
                onClick={() => handleSave()}
                className="select-btn"
              >
                Apply
              </Button>
            ) : (
              ""
            )}
          </Stack>
        </div>
      </ModalComponent >
    </>
  );
}

export default MiuiLibrarySelectPopup;
