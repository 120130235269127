import React from "react";

const FullPageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
    >
      <rect x="0.5" y="1" width="23" height="19" rx="3.5" stroke="#216DDE" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 17.0455C20 17.2965 19.7558 17.5 19.4545 17.5L15.5455 17.5C15.2442 17.5 15 17.251 15 17C15 16.749 15.2442 16.4798 15.5455 16.4798L19 16.5V12.9545C19 12.7035 19.1533 12.5 19.4545 12.5C19.7558 12.5 20 12.7035 20 12.9545L20 17.0455Z"
        fill="#216DDE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 17.0455C4 17.2965 4.24421 17.5 4.54545 17.5L8.45455 17.5C8.75579 17.5 9 17.251 9 17C9 16.749 8.75579 16.4798 8.45455 16.4798L5 16.5V12.9545C5 12.7035 4.8467 12.5 4.54545 12.5C4.24421 12.5 4 12.7035 4 12.9545L4 17.0455Z"
        fill="#216DDE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 3.95455C4 3.70351 4.24421 3.5 4.54545 3.5L8.45455 3.5C8.75579 3.5 9 3.74896 9 4C9 4.25104 8.75579 4.52021 8.45455 4.52021L5 4.5V8.04545C5 8.29649 4.8467 8.5 4.54545 8.5C4.24421 8.5 4 8.29649 4 8.04545L4 3.95455Z"
        fill="#216DDE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 3.95455C20 3.70351 19.7558 3.5 19.4545 3.5L15.5455 3.5C15.2442 3.5 15 3.74896 15 4C15 4.25104 15.2442 4.52021 15.5455 4.52021L19 4.5V8.04545C19 8.29649 19.1533 8.5 19.4545 8.5C19.7558 8.5 20 8.29649 20 8.04545L20 3.95455Z"
        fill="#216DDE"
      />
    </svg>
  );
};

export default FullPageIcon;
