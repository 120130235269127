/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Header from '../../component/Header'
import { Outlet } from 'react-router-dom'
import { getMyUserDetails } from '../../service/UserService';
import { useDispatch } from 'react-redux';
import { IPSERAHCOUNT, USERSEPURCHASEPLAN, USERSERACHCOUNT, USER_DATA } from '../../redux/actions';
import { useAccount } from '../../component/context/AccountProvider';
import { getAllBlog, getAllPage } from '../../service/CMSService';
import { getUserSeachCount } from '../../service/Global';
import { fetchUserSearchCount, setSearchCount } from '../../redux/searchSlice';

function MainLayout() {
    const dispatch = useDispatch();
    const { setUserDetails } = useAccount();

    useEffect(() => {
        debugger
        if (localStorage.getItem('token') !== null) {
            getMyUserDetailsHandler();
        }
        else {
            dispatch(fetchUserSearchCount(''))
        }
    }, []);

    const getMyUserDetailsHandler = async () => {
        const res = await getMyUserDetails();
        dispatch({ type: USER_DATA, payload: res });
        console.log(res, 'resData');
        setUserDetails(res);
        dispatch(fetchUserSearchCount(res.id))
        dispatch({ type: IPSERAHCOUNT, payload: false });
        dispatch({ type: USERSERACHCOUNT, payload: false });
        dispatch({ type: USERSEPURCHASEPLAN, payload: false });
    };


    const [pages, setPages] = useState([]);
    const [blogs, setBlogs] = useState([]);

    const getAllPageHanlder = async () => {
        const res = await getAllPage();
        setPages(res.data.data || []);
    };

    useEffect(() => {
        getAllPageHanlder();
        getAllBlogHandler();

    }, []);

    const getAllBlogHandler = async () => {
        const res = await getAllBlog();
        setBlogs(res.data.data || []);
    };

    return (
        <>
            <Header pages={pages} blogs={blogs} />
            <div className="content-wrap ">
                <Outlet />
            </div>
        </>
    )
}

export default MainLayout