import React from "react";

const BackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M23.5 11.4998H1.089C1.164 11.2908 1.287 11.0988 1.453 10.9348L5.401 6.89175C5.594 6.69475 5.59 6.37775 5.392 6.18475C5.195 5.99275 4.879 5.99575 4.685 6.19375L0.744 10.2298C0.27 10.6968 0.006 11.3348 0 11.9998C0.002 12.6578 0.258 13.2958 0.716 13.7608L4.639 17.8468C4.737 17.9488 4.868 17.9998 5 17.9998C5.125 17.9998 5.25 17.9538 5.346 17.8598C5.545 17.6698 5.552 17.3518 5.361 17.1528L1.433 13.0618C1.273 12.8998 1.154 12.7078 1.082 12.4998H23.5C23.776 12.4998 24 12.2758 24 11.9998C24 11.7238 23.776 11.4998 23.5 11.4998Z"
        fill="#303030"
      />
    </svg>
  );
};

export default BackIcon;
