/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowBack,
  MdArrowDropDown,
  MdArrowDropUp,
  MdArrowForward,
  MdClose,
  MdRemoveRedEye,
  MdOutlineBookmarkAdd,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import {
  fetchAcessionNumberHandler,
  fetchCIKHandler,
  fetchClausesHandler,
  fetchCompanyHandler,
  fetchContractTitleHandler,
  fetchCountryHandler,
  fetchDepartmentHandler,
  fetchEntityTypeHandler,
  fetchExhibitIDHandler,
  fetchFormsHandler,
  fetchGoverningLawHandler,
  fetchIndustryHandler,
  fetchSearchDataHnadler,
  fetchStateOfIncorporationHandler,
  fetchStockExchangesHandler,
  genericFilterHandler,
  setDocData,
  setExcludeKeyword,
  setIncludeKeyword,
  setRemoveAllFilters,
  setSearchResult,
  setSearchType,
  setSelectedAccessionNumberList,
  setSelectedCIKList,
  setSelectedClause,
  setSelectedContractTitle,
  setSelectedCounterPartyList,
  setSelectedCountry,
  selectedCounterParty,
  setSelectedDate,
  setSelectedDepartment,
  setSelectedEntityTypeList,
  setSelectedExcludeKeyword,
  setSelectedExhibitList,
  setSelectedFilters,
  setSelectedFormsList,
  setSelectedGoverningLaw,
  setSelectedIncludeKeyword,
  setSelectedIndustry,
  setSelectedStockExchangesList,
  setSelectedstateOfIncorporationList,
  fetchCounterPartyHandler,
  setSearchValue,
  setIsLoading,
  getAllsearchFilters,
  directSearchFilter,
  fetchUserSearchCount,
  setSearchCount,
  setSearchResultCount,
} from "../../redux/searchSlice";
import { boldString, checkArrays } from "../../utils/commonFunctions";
import Search from "../../component/Search";
import { Dropdown, Loader, SelectPicker } from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';
import styled from "styled-components";
import { CgScreenShot } from "react-icons/cg";
import ViewFullScreen from "./ViewFullScreen";
import { useAccount } from "../../component/context/AccountProvider";
import {
  createBookmark,
  getAllbookmarkList,
  removeBookmark,
} from "../../service/BookmarkService";
import Toast from "../../component/Toast";
import SaveBM from "./SaveBM";
import MiuiAsyncSelect from "../../ui-component/MiuiAsyncSelect";
import { useDebouncedCallback } from "use-debounce";
import ModalComponent from "../../component/Modal";
import MiuiSelectPopup from "../../ui-component/MiuiSelectPopup";
import { Cancel } from "@mui/icons-material";
import { getSigleContarctData, getUserSeachCount } from "../../service/Global";
import ChipTagSelect from "../../ui-component/ChipTagSelect";
import ChipInput from "material-ui-chip-input";
import SignUpPopup from "../../ui-component/Popup/SignUpPopup";
import FreeAccessReachedLimitModal from "../../ui-component/Popup/FreeAccessReachedLimitModal";
import YourPremiumSubsWillExpirySoonPopup from "../../ui-component/Popup/YourPremiumSubsWillExpirySoonPopup";
import YourSubsExpiredPopup from "../../ui-component/Popup/YourSubsExpiredPopup";
import UpgradePlanPopup from "../../ui-component/Popup/UpgradePlanPopup";
import DropdownIcon from "../../assets/images/SearchIcon/DropdownIcon";
import EyeIcon from "../../assets/images/SearchIcon/EyeIcon";
import GoBackIcon from "../../assets/images/SearchIcon/GoBackIcon";
import GoNextIcon from "../../assets/images/SearchIcon/GoNextIcon";
import DateIcon from "../../assets/images/SearchIcon/DateIcon";
import GoNextFilterIcon from "../../assets/images/SearchIcon/GoNextFilterIcon";
import GoPrevIcon from "../../assets/images/SearchIcon/GoPrevIcon";
import FullPageIcon from "../../assets/images/SearchIcon/FullPageIcon";
import BookmarkIcon from "../../assets/images/SearchIcon/BookmarkIcon";
import MoreFiltersModal from "./MoreFiltersModal";
import { IPSERAHCOUNT, UPGRADEPLAN, USERSERACHCOUNT } from "../../redux/actions";
import { toast } from "react-toastify";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#005287",
  backgroundColor: "transparent",
  borderColor: "#005287",
}));

const data = [
  { label: "Relevance", value: "relevance" },
  { label: "Latest", value: "newest" },
  { label: "Older", value: "oldest" },
];

const SearchResultHeader = ({ setPreviewShow, userId, searchType, searchData, activeTab }) => {

  return (
    <div className="search-result-found-header">
      <div>
        <p>
          <span>Found: </span>
          {searchData?.searchResultCount} Results of {searchData?.searchValue}
        </p>
      </div>
      <div id="sort-by-section">
        <span>Sort by: </span>

        <SortBySection
          activeTab={activeTab}
          userId={userId}
          searchType={searchType}
          setPreviewShow={setPreviewShow}
        />
      </div>
    </div>
  );
};
const SortByButton = ({ ref, ...props }) => {
  console.log(props, ref, "propsData");
  return (
    <button {...props} ref={ref}>
      {props?.title}
      <DropdownIcon />
    </button>
  );
};

export const SortBySection = ({ setPreviewShow, userId, searchType, activeTab, CustomButton }) => {
  const { query } = useParams();
  const dispatch = useDispatch();
  const [selectedSort, setSelectedSort] = useState(data[0]?.label);
  return (
    <Dropdown
      renderToggle={(props, ref) => {
        console.log(props, "propsData");
        return (
          <>
            {CustomButton ? (
              <CustomButton
                {...(Object.keys(props)?.length ? props : {})}
                ref={ref}
                title={selectedSort}
              />
            ) : (
              <SortByButton
                {...(Object.keys(props)?.length ? props : {})}
                ref={ref}
                title={selectedSort}
              />
            )}
          </>
        );
      }}
      className="sort-by-section"
    >
      {data?.map((item) => {
        return (
          <Dropdown.Item
            value={item.value}
            onClick={() => {
              setSelectedSort(item?.label);
              dispatch(genericFilterHandler(userId, searchType, activeTab, query, null, 0, item.value));
              setPreviewShow(false);
            }}
          >
            {item.label}
          </Dropdown.Item>
        );
      })}
    </Dropdown >
  );
};

const SearchResult = () => {
  const { userDetails } = useAccount();
  const rootRef = useRef(null);
  const divRef = useRef(null);
  const previewDivRef = useRef(null);
  const { query } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  // const [searchValue, setSearchValue] = useState("");
  const {
    docData, isLoading, searchResult, inclueKeyword, excludeKeyword, searchResultCount, searchPageCount, governingLawList, selectedFilters,
    departmentList, industryList, contractTitleList, clausesList, cikList, accessionNumberList, entityTypeList, formsList, stockExchangesList,
    countryList, counterPartyList, stateOfIncorporationList, exhibitIDList, searchCount, searchType, searchValue, searchSuggestionValue
  } = useSelector((state) => state.search);
  let searchData = useSelector((state) => state.search);
  const [tabValue, setTabValue] = useState(0);
  const [previewShow, setPreviewShow] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [viewFullViewer, setViewFullViewer] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [customBookMarkModal, setCustomBookMarkModal] = useState(false);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [activeTab, setActiveTab] = useState(
    JSON.parse(localStorage.getItem("activeTab"))
  );
  const [showSaveDD, setShowSaveDD] = useState(false);



  useEffect(() => {
    dispatch(setIsLoading(true));
    hadleAccess()
  }, [userDetails]);



  const hadleAccess = async () => {
    // let count = fetchUserSearchCount()
    const response = await getUserSeachCount(userDetails.id || '');
    dispatch(setSearchCount(response.data.click_count || 0));

    if ((localStorage.getItem('token') === null && response?.data?.click_count <= 4) || (localStorage.getItem('token') !== null && response?.data?.click_count <= 9) || (localStorage?.getItem('token') !== null && userDetails?.user_subscription?.length)) {

      debugger
      dispatch(setSearchValue(query))
      let activeTabData = JSON.parse(localStorage.getItem("activeTab"));
      console.log("activeTabData", activeTabData);
      if (activeTabData?.contractType) {
        setTabValue(0);
      }
      if (activeTabData?.clause) {
        setTabValue(1);
      }
      if (activeTabData?.definition) {
        setTabValue(2);
      }
      debugger
      // const payload = {
      //   _query: query,
      //   user_id: '',
      //   type: searchType,
      //   _index: activeTab?.contractType
      //     ? "contracts"
      //     : activeTab?.clause
      //       ? "clauses"
      //       : "definitions",
      //   filters: {},
      // };
      // if (searchParams.get("company")) {
      //   payload.filters = {
      //     ...payload.filters,
      //     company: [+searchParams.get("company")],
      //   };
      // }
      // if (searchParams.get("industry")) {
      //   payload.filters = {
      //     ...payload.filters,
      //     industry: [+searchParams.get("industry")],
      //   };
      // }
      // if (searchParams.get("governing_law")) {
      //   payload.filters = {
      //     ...payload.filters,
      //     governing_law: [+searchParams.get("governing_law")],
      //   };
      // }

      debugger
      // dispatch(fetchSearchDataHnadler(payload));
      // dispatch(getAllsearchFilters(payload));
      dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue || query))
      dispatch(directSearchFilter(userDetails.id || '', searchType, activeTab, searchValue || query));
    }
    else {
      dispatch(setIsLoading(false))
      dispatch(setSearchResultCount(0))
      if (localStorage.getItem('token') === null && response.data.click_count >= 4) {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
      if (localStorage.getItem('token') !== null) {
        if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      }
      // if (localStorage.getItem('token') !== null && userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      // }
      // if (localStorage.getItem('token') !== null && !userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   dispatch({ type: USERSEPURCHASEPLAN, payload: true });
      // }
    }
  }

  // const screenIsopen = () => {
  //   if ((localStorage.getItem('token') === null && searchCount <= 4) || (localStorage.getItem('token') !== null && searchCount <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
  //   }
  //   else {
  //     dispatch(setIsLoading(false))
  //     dispatch(setSearchResultCount(0))
  //     if (localStorage.getItem('token') === null && searchCount >= 4) {
  //       dispatch({ type: IPSERAHCOUNT, payload: true });
  //     }
  //     if (localStorage.getItem('token') !== null) {
  //       if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
  //     }
  //     // if (localStorage.getItem('token') !== null && userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
  //     //   if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
  //     // }
  //     // if (localStorage.getItem('token') !== null && !userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
  //     //   dispatch({ type: USERSEPURCHASEPLAN, payload: true });
  //     // }
  //   }
  // }





  // useEffect(() => {
  //   dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
  // }, [activeTab]);

  useEffect(() => {
    if (!localStorage.getItem("activeTab")) {
      localStorage.setItem(
        "activeTab",
        JSON.stringify({ contractType: true, clause: false, definition: false })
      );
      setActiveTab({ contractType: true, clause: false, definition: false });
    }
    fetchBookmarkList();
  }, []);

  useEffect(() => {
    if (!searchResult?.results?.length) {
      setPreviewShow(false);
    }
  }, [searchResult]);

  useEffect(() => {
    if (searchResult?.results?.length) {
      if (activeTab?.contractType) {
        let newData = {
          ...searchResult?.contract[searchResult?.results[0]?._id],
          ...searchResult?.results[0],
        };
        dispatch(setDocData(newData));
      } else {
        handleDocDataonLoad();
      }
    }
  }, [searchResult?.results?.length]);


  const handleCheck = (value) => {
    debugger
    if (value === 'concept_search') {
      if (localStorage.getItem('token') !== null) {
        if (userDetails.user_subscription.length) {
          dispatch(setIsLoading(true));
          dispatch(genericFilterHandler(userDetails.id || '', value, activeTab, searchValue))
          dispatch(directSearchFilter(userDetails.id || '', value, activeTab, searchSuggestionValue || query));
          dispatch(setSearchType(value));
        }
        else { dispatch({ type: UPGRADEPLAN, payload: true }); }
      }
      else { dispatch({ type: IPSERAHCOUNT, payload: true }); }
    }
    else {
      dispatch(setIsLoading(true));
      dispatch(genericFilterHandler(userDetails.id || '', value, activeTab, searchValue))
      dispatch(directSearchFilter(userDetails.id || '', value, activeTab, searchSuggestionValue || query));
      dispatch(setSearchType(value));
    }
  };



  const handleDocDataonLoad = async () => {
    let previewData = await getSigleContarctData(
      searchResult.results[0].contract_id
    );
    let newData = { ...previewData.data, ...searchResult.results[0] };
    dispatch(setDocData(newData));
  };

  const onSelectHandler = async (item, index, name) => {
    debugger
    if (localStorage.getItem('token') !== null) {
      if (name === "clause" || name === "definition") {
        setPreviewShow(true);
        handleScrollToTop();
        setSaveModal(false);
        setCustomBookMarkModal(false);
        let previewData = await getSigleContarctData(item?.contract_id);
        let newData = { ...previewData.data, ...item };
        dispatch(setDocData(newData));
        const _temp = [...searchResult.results];
        // add selected true to selected item using map
        const temp = _temp?.map((el, i) => {
          if (i === index) {
            return { ...el, selected: true };
          } else {
            return { ...el, selected: false };
          }
        });
        dispatch(setSearchResult({ ...searchResult, results: temp }));
      } else {
        setPreviewShow(true);
        handleScrollToTop();
        setSaveModal(false);
        setCustomBookMarkModal(false);
        let previewData = searchResult?.contract[item?.contract_id];
        let newData = { ...previewData, ...item };
        dispatch(setDocData(newData));
        const _temp = [...searchResult.results];
        // add selected true to selected item using map
        const temp = _temp?.map((el, i) => {
          if (i === index) {
            return { ...el, selected: true };
          } else {
            return { ...el, selected: false };
          }
        });
        dispatch(setSearchResult({ ...searchResult, results: temp }));
      }
    }
    else {
      dispatch({ type: IPSERAHCOUNT, payload: true });
    }
  };

  const handleRemoveSelected = (arrayType, idToRemove) => {
    setPreviewShow(false);
    if (selectedFilters[arrayType]) {
      const updatedArray = selectedFilters[arrayType].filter(
        (item) => item._id !== idToRemove
      );

      debugger;
      let finalData = { ...selectedFilters, [arrayType]: updatedArray };

      dispatch(setSelectedFilters({ replaceData: finalData }));
      if (arrayType === "Industry") {
        dispatch(setSelectedIndustry(updatedArray));
      } else if (arrayType === "ContractType") {
        dispatch(setSelectedContractTitle(updatedArray));
      } else if (arrayType === "Department") {
        dispatch(setSelectedDepartment(updatedArray));
      } else if (arrayType === "Clauses") {
        dispatch(setSelectedClause(updatedArray));
      } else if (arrayType === "GoverningLaw") {
        dispatch(setSelectedGoverningLaw(updatedArray));
      } else if (arrayType === "Country") {
        dispatch(setSelectedCountry(updatedArray));
      } else if (arrayType === "CounterParty") {
        dispatch(setSelectedCounterPartyList(updatedArray));
      } else if (arrayType === "AccessionNumber") {
        dispatch(setSelectedAccessionNumberList(updatedArray));
      } else if (arrayType === "EntityType") {
        dispatch(setSelectedEntityTypeList(updatedArray));
      } else if (arrayType === "Forms") {
        dispatch(setSelectedFormsList(updatedArray));
      } else if (arrayType === "StockExchanges") {
        dispatch(setSelectedStockExchangesList(updatedArray));
      } else if (arrayType === "StateOfIncorporation") {
        dispatch(setSelectedstateOfIncorporationList(updatedArray));
      } else if (arrayType === "ExhibitID") {
        dispatch(setSelectedExhibitList(updatedArray));
      } else if (arrayType === "CIK") {
        dispatch(setSelectedCIKList(updatedArray));
      } else if (arrayType === "include") {
        const updatedSelectInclude = searchData?.includeKeyword.filter(
          (item) => item !== idToRemove
        );
        dispatch(setIncludeKeyword(updatedSelectInclude));
        dispatch(
          setSelectedIncludeKeyword(updatedArray.map((obj) => obj.name))
        );
      } else if (arrayType === "exclude") {
        const updatedSelectExclude = searchData?.includeKeyword.filter(
          (item) => item !== idToRemove
        );
        dispatch(setExcludeKeyword(updatedSelectExclude));
        dispatch(
          setSelectedExcludeKeyword(updatedArray.map((obj) => obj.name))
        );
      }
      dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
    }
  };

  const handleRemoveAllSelected = () => {
    setPreviewShow(false);
    dispatch(setRemoveAllFilters([]));
    dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
  };

  const handleDate = (date) => {
    setPreviewShow(false);
    dispatch(setSelectedDate(date));
    dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
  };

  const handleShortCutDate = (date) => {
    setPreviewShow(false);
    dispatch(setSelectedDate(date.value));
    dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
  };

  const handleRemove = () => {
    dispatch(setSelectedDate(""));
    dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
  };

  const handleBookmark = async (e, item) => {
    debugger
    if (localStorage.getItem('token') !== null) {
      if (e.target.name === 'my_library') {
        handleCheckUnchek(e, item)
      }
      else if (userDetails.user_subscription.length) {
        handleCheckUnchek(e, item)
      }
      else {
        dispatch({ type: UPGRADEPLAN, payload: true });
      }
    }
  };

  const handleCheckUnchek = async (e, item) => {
    const { name, value, checked } = e.target;
    if (!checked) {
      const res = await removeBookmark(item.id);
      if (res.message === "Bookmark deleted successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();
      } else {
        toast.error(res.message || `something went wrong`);
      }
    } else {
      let payload = {
        contract_id: docData?.contract_id,
        library_type: name,
        bookmark_type: activeTab?.contractType
          ? "contracts"
          : activeTab?.clause
            ? "clauses"
            : "definitions",
        bookmark_name: activeTab?.contractType
          ? docData?.contract_title
          : activeTab?.clause
            ? docData.heading
            : docData.define_term,
      };
      const res = await createBookmark(payload);
      if (res.message === "Bookmark Created Successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();
      } else {
        toast.error(res.message || `something went wrong`);
      }
    }
  }

  //fetch bookmark list
  const fetchBookmarkList = async () => {
    const res = await getAllbookmarkList();
    if (res.data) {
      setBookmarkList(res?.data);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      // Clicked outside the div, so remove the class
      setShowSaveDD(false);
    }
  };

  const handleWord = (value, name) => {
    debugger;
    setPreviewShow(false);
    if (name === "Include Keywords") {
      const objectsArray = [...searchData?.includeKeyword, value]
        ?.map((str, index) => {
          if (str.trim() !== "") {
            return {
              _id: str.trim(),
              name: str.trim(),
              label: str.trim(),
              value: str.trim(),
            };
          }
          return null;
        })
        .filter((obj) => obj !== null);
      dispatch(setIncludeKeyword([...searchData?.includeKeyword, value]));
      dispatch(
        setSelectedIncludeKeyword([...searchData?.includeKeyword, value])
      );
      dispatch(setSelectedFilters({ data: "include", value: objectsArray }));
      dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
    } else {
      const objectsArray = [...searchData?.excludeKeyword, value]
        ?.map((str, index) => {
          if (str.trim() !== "") {
            return {
              _id: str.trim(),
              name: str.trim(),
              label: str.trim(),
              value: str.trim(),
            };
          }
          return null;
        })
        .filter((obj) => obj !== null);
      dispatch(setExcludeKeyword([...searchData?.excludeKeyword, value]));
      dispatch(
        setSelectedExcludeKeyword([...searchData?.excludeKeyword, value])
      );
      dispatch(setSelectedFilters({ data: "exclude", value: objectsArray }));
      dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, searchValue));
    }
  };


  const regex = new RegExp(/<html\b[^>]*>[\s\S]*<\/html>/i);

  const [contractTypePopup, setContractTypePopup] = useState(false);
  const [industryPopup, setIndustryPopup] = useState(false);
  const [departmentPopup, setDepartmentPopup] = useState(false);
  const [clausesPopup, setClausesPopup] = useState(false);
  const [governingLawPopup, setGoverningLawPopup] = useState(false);
  const [countryPopup, setCountryPopup] = useState(false);
  const [entityTypePopup, setEntityTypePopup] = useState(false);
  const [stockExchangesPopup, setStockExchangesPopup] = useState(false);
  const [exhibitIDPopup, setExhibitIDPopup] = useState(false);
  const [cikPopup, setCikPopup] = useState(false);
  const [accessionPopup, setAccessionPopup] = useState(false);
  const [formPopup, setFormPopup] = useState(false);
  const [stateOfIncoPopup, setStateOfIncoPopup] = useState(false);
  const [counterPartyPopup, setCounterPartyPopup] = useState(false);
  const selectedFiltersScrollContainerRef = useRef(null);
  const [allFilters, setAllFilters] = useState({});

  const handleScrollToTop = () => {
    if (previewDivRef.current) {
      // Scroll to the top of the div
      previewDivRef.current.scrollTop = 0;
    }
  };
  const scroll = (direction) => {
    if (selectedFiltersScrollContainerRef.current) {
      const { scrollLeft, clientWidth } =
        selectedFiltersScrollContainerRef.current;
      const scrollAmount = direction === "next" ? clientWidth : -clientWidth;
      selectedFiltersScrollContainerRef.current.scrollTo({
        left: scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const renderFiltersBasedOnCondition = (id, comp) => {
    if (allFilters[id]?.checked) {
      return comp;
    } else {
      return null;
    }
  };


  const handlePreviewAccess = (value) => {
    if (localStorage.getItem('token') !== null) {
      setPreviewShow(value)
    }
    else {
      setPreviewShow(false)
      dispatch({ type: IPSERAHCOUNT, payload: true });
    }
  }



  const handleOpenPopup = (value) => {
    if ((localStorage.getItem('token') === null && searchCount <= 4) || (localStorage.getItem('token') !== null && searchCount <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
      if (value === 'ContractType') setContractTypePopup(true)
      if (value === 'Industry') setIndustryPopup(true)
      if (value === 'Department') setDepartmentPopup(true)
      if (value === 'Clauses') setClausesPopup(true)
      if (value === 'GoverningLaw') setGoverningLawPopup(true)
      if (value === 'Country') setCountryPopup(true)
      if (value === 'CounterParty') setCounterPartyPopup(true)
      if (value === 'CIK') setCikPopup(true)
      if (value === 'AccessionNumber') setAccessionPopup(true)
      if (value === 'EntityType') setEntityTypePopup(true)
      if (value === 'Forms') setFormPopup(true)
      if (value === 'StockExchanges') setStockExchangesPopup(true)
      if (value === 'ExhibitID') setExhibitIDPopup(true)
      if (value === 'StateOfIncorporation') setStateOfIncoPopup(true)
    }
    else {
      dispatch(setIsLoading(false))
      dispatch(setSearchResultCount(0))
      if (localStorage.getItem('token') === null && searchCount >= 4) {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
      if (localStorage.getItem('token') !== null) {
        if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      }
    }
  }


  return (
    <>
      {console.log("searchData", searchData)}
      {console.log("userDetails", userDetails)}

      {!viewFullViewer ? (
        <div className="gray_bg"
        // onClick={() => screenIsopen()}
        >
          <Container className="main-container">
            <Box className="search-main">
              <Box>
                <Box className="search-head">
                  <div className="search-left">
                    <Search
                      searchType={searchType}
                      activeTab={activeTab}
                      selectedQuery={searchValue}
                      fromSearchResult
                      setPreviewShow={setPreviewShow}
                    />
                    {/* <span>{searchResult && searchResultCount} results found</span> */}
                  </div>
                  <div className="home_banner_search_radio_left">
                    <div class="custom-radio-button">
                      <div>
                        <input
                          type="radio"
                          id="textSearch"
                          name="color"
                          value="text_search"
                          checked={searchType === "text_search"}
                        />
                        <label
                          for="textSearch"
                          onClick={() => handleCheck("text_search")}
                        >
                          <span> Text Search </span>
                        </label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="conceptSearch"
                          name="color"
                          value="concept_search"
                          checked={searchType === "concept_search"}
                        />
                        <label
                          for="conceptSearch"
                          onClick={() => handleCheck("concept_search")}
                        >
                          <span> Concept Search </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="filter">
                    <ColorButton
                      size="large"
                      variant="outlined"
                      endIcon={
                        moreFilter ? <MdArrowDropUp /> : <MdArrowDropDown />
                      }
                      onClick={() => setMoreFilter(!moreFilter)}
                    >
                      {moreFilter ? "Less Filter" : "More Filter"}
                    </ColorButton>
                  </div> */}
                </Box>
                <div className="search-result-head">
                  {/* <ul> <li> <Link to="/">Home</Link> </li> <li> {query .toLowerCase() .replace(/(^|\s)\S/g, (match) => match.toUpperCase())} </li> </ul> */}
                  <div className="filters-main">
                    <div className="filter-row">
                      {/* <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Contract Type"
                          ddname='ContractType'
                          loadOptions={contractTitleList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedContractTitle}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedContractTitle}
                          fetchListData={fetchContractTitleHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div> */}
                      <div
                        className="filter-col"
                        onClick={() => handleOpenPopup('ContractType')}
                      >
                        <MiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="Contract Type"
                          ddname="ContractType"
                          loadOptions={contractTitleList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedContractTitle}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedContractTitle}
                          fetchListData={fetchContractTitleHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      {/* <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Industry"
                          ddname='Industry'
                          loadOptions={industryList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedIndustry}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedIndustry}
                          fetchListData={fetchIndustryHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div> */}

                      <div
                        className="filter-col"
                        onClick={() => handleOpenPopup('Industry')}
                      >
                        <MiuiAsyncSelect
                          readOnly
                          multiple
                          placeholder="Industry"
                          ddname="Industry"
                          loadOptions={industryList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedIndustry}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedIndustry}
                          fetchListData={fetchIndustryHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      {/* <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Department"
                          ddname='Department'
                          loadOptions={departmentList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedDepartment}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedDepartment}
                          fetchListData={fetchDepartmentHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div> */}

                      <div
                        className="filter-col"
                        onClick={() => handleOpenPopup('Department')}
                      >
                        <MiuiAsyncSelect
                          readOnly
                          multiple
                          placeholder="Department"
                          ddname="Department"
                          loadOptions={departmentList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedDepartment}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedDepartment}
                          fetchListData={fetchDepartmentHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      {activeTab?.contractType && (
                        <>
                          {/* <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="Clauses"
                              ddname='Clauses'
                              loadOptions={clausesList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedClause}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedClause}
                              fetchListData={fetchClausesHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div> */}
                          {renderFiltersBasedOnCondition(
                            12,
                            <div
                              className="filter-col"
                              onClick={() => handleOpenPopup('Clauses')}
                            >
                              <MiuiAsyncSelect
                                readOnly
                                multiple
                                placeholder="Clauses"
                                ddname="Clauses"
                                loadOptions={clausesList}
                                activeTab={activeTab}
                                query={searchValue}
                                setSelected={setSelectedClause}
                                genericFilterHandler={genericFilterHandler}
                                searchType={searchType}
                                value={searchData?.selectedClause}
                                fetchListData={fetchClausesHandler}
                                setPreviewShow={setPreviewShow}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {/* <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Governing Law"
                          ddname='GoverningLaw'
                          loadOptions={governingLawList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedGoverningLaw}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedGoverningLaw}
                          fetchListData={fetchGoverningLawHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div> */}
                      <div
                        className="filter-col"
                        onClick={() => handleOpenPopup('GoverningLaw')}
                      >
                        <MiuiAsyncSelect
                          readOnly
                          multiple
                          placeholder="Governing Law"
                          ddname="GoverningLaw"
                          loadOptions={governingLawList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedGoverningLaw}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedGoverningLaw}
                          fetchListData={fetchGoverningLawHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      {/* <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Country"
                          ddname='Country'
                          loadOptions={countryList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedCountry || []}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedCountry}
                          fetchListData={fetchCountryHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div> */}

                      <div
                        className="filter-col"
                        onClick={() => handleOpenPopup('Country')}
                      >
                        <MiuiAsyncSelect
                          readOnly
                          multiple
                          placeholder="Country"
                          ddname="Country"
                          loadOptions={countryList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedCountry || []}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedCountry}
                          fetchListData={fetchCountryHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      <div
                        className="filter-col"
                        onClick={() => handleOpenPopup('CounterParty')}
                      >
                        <MiuiAsyncSelect
                          readOnly
                          multiple
                          placeholder="Party/Counter Party"
                          ddname="CounterParty"
                          loadOptions={counterPartyList}
                          activeTab={activeTab}
                          query={searchValue}
                          setSelected={setSelectedCounterPartyList || []}
                          genericFilterHandler={genericFilterHandler}
                          searchType={searchType}
                          value={searchData?.selectedCounterParty}
                          fetchListData={fetchCounterPartyHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      {/* <div className="filter-col">
                        <TextField
                          sx={{ width: '151px' }}
                          hiddenLabel
                          id="include-keywords"
                          placeholder="Include Keywords"
                          variant="outlined"
                          size="small"
                          defaultValue={searchData?.selectedincludeKeyword?.join(',')}
                          // value={searchData.selectedincludeKeyword.join(',')}
                          onChange={(e) => handleWord(e.target.value, 'include')}
                        />
                      </div> */}
                      {renderFiltersBasedOnCondition(
                        2,
                        <div className="filter-col">
                          <ChipInput
                            id="includekeyword"
                            variant="outlined"
                            color="primary"
                            size="small"
                            className="taginput"
                            hiddenLabel
                            alwaysShowPlaceholder={true}
                            dataSource={searchData?.includeKeyword}
                            value={searchData?.includeKeyword}
                            placeholder={"Include Keywords"}
                            onAdd={(chip) =>
                              handleWord(chip, "Include Keywords")
                            }
                          />
                        </div>
                      )}

                      {renderFiltersBasedOnCondition(
                        3,
                        <div className="filter-col">
                          <ChipInput
                            id="excludekeyword"
                            variant="outlined"
                            color="primary"
                            size="small"
                            className="taginput"
                            hiddenLabel
                            alwaysShowPlaceholder={true}
                            dataSource={searchData?.excludeKeyword}
                            value={searchData?.excludeKeyword}
                            placeholder={"Exclude Keywords"}
                            onAdd={(chip) =>
                              handleWord(chip, "Exclude Keywords")
                            }
                          />
                        </div>
                      )}

                      {/* <div className="filter-col">
                        <TextField
                          sx={{ width: '151px' }}
                          hiddenLabel
                          id="exclude-keywords"
                          placeholder="Exclude Keywords"
                          variant="outlined"
                          size="small"
                          value={excludeKeyword}
                          onChange={(e) => handleWord(e.target.value, 'exclude')}
                        />
                      </div> */}

                      {renderFiltersBasedOnCondition(
                        4,
                        <div className="filter-col" style={{ maxWidth: '223px' }}>
                          <DateRangePicker
                            style={{ width: '100%', height: '40px', fontSize: '15px' }}
                            cleanable
                            showOneCalendar
                            placeholder="Date Range"
                            defaultValue={searchData?.selectedDate?.length ? [new Date(searchData.selectedDate[0]), new Date(searchData.selectedDate[1]),] : []}
                            onOk={(date) => handleDate(date)}
                            onClean={(date) => handleRemove(date)}
                            onShortcutClick={(date) => handleShortCutDate(date)}
                          />
                        </div>
                      )}

                      <>
                        {renderFiltersBasedOnCondition(
                          5,
                          <div
                            className="filter-col"
                            onClick={() => handleOpenPopup('CIK')}
                          >
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="CIK"
                              ddname="CIK"
                              loadOptions={cikList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedCIKList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedCIKList}
                              fetchListData={fetchCIKHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        )}

                        {renderFiltersBasedOnCondition(
                          6,
                          <div
                            className="filter-col"
                            onClick={() => handleOpenPopup('AccessionNumber')}
                          >
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="Accession Number"
                              ddname="AccessionNumber"
                              loadOptions={accessionNumberList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedAccessionNumberList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedAccessionNumberList}
                              fetchListData={fetchAcessionNumberHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        )}

                        {/* <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="Entity Type"
                              ddname='EntityType'
                              loadOptions={entityTypeList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedEntityTypeList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedEntityTypeList}
                              fetchListData={fetchEntityTypeHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div> */}
                        {renderFiltersBasedOnCondition(
                          7,
                          <div
                            className="filter-col"
                            onClick={() => handleOpenPopup('EntityType')}
                          >
                            <MiuiAsyncSelect
                              readOnly
                              multiple
                              placeholder="Entity Type"
                              ddname="EntityType"
                              loadOptions={entityTypeList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedEntityTypeList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedEntityTypeList}
                              fetchListData={fetchEntityTypeHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        )}

                        {renderFiltersBasedOnCondition(
                          8,
                          <div
                            className="filter-col"
                            onClick={() => handleOpenPopup('Forms')}
                          >
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="Form"
                              ddname="Forms"
                              loadOptions={formsList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedFormsList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedFormsList}
                              fetchListData={fetchFormsHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        )}

                        {/* <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="Stock Exchange"
                              ddname='StockExchanges'
                              loadOptions={stockExchangesList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedStockExchangesList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedStockExchangesList}
                              fetchListData={fetchStockExchangesHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div> */}
                        {renderFiltersBasedOnCondition(
                          9,
                          <div
                            className="filter-col"
                            onClick={() => handleOpenPopup('StockExchanges')}
                          >
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="Stock Exchange"
                              ddname="StockExchanges"
                              loadOptions={stockExchangesList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedStockExchangesList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedStockExchangesList}
                              fetchListData={fetchStockExchangesHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        )}

                        {/* <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="Exhibit ID"
                              ddname='ExhibitID'
                              loadOptions={exhibitIDList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedExhibitList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedExhibitIDList}
                              fetchListData={fetchExhibitIDHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div> */}
                        {renderFiltersBasedOnCondition(
                          10,
                          <div
                            className="filter-col"
                            onClick={() => handleOpenPopup('ExhibitID')}
                          >
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="Exhibit ID"
                              ddname="ExhibitID"
                              loadOptions={exhibitIDList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedExhibitList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedExhibitIDList}
                              fetchListData={fetchExhibitIDHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        )}

                        {/* <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="State of Incorporation"
                              ddname='StateOfIncorporation'
                              loadOptions={stateOfIncorporationList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedstateOfIncorporationList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={searchData?.selectedStateOfIncorporationList}
                              fetchListData={fetchStateOfIncorporationHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div> */}
                        {renderFiltersBasedOnCondition(
                          11,
                          <div
                            className="filter-col"
                            onClick={() => handleOpenPopup('StateOfIncorporation')}
                          >
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="State of Incorporation"
                              ddname="StateOfIncorporation"
                              loadOptions={stateOfIncorporationList}
                              activeTab={activeTab}
                              query={searchValue}
                              setSelected={setSelectedstateOfIncorporationList}
                              genericFilterHandler={genericFilterHandler}
                              searchType={searchType}
                              value={
                                searchData?.selectedStateOfIncorporationList
                              }
                              fetchListData={fetchStateOfIncorporationHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        )}
                      </>
                    </div>
                    {console.log(data, "vkDebug")}
                    {/* <div className="sort-by">
                      <div className="filter-row">
                        <div className="filter-col">
                          <SelectPicker
                            data={data}
                            cleanable={false}
                            defaultValue="relevance"
                            placeholder="Sort by"
                            searchable={false}
                            style={{ width: 224 }}
                            onChange={(data) => {
                              dispatch(genericFilterHandler(userDetails.id ? userDetails.id : '', activeTab, query, null, 0, data)
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="filter">
                      <MoreFiltersModal
                        onClick={() => setMoreFilter(!moreFilter)}
                        title={!moreFilter ? "More Filter" : "Less Filter"}
                        setAllFilters={setAllFilters}
                      />
                    </div>
                  </div>

                  <div className="selected-filter">
                    {!checkArrays(selectedFilters) ? (
                      <button
                        onClick={() => scroll("prev")}
                        className="selected-filter-scroll-button"
                      >
                        <GoPrevIcon />
                      </button>
                    ) : null}

                    <ul ref={selectedFiltersScrollContainerRef}>
                      {Object.keys(selectedFilters).map((item, i) => {
                        return selectedFilters[item].length ? (
                          <li key={i}>
                            <h6>{item}: </h6>
                            <ul>
                              {selectedFilters[item]?.map((selected, index) => {
                                return (
                                  <li key={index}>
                                    <div className="selected">
                                      <span>{selected?.name}</span>
                                      <MdClose
                                        onClick={() =>
                                          handleRemoveSelected(
                                            item,
                                            selected?._id
                                          )
                                        }
                                      />
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        ) : (
                          ""
                        );
                      })}
                    </ul>

                    {!checkArrays(selectedFilters) ? (
                      <button
                        onClick={() => scroll("next")}
                        className="selected-filter-scroll-button"
                      >
                        <GoNextFilterIcon />
                      </button>
                    ) : null}

                    {Object.values(selectedFilters).some((array) => array.length > 0) ? (
                      <Button onClick={() => handleRemoveAllSelected()}>
                        Clear All
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => setTabValue(newValue)}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                  className="search-result-tab"
                  alignItems={"center"}
                >
                  <Tab
                    onClick={() => {
                      setActiveTab({ contractType: true, clause: false, definition: false });
                      localStorage.setItem("activeTab", JSON.stringify({ contractType: true, clause: false, definition: false, }));
                      setPreviewShow(false);
                      if ((localStorage.getItem('token') === null && searchCount <= 4) || (localStorage.getItem('token') !== null && searchCount <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
                        dispatch(genericFilterHandler(userDetails.id || '', searchType, { contractType: true, clause: false, definition: false }, searchValue, 'countSearch'));
                        dispatch(directSearchFilter(userDetails.id || '', searchType, { contractType: true, clause: false, definition: false }, searchSuggestionValue || query));
                      }
                    }}
                    label={`Contract Type`}
                    // label={`Contract Type ${contractCount > 0 ? `(${contractCount})` : ""}`}
                    className="search-result-tab-btn"
                    {...a11yProps(0)}
                  />
                  <Tab
                    onClick={() => {
                      setActiveTab({ contractType: false, clause: true, definition: false });
                      localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: true, definition: false, }));
                      setPreviewShow(false);
                      if ((localStorage.getItem('token') === null && searchCount <= 4) || (localStorage.getItem('token') !== null && searchCount <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
                        dispatch(genericFilterHandler(userDetails.id || '', searchType, { contractType: false, clause: true, definition: false }, searchValue, 'countSearch'));
                        dispatch(directSearchFilter(userDetails.id || '', searchType, { contractType: false, clause: true, definition: false }, searchSuggestionValue || query));
                      }
                    }}
                    label={`Clause`}
                    // label={`Clause  ${clauseCount > 0 ? `(${clauseCount})` : ""}`}
                    className="search-result-tab-btn"
                    {...a11yProps(1)}
                  />
                  <Tab
                    onClick={() => {
                      setActiveTab({ contractType: false, clause: false, definition: true });
                      localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: false, definition: true }));
                      setPreviewShow(false);
                      if ((localStorage.getItem('token') === null && searchCount <= 4) || (localStorage.getItem('token') !== null && searchCount <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
                        dispatch(genericFilterHandler(userDetails.id || '', searchType, { contractType: false, clause: false, definition: true }, searchValue, 'countSearch'));
                        dispatch(directSearchFilter(userDetails.id || '', searchType, { contractType: false, clause: false, definition: true }, searchSuggestionValue || query));
                      }
                    }}
                    label={`Definition`}
                    // label={`Definition ${definitionCount > 0 ? `(${definitionCount})` : ""}`}
                    className="search-result-tab-btn"
                    {...a11yProps(2)}
                  />
                  <div className="head-right">
                    <ul>
                      <li>
                        <span
                          className={`preview ${previewShow ? "active" : ""}`}
                          onClick={() => handlePreviewAccess(!previewShow)}
                        >
                          <EyeIcon />
                          Preview
                        </span>
                      </li>
                      <li>
                        <span>
                          Page {searchPageCount / 10 + 1} /
                          {Math.ceil(searchResultCount / 10)}
                        </span>
                      </li>
                      <li>
                        <div className="arrow">
                          <IconButton
                            aria-label="prev"
                            size="small"
                            disabled={searchPageCount / 10 + 1 === 1}
                            color="primary"
                            onClick={() => {
                              setPreviewShow(false);
                              if ((localStorage.getItem('token') === null && searchCount <= 4) || (localStorage.getItem('token') !== null && searchCount <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
                                dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, query, null, searchPageCount - 10));
                              }
                            }}
                          >
                            {/* <MdArrowBack /> */}
                            <GoBackIcon />
                          </IconButton>

                          <IconButton
                            aria-label="prev"
                            size="small"
                            disabled={
                              searchPageCount / 10 + 1 ===
                              Math.ceil(searchResultCount / 10)
                            }
                            color="primary"
                            onClick={() => {
                              setPreviewShow(false);
                              debugger
                              if ((localStorage.getItem('token') === null && searchCount <= 4) || (localStorage.getItem('token') !== null && searchCount <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
                                dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, query, null, searchPageCount + 10));
                              }
                            }}
                          >
                            <GoNextIcon />
                          </IconButton>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Tabs>
              </Box>
              {console.log("searchResult", searchResult)}

              {/* contract tab */}
              <CustomTabPanel
                value={tabValue}
                index={0}
                className="searchtab_content"
              >
                <Box className="search-result-body">
                  <div className={`left ${previewShow ? "preview-open" : ""}`}>
                    <SearchResultHeader
                      searchData={searchData}
                      activeTab={activeTab}
                      userId={userDetails.id}
                      searchType={searchType}
                      setPreviewShow={setPreviewShow}
                    />
                    <ul style={{ height: "calc(100% - 44px)" }}>
                      {isLoading ? (
                        <div style={{ height: "100%", position: "relative" }}>
                          <Loader center content="Loading Data..." />
                        </div>
                      ) : activeTab?.contractType &&
                        searchResult?.results?.length ? (
                        searchResult?.results?.map((item, i) => (
                          <li
                            className={
                              item?.selected && previewShow ? "selected" : ""
                            }
                            onClick={() => onSelectHandler(item, i)}
                          >
                            <div className="title">
                              {/* <span className="number">
                                {searchPageCount + (i + 1)}.
                              </span> */}
                              <h5>
                                {item?.contract_title
                                  ?.toLowerCase()
                                  .replace(/(^|\s)\S/g, (match) =>
                                    match?.toUpperCase()
                                  )}
                              </h5>
                            </div>
                            <div className="doc_content">
                              <div className="tags-main">
                                <ul>
                                  {item?.contract_title && (
                                    <li>
                                      <p>
                                        <b>Contract Type: </b>
                                        {item?.contract_title}
                                      </p>
                                    </li>
                                  )}
                                  {item?.industry && (
                                    <li>
                                      <p>
                                        <b>Industry: </b>
                                        {item?.industry || "NA"}
                                      </p>
                                    </li>
                                  )}
                                  {item?.department && (
                                    <li>
                                      <p>
                                        <b>Dept: </b>
                                        {item?.department
                                          ?.map((d) => d.department_type)
                                          .join(", ")}
                                      </p>
                                    </li>
                                  )}
                                  {item?.governing_law && (
                                    <li>
                                      <p>
                                        <b>Gov Law: </b>
                                        {item?.governing_law || "NA"}
                                      </p>
                                    </li>
                                  )}
                                  {item?.party_counter_party && (
                                    <li>
                                      <p>
                                        <b>Party/Counter Party: </b>
                                        {item?.party_counter_party
                                          ?.map((d) => d.party_counter_party_type)
                                          .join(", ")}
                                      </p>
                                    </li>
                                  )}
                                  {item?.filing_date && (
                                    <li>
                                      <p>
                                        <b>Filing Date: </b>
                                        {
                                          new Date(item?.filing_date)
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                      </p>
                                    </li>
                                  )}

                                </ul>
                              </div>
                              <div className="description">
                                {item?.description?.map((desc) => (
                                  <>
                                    <p>
                                      {searchData.selectedincludeKeyword
                                        .length ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: boldString(
                                              desc,
                                              searchData.selectedincludeKeyword
                                            ),
                                          }}
                                        ></div>
                                      ) : (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: boldString(desc, searchValue),
                                          }}
                                        ></div>
                                      )}
                                      {/* <span>{matchText(desc, query)[1]} </span>
                                        <b> {query} </b>
                                        <span> {matchText(desc, query)[2]}</span> */}
                                    </p>
                                  </>
                                ))}
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <h3 style={{ textAlign: "center" }}>
                            No Result Found
                          </h3>
                        </li>
                      )}
                    </ul>
                  </div>

                  {previewShow && (
                    <div className="right" ref={rootRef}>
                      <div>
                        <Stack
                          style={{ padding: "0 10px" }}
                          justifyContent="space-between"
                          flexDirection={'row'}
                        >
                          <span className="custombtn" onClick={() => setViewFullViewer(!viewFullViewer)} >
                            Go to Full Page <FullPageIcon />
                            {/* <CgScreenShot
                              fontSize={24}
                              style={{
                                transform: "rotate(90deg)",
                                marginRight: "5px",
                              }}
                            /> */}
                          </span>
                          <Stack className="save_dropdwon" alignItems="center">
                            <span
                              onClick={() => {
                                if (localStorage.getItem("token")) {
                                  setShowSaveDD(!showSaveDD);
                                } else {
                                  toast.warning(`Please Login First to save contract`);
                                  // dispatch({ type: IPSERAHCOUNT, payload: true });
                                }
                              }}
                            >
                              <BookmarkIcon />
                              {/* Save */}
                              {/* <MdOutlineBookmarkAdd
                                fontSize={24}
                                style={{ marginLeft: "5px" }}
                              /> */}
                            </span>
                            <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? "active" : ""}`}
                            >
                              <ul>
                                <li className="customcheck rightside">
                                  <input type="checkbox"
                                    id="My Library"
                                    name="my_library"
                                    checked={bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "my_library"}
                                    onChange={(e) => handleBookmark(e, bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                  />
                                  <label htmlFor="My Library"> My Library </label>
                                </li>

                                <li className="customcheck rightside">
                                  <input type="checkbox"
                                    id="Team Library"
                                    name="team_library"
                                    checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "team_library"}
                                    onChange={(e) => handleBookmark(e, bookmarkList.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                  />
                                  <label htmlFor="Team Library"> Team Library </label>
                                </li>

                                <li onClick={() => {
                                  if (localStorage.getItem('token') !== null && userDetails.user_subscription.length) {
                                    setSaveModal(true)
                                  }
                                  else {
                                    dispatch({ type: UPGRADEPLAN, payload: true });
                                  }
                                }}
                                >
                                  <span>
                                    Client Library
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                    </svg>
                                  </span>
                                </li>
                                <li onClick={() => setCustomBookMarkModal(true)} >
                                  <span>
                                    Custom Library
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                    </svg>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Stack>
                        </Stack>
                        {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                      </div>
                      {console.log("docData", docData)}
                      <div style={{ padding: "10px", overflow: "auto" }} ref={previewDivRef} >
                        {docData?.raw_text ? (
                          regex.test(docData?.raw_text) ? (
                            <div dangerouslySetInnerHTML={{ __html: docData?.raw_text, }} ></div>
                          ) : (
                            <pre> <div>{docData?.raw_text}</div> </pre>
                          )
                        ) : regex.test(docData?.formatted_text) ? (
                          <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />"), }} ></div>
                        ) : (
                          <pre> <div>{docData?.formatted_text}</div> </pre>
                        )}
                      </div>

                      <SaveBM
                        docData={docData}
                        bookmarkList={bookmarkList}
                        activeTab={activeTab}
                        fetchBookmarkList={fetchBookmarkList}
                        rootRef={() => rootRef?.current}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        customBookMarkModal={customBookMarkModal}
                        setCustomBookMarkModal={setCustomBookMarkModal}
                      />
                    </div>
                  )}
                </Box>
              </CustomTabPanel>

              {/* caluse tab */}
              <CustomTabPanel
                value={tabValue}
                index={1}
                className="searchtab_content"
              >
                <Box className="search-result-body">
                  <div className={`left ${previewShow ? "preview-open" : ""}`}>
                    <SearchResultHeader
                      searchData={searchData}
                      activeTab={activeTab}
                      userId={userDetails.id}
                      searchType={searchType}
                    />
                    <ul style={{ height: "calc(100% - 44px)" }}>
                      {isLoading ? (
                        <div style={{ height: "100%", position: "relative" }}>
                          <Loader center content="Loading Data..." />
                        </div>
                      ) : activeTab?.clause && searchResult?.results?.length ? (
                        searchResult?.results?.map((item, i) => (
                          <li
                            className={item?.selected && previewShow ? "selected" : ""}
                            onClick={() => onSelectHandler(item, i, "clause")}
                          >
                            <div className="title">
                              {/* <span className="number">
                                {searchPageCount + (i + 1)}.
                              </span> */}
                              <h5>{item?.heading}</h5>
                            </div>
                            <div className="doc_content">
                              <div className="tags-main">
                                <ul>
                                  {item?.contract_title && (
                                    <li>
                                      <p>
                                        <b>Contract Type: </b>
                                        {item?.contract_title}
                                      </p>
                                    </li>
                                  )}
                                  {item?.industry && (
                                    <li>
                                      <p>
                                        <b>Industry: </b> {item?.industry}
                                      </p>
                                    </li>
                                  )}
                                  {item?.department && (
                                    <li>
                                      <p>
                                        <b>Dept: </b>
                                        {item?.department
                                          ?.map((d) => d?.department_type)
                                          ?.join(", ")}
                                      </p>
                                    </li>
                                  )}
                                  {item?.governing_law && (
                                    <li>
                                      <p>
                                        <b>Gov Law: </b>
                                        {item?.governing_law || "NA"}
                                      </p>
                                    </li>
                                  )}
                                  {item?.party_counter_party && (
                                    <li>
                                      <p>
                                        <b>Party/Counter Party: </b>
                                        {item?.party_counter_party
                                          ?.map((d) => d.party_counter_party_type)
                                          .join(", ")}
                                      </p>
                                    </li>
                                  )}
                                  {item?.filing_date && (
                                    <li>
                                      <p>
                                        <b>Filing Date: </b>
                                        {
                                          new Date(item?.filing_date)
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                      </p>
                                    </li>
                                  )}

                                </ul>
                              </div>
                              {/* <div className="definitions">
                                  {searchData.selectedincludeKeyword.length ?
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.clause_content, searchData.selectedincludeKeyword) }} ></div>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.clause_content, query) }} ></div>
                                  }
                                </div> */}

                              <div className="description">
                                {item?.description?.map((desc) => (
                                  <>
                                    <p>
                                      {searchData.selectedincludeKeyword
                                        .length ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: boldString(
                                              desc,
                                              searchData.selectedincludeKeyword
                                            ),
                                          }}
                                        ></div>
                                      ) : (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: boldString(desc, searchValue),
                                          }}
                                        ></div>
                                      )}
                                    </p>
                                  </>
                                ))}
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <h3 style={{ textAlign: "center" }}>
                            No Result Found
                          </h3>
                        </li>
                      )}
                    </ul>
                  </div>
                  {console.log("docData", docData)}
                  {previewShow && (
                    <div className="right" ref={rootRef}>
                      <div>
                        <Stack
                          style={{ padding: "0 10px" }}
                          justifyContent="space-between"
                          flexDirection={'row'}
                        >
                          {/* <span
                            className="custombtn 123"
                            onClick={() => setViewFullViewer(!viewFullViewer)}
                          >
                            <CgScreenShot
                              fontSize={24}
                              style={{
                                transform: "rotate(90deg)",
                                marginRight: "5px",
                              }}
                            />
                            View Full Contract
                          </span> */}
                          <span className="custombtn" onClick={() => setViewFullViewer(!viewFullViewer)} >
                            Go to Full Page
                            <FullPageIcon />
                          </span>
                          <Stack className="save_dropdwon" alignItems="center">
                            <span
                              onClick={() => {
                                if (localStorage.getItem("token")) {
                                  setShowSaveDD(!showSaveDD);
                                } else {
                                  toast.warning(`Please Login First to save contract`);
                                }
                              }}
                            >
                              {/* Save
                              <MdOutlineBookmarkAdd
                                fontSize={24}
                                style={{ marginLeft: "5px" }}
                              /> */}
                              <BookmarkIcon />
                            </span>
                            <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? "active" : ""}`} >
                              <ul>
                                <li className="customcheck rightside">
                                  <input type="checkbox"
                                    id="My Library"
                                    name="my_library"
                                    checked={bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "my_library"}
                                    onChange={(e) => handleBookmark(e, bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                  />
                                  <label htmlFor="My Library"> My Library </label>
                                </li>

                                <li className="customcheck rightside">
                                  <input type="checkbox"
                                    id="Team Library"
                                    name="team_library"
                                    checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "team_library"}
                                    onChange={(e) => handleBookmark(e, bookmarkList.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                  />
                                  <label htmlFor="Team Library"> Team Library </label>
                                </li>

                                <li onClick={() => setSaveModal(true)}>
                                  <span>
                                    Client Library
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                    </svg>
                                  </span>
                                </li>

                                <li onClick={() => setCustomBookMarkModal(true)} >
                                  <span>
                                    Custom Library
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                    </svg>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Stack>
                        </Stack>
                        {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                      </div>
                      <div
                        style={{ padding: "10px", overflow: "auto" }}
                        ref={previewDivRef}
                      >
                        {docData?.raw_text ? (
                          regex.test(docData?.raw_text) ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: docData?.raw_text,
                              }}
                            ></div>
                          ) : (
                            <pre>
                              <div>{docData?.raw_text}</div>
                            </pre>
                          )
                        ) : regex.test(docData?.formatted_text) ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: docData?.formatted_text?.replace(
                                /\n/g,
                                "<br />"
                              ),
                            }}
                          ></div>
                        ) : (
                          <pre>
                            <div>{docData?.formatted_text}</div>
                          </pre>
                        )}
                      </div>
                      <SaveBM
                        docData={docData}
                        bookmarkList={bookmarkList}
                        activeTab={activeTab}
                        fetchBookmarkList={fetchBookmarkList}
                        rootRef={() => rootRef?.current}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        customBookMarkModal={customBookMarkModal}
                        setCustomBookMarkModal={setCustomBookMarkModal}
                      />
                    </div>
                  )}
                </Box>
              </CustomTabPanel>

              {/* definition tab */}

              <CustomTabPanel
                value={tabValue}
                index={2}
                className="searchtab_content"
              >
                <Box className="search-result-body">
                  <div className={`left ${previewShow ? "preview-open" : ""}`}>
                    <SearchResultHeader
                      searchData={searchData}
                      activeTab={activeTab}
                      userId={userDetails.id}
                      searchType={searchType}
                    />
                    <ul style={{ height: "calc(100% - 44px)" }}>
                      {isLoading ? (
                        <div style={{ height: "100%", position: "relative" }}>
                          <Loader center content="Loading Data..." />
                        </div>
                      ) : activeTab?.definition &&
                        searchResult?.results?.length ? (
                        searchResult?.results?.map((item, i) => (
                          <li className={item?.selected && previewShow ? "selected" : ""}
                            onClick={() => onSelectHandler(item, i, "definition")}
                          >
                            <div className="title">
                              {/* <span className="number">
                                {searchPageCount + (i + 1)}.
                              </span> */}
                              <h5>{item?.define_term}</h5>
                            </div>
                            <div className="doc_content">
                              <div className="tags-main">
                                <ul>
                                  {item?.contract_title && (
                                    <li>
                                      <p>
                                        <b>Contract Type: </b>
                                        {item?.contract_title}
                                      </p>
                                    </li>
                                  )}
                                  {item?.industry && (
                                    <li>
                                      <p>
                                        <b>Industry: </b>
                                        {item?.industry || "NA"}
                                      </p>
                                    </li>
                                  )}
                                  {item?.department && (
                                    <li>
                                      <p>
                                        <b>Dept: </b>
                                        {item?.department
                                          ?.map((d) => d?.department_type)
                                          ?.join(", ")}
                                      </p>
                                    </li>
                                  )}
                                  {item?.party_counter_party && (
                                    <li>
                                      <p>
                                        <b>Party/Counter Party: </b>
                                        {item?.party_counter_party
                                          ?.map((d) => d.party_counter_party_type)
                                          .join(", ")}
                                      </p>
                                    </li>
                                  )}
                                  {item?.filing_date && (
                                    <li>
                                      <p>
                                        <b>Filing Date: </b>
                                        {
                                          new Date(item?.filing_date)
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              {/* <div className="definitions">
                                  {searchData.selectedincludeKeyword.length ?
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.definition, searchData.selectedincludeKeyword) }} ></div>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.definition, query) }} ></div>
                                  }
                                </div> */}

                              <div className="description">
                                {item?.description?.map((desc) => (
                                  <>
                                    <p>
                                      {searchData.selectedincludeKeyword
                                        .length ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: boldString(
                                              desc,
                                              searchData.selectedincludeKeyword
                                            ),
                                          }}
                                        ></div>
                                      ) : (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: boldString(desc, searchValue),
                                          }}
                                        ></div>
                                      )}
                                    </p>
                                  </>
                                ))}
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <h3 style={{ textAlign: "center" }}>
                            No Result Found
                          </h3>
                        </li>
                      )}
                    </ul>
                  </div>
                  {previewShow && (
                    <div className="right" ref={rootRef}>
                      <div>
                        <Stack
                          style={{ padding: "0 10px" }}
                          justifyContent="space-between"
                          flexDirection={'row'}
                        >
                          {/* <span
                            className="custombtn 890"
                            onClick={() => setViewFullViewer(!viewFullViewer)}
                          >
                            <CgScreenShot
                              fontSize={24}
                              style={{
                                transform: "rotate(90deg)",
                                marginRight: "5px",
                              }}
                            />
                            View Full Contract
                          </span> */}
                          <span
                            className="custombtn"
                            onClick={() => setViewFullViewer(!viewFullViewer)}
                          >
                            Go to Full Page
                            <FullPageIcon />
                          </span>
                          <Stack className="save_dropdwon" alignItems="center">
                            <span
                              onClick={() => {
                                if (localStorage.getItem("token")) {
                                  setShowSaveDD(!showSaveDD);
                                } else {
                                  toast.warning(`Please Login First to save contract`);
                                }
                              }}
                            >
                              {/* Save
                              <MdOutlineBookmarkAdd
                                fontSize={24}
                                style={{ marginLeft: "5px" }}
                              /> */}
                              <BookmarkIcon />
                            </span>
                            <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? "active" : ""}`} >
                              <ul>
                                <li className="customcheck rightside">
                                  <input type="checkbox"
                                    id="My Library"
                                    name="my_library"
                                    checked={bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "my_library"}
                                    onChange={(e) => handleBookmark(e, bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                  />
                                  <label htmlFor="My Library"> My Library </label>
                                </li>

                                <li className="customcheck rightside">
                                  <input type="checkbox"
                                    id="Team Library"
                                    name="team_library"
                                    checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "team_library"}
                                    onChange={(e) => handleBookmark(e, bookmarkList.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                  />
                                  <label htmlFor="Team Library"> Team Library </label>
                                </li>

                                <li onClick={() => setSaveModal(true)}>
                                  <span> Client Library
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                    </svg>
                                  </span>
                                </li>
                                <li onClick={() => setCustomBookMarkModal(true)} >
                                  <span> Custom Library
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                    </svg>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Stack>
                        </Stack>
                        {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                      </div>
                      <div
                        style={{ padding: "10px", overflow: "auto" }}
                        ref={previewDivRef}
                      >
                        {docData?.raw_text ? (
                          regex.test(docData?.raw_text) ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: docData?.raw_text,
                              }}
                            ></div>
                          ) : (
                            <pre>
                              <div>{docData?.raw_text}</div>
                            </pre>
                          )
                        ) : regex.test(docData?.formatted_text) ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: docData?.formatted_text?.replace(
                                /\n/g,
                                "<br />"
                              ),
                            }}
                          ></div>
                        ) : (
                          <pre>
                            <div>{docData?.formatted_text}</div>
                          </pre>
                        )}
                      </div>

                      <SaveBM
                        docData={docData}
                        bookmarkList={bookmarkList}
                        activeTab={activeTab}
                        fetchBookmarkList={fetchBookmarkList}
                        rootRef={() => rootRef?.current}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        customBookMarkModal={customBookMarkModal}
                        setCustomBookMarkModal={setCustomBookMarkModal}
                      />
                    </div>
                  )}
                </Box>
              </CustomTabPanel>
            </Box>
          </Container >
        </div >
      ) : (
        <ViewFullScreen
          docFullViewData={docData}
          setViewFullViewer={setViewFullViewer}
          activeTab={activeTab}
        />
      )}

      {
        contractTypePopup && (
          <MiuiSelectPopup
            open={contractTypePopup}
            close={() => setContractTypePopup(false)}
            ddname="ContractType"
            placeholder="Contract Type"
            loadOptions={contractTitleList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedContractTitle}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedContractTitle}
          />
        )
      }

      {
        industryPopup && (
          <MiuiSelectPopup
            open={industryPopup}
            close={() => setIndustryPopup(false)}
            placeholder="Industry"
            ddname="Industry"
            loadOptions={industryList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedIndustry}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedIndustry}
          />
        )
      }

      {
        departmentPopup && (
          <MiuiSelectPopup
            open={departmentPopup}
            close={() => setDepartmentPopup(false)}
            placeholder="Department"
            ddname="Department"
            loadOptions={departmentList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedDepartment}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedDepartment}
          />
        )
      }

      {
        clausesPopup && (
          <MiuiSelectPopup
            open={clausesPopup}
            close={() => setClausesPopup(false)}
            placeholder="Clauses"
            ddname="Clauses"
            loadOptions={clausesList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedClause}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedClause}
          />
        )
      }

      {
        governingLawPopup && (
          <MiuiSelectPopup
            open={governingLawPopup}
            close={() => setGoverningLawPopup(false)}
            placeholder="Governing Law"
            ddname="GoverningLaw"
            loadOptions={governingLawList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedGoverningLaw}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedGoverningLaw}
          />
        )
      }

      {
        countryPopup && (
          <MiuiSelectPopup
            open={countryPopup}
            close={() => setCountryPopup(false)}
            placeholder="Country"
            ddname="Country"
            loadOptions={countryList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedCountry || []}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedCountry}
          />
        )
      }

      {
        counterPartyPopup && (
          <MiuiSelectPopup
            open={counterPartyPopup}
            close={() => setCounterPartyPopup(false)}
            placeholder="Party/Counter Party"
            ddname="CounterParty"
            loadOptions={counterPartyList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedCounterPartyList || []}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedCounterParty}
          />
        )
      }

      {
        entityTypePopup && (
          <MiuiSelectPopup
            open={entityTypePopup}
            close={() => setEntityTypePopup(false)}
            placeholder="Entity Type"
            ddname="EntityType"
            loadOptions={entityTypeList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedEntityTypeList}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedEntityTypeList}
          />
        )
      }

      {
        stockExchangesPopup && (
          <MiuiSelectPopup
            open={stockExchangesPopup}
            close={() => setStockExchangesPopup(false)}
            placeholder="Stock Exchange"
            ddname="StockExchanges"
            loadOptions={stockExchangesList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedStockExchangesList}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedStockExchangesList}
          />
        )
      }

      {
        exhibitIDPopup && (
          <MiuiSelectPopup
            open={exhibitIDPopup}
            close={() => setExhibitIDPopup(false)}
            placeholder="Exhibit ID"
            ddname="ExhibitID"
            loadOptions={exhibitIDList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedExhibitList}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedExhibitIDList}
          />
        )
      }

      {
        stateOfIncoPopup && (
          <MiuiSelectPopup
            open={stateOfIncoPopup}
            close={() => setStateOfIncoPopup(false)}
            placeholder="State of Incorporation"
            ddname="StateOfIncorporation"
            loadOptions={stateOfIncorporationList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedstateOfIncorporationList}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedStateOfIncorporationList}
          />
        )
      }

      {
        cikPopup && (
          <MiuiSelectPopup
            open={cikPopup}
            close={() => setCikPopup(false)}
            ddname="CIK"
            placeholder="CIK"
            loadOptions={cikList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedCIKList}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedCIKList}
          />
        )
      }

      {
        accessionPopup && (
          <MiuiSelectPopup
            open={accessionPopup}
            close={() => setAccessionPopup(false)}
            ddname="AccessionNumber"
            placeholder="AccessionNumber"
            loadOptions={accessionNumberList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedAccessionNumberList}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedAccessionNumberList}
            fetchListData={fetchAcessionNumberHandler}
          />
        )
      }

      {
        formPopup && (
          <MiuiSelectPopup
            open={formPopup}
            close={() => setFormPopup(false)}
            ddname="Forms"
            placeholder="Forms"
            loadOptions={formsList}
            activeTab={activeTab}
            query={searchValue}
            setSelected={setSelectedFormsList}
            genericFilterHandler={genericFilterHandler}
            searchType={searchType}
            value={searchData?.selectedFormsList}
            fetchListData={fetchFormsHandler}
          />
        )
      }

      {/* <PopupWithCheckList />
      <SignUpPopup />
      <FreeAccessReachedLimitModal />
      <YourPremiumSubsWillExpirySoonPopup />
      <YourSubsExpiredPopup />
      <UpgradePlanPopup /> */}
    </>
  );
};

export default SearchResult;
