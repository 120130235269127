import React from "react";

const ZoomInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.24537e-07 6C5.08451e-07 5.816 0.149334 5.66667 0.333334 5.66667L5.66667 5.66667L5.66667 0.333333C5.66667 0.149333 5.816 -5.08451e-07 6 -5.24537e-07C6.184 -5.40622e-07 6.33333 0.149333 6.33333 0.333333L6.33333 5.66667L11.6667 5.66667C11.8507 5.66667 12 5.816 12 6C12 6.184 11.8507 6.33333 11.6667 6.33333L6.33333 6.33333L6.33333 11.6667C6.33333 11.8507 6.184 12 6 12C5.816 12 5.66667 11.8507 5.66667 11.6667L5.66667 6.33333L0.333334 6.33333C0.149334 6.33333 5.40622e-07 6.184 5.24537e-07 6Z"
        fill="#606060"
      />
    </svg>
  );
};

export default ZoomInIcon;
