/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import {
  Autocomplete,
  Button,
  Checkbox,
  ClickAwayListener,
  Paper,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import {
  getAllsearchFilters,
  setSelectedDepartment,
  setSelectedFilters,
} from "../../redux/searchSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FilterIcon from "../../assets/images/SearchIcon/FilterIcon";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MiuiAsyncSelect({
  searchType,
  multiple,
  readOnly,
  setPreviewShow,
  ddname,
  placeholder,
  fetchListData,
  loadOptions,
  activeTab,
  query,
  setSelected,
  genericFilterHandler,
  value,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [shouldStayOpen, setShouldStayOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const wrapperRef = useRef(null);
  const [perCount, setPerCount] = useState(loadOptions?.total_count);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const getOptions = (ddname) => {
    debugger;
    setShouldStayOpen(true);
    dispatch(
      getAllsearchFilters(
        activeTab,
        query,
        loadOptions?.data?.length + 10,
        ddname
      )
    );
  };

  const handleToggleOption = (selectedOptions) => {
    setPreviewShow(false);
    setSelectedOptions(selectedOptions);
  };

  const handleClearOptions = () => {
    setSelectedOptions([]);
  };

  const handleChange = (event, selectedOptions, reason) => {
    setPreviewShow(false);
    if (reason === "clear") {
      handleClearOptions();
      dispatch(setSelected([]));
      dispatch(setSelectedFilters({ data: ddname, value: selectedOptions }));
      dispatch(genericFilterHandler(searchType, activeTab, query));
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions && Array.isArray(selectedOptions)) {
        // for only names
        // const namesArray = selectedOptions.map(item => item.name);
        setPreviewShow(false);

        handleToggleOption(selectedOptions);
        dispatch(setSelected(selectedOptions));
        dispatch(setSelectedFilters({ data: ddname, value: selectedOptions }));
        dispatch(genericFilterHandler(searchType, activeTab, query));
      } else {
        setPreviewShow(false);

        handleToggleOption([selectedOptions]);
        dispatch(setSelectedDepartment([selectedOptions]));
        dispatch(
          setSelectedFilters({ data: ddname, value: [selectedOptions] })
        );
        dispatch(genericFilterHandler(searchType, activeTab, query));
      }
    }
  };

  const handleClickAway = () => {
    setOpen(false);
    setShouldStayOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div ref={wrapperRef}>
          <Autocomplete
            id="asynchronous-demo"
            siz="small"
            readOnly={readOnly || false}
            multiple={multiple || false}
            limitTags={0}
            value={selectedOptions}
            open={open || shouldStayOpen}
            disableCloseOnSelect
            onOpen={() => {
              setOpen(true);
              setShouldStayOpen(false);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) =>
              option?.label === value?.label
            }
            options={loadOptions?.data}
            onChange={handleChange}
            renderOption={(props, option, { selected }) => (
              <li {...props} className="customlist">
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <p>{option.label}</p>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{ fontSize: "12px" }}
                placeholder={placeholder}
                className="main_div"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {/* {params.InputProps.endAdornment} */}
                      <FilterIcon />
                    </React.Fragment>
                  ),
                }}
              />
            )}
            PaperComponent={({ children }) => {
              return (
                <Paper>
                  <>
                    {children}
                    {loadOptions?.total_count > loadOptions?.data?.length && (
                      <Button
                        color="primary"
                        fullWidth="true"
                        sx={{ justifyContent: "flex-start", pl: 2 }}
                        onMouseDown={() => getOptions(ddname)}
                      >
                        Load More
                      </Button>
                    )}
                  </>
                </Paper>
              );
            }}
          />
        </div>
      </ClickAwayListener>
    </>
  );
}

export default MiuiAsyncSelect;
