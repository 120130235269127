import React from "react";

const GoNextFilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="14"
      viewBox="0 0 6 14"
      fill="none"
    >
      <path
        d="M0.499528 13.9996C0.376528 13.9996 0.254528 13.9546 0.158528 13.8646C-0.0434721 13.6766 -0.0534723 13.3596 0.134528 13.1576L4.27653 8.72763C4.74753 8.25663 4.99953 7.64763 4.99953 6.99863C4.99953 6.34963 4.74753 5.74063 4.28853 5.28163L0.134528 0.84063C-0.0534723 0.63863 -0.0434721 0.32163 0.158528 0.13363C0.359528 -0.0533701 0.676528 -0.0453698 0.865528 0.15663L5.00753 4.58663C5.64353 5.22263 6.00053 6.08263 6.00053 6.99863C6.00053 7.91463 5.64353 8.77463 4.99653 9.42263L0.866527 13.8406C0.767527 13.9456 0.634527 13.9986 0.501527 13.9986L0.499528 13.9996Z"
        fill="#303030"
      />
    </svg>
  );
};

export default GoNextFilterIcon;
