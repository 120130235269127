/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Container,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleContract, getAgreementIndex } from "../../service/Global";
import {
  createBookmark,
  getAllbookmarkList,
  removeBookmark,
} from "../../service/BookmarkService";
import SaveBM from "./SaveBM";
import { Loader } from "rsuite";
import { RiExternalLinkFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import ZoomInIcon from "../../assets/images/SearchIcon/ZoomInIcon";
import ZoomOutIcon from "../../assets/images/SearchIcon/ZoomOutIcon";
import BookmarkIcon from "../../assets/images/SearchIcon/BookmarkIcon";
import { toast } from "react-toastify";
import { UPGRADEPLAN } from "../../redux/actions";
import { useAccount } from "../../component/context/AccountProvider";

function ViewFullScreen({ docFullViewData, setViewFullViewer, activeTab }) {
  const regex = new RegExp(/<html\b[^>]*>[\s\S]*<\/html>/i);
  const { userDetails } = useAccount()
  const dispatch = useDispatch()
  const { query } = useParams();
  const { rootRef } = useRef(null);
  const previewDivRef = useRef(null);
  const divRef = useRef(null);
  const [contarctData, setContarctData] = useState([]);
  const [docData, setDocData] = useState(docFullViewData);
  const [agreementindex, setAgreementindex] = useState([]);
  const [fontSize, setFontSize] = useState(14);
  const [zoom, setzoom] = useState(100);
  const [showSaveDD, setShowSaveDD] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [customBookMarkModal, setCustomBookMarkModal] = useState(false);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { entityTypeList, searchType } = useSelector((state) => state.search);



  const handleScrollToTop = () => {
    if (previewDivRef.current) {
      // Scroll to the top of the div
      previewDivRef.current.scrollTop = 0;
    }
  };

  var base64Data = btoa(unescape(encodeURIComponent(docData?.formatted_text)));
  // var base64Data = btoa(docData?.formatted_text);
  // let dec = atob(base64Data);
  // console.log(dec);

  useEffect(() => {
    debugger;
    handleScrollToTop();
    fectchFullContarct();
    fetchAgrementIndex();
    fetchBookmarkList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docData]);

  const fectchFullContarct = async () => {
    setIsLoading(true);
    let payload = { documentData: base64Data };
    const response = await getAgreementIndex(payload);

    if (response?.data?.data) {
      setAgreementindex(response?.data?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fetchAgrementIndex = async () => {
    let payload = {
      contract_title: docData?.contract_title,
      type: searchType
    };
    const response = await fetchSingleContract(payload);
    setContarctData(response?.data);
  };

  //fetch bookmark list
  const fetchBookmarkList = async () => {
    const res = await getAllbookmarkList();
    if (res.data) {
      setBookmarkList(res?.data);
    }
  };

  const handleBookmark = async (e, item) => {
    debugger
    if (localStorage.getItem('token') !== null) {
      if (e.target.name === 'my_library') {
        handleCheckUnchek(e, item)
      }
      else if (userDetails.user_subscription.length) {
        handleCheckUnchek(e, item)
      }
      else {
        dispatch({ type: UPGRADEPLAN, payload: true });
      }
    }
  };

  //handlebookmark save
  const handleCheckUnchek = async (e, item) => {
    const { name, checked } = e.target;
    if (!checked) {
      const res = await removeBookmark(item.id);

      if (res.message === "Bookmark deleted successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();
      } else {
        toast.error(res.message || `something went wrong`);
      }
    } else {
      let payload = {
        contract_id: docData?.contract_id,
        library_type: name,
        bookmark_type: activeTab?.contractType
          ? "contracts"
          : activeTab?.clause
            ? "clauses"
            : "definitions",
        bookmark_name: activeTab?.contractType
          ? docData?.contract_title
          : activeTab?.clause
            ? docData.heading
            : docData.define_term,
      };

      const res = await createBookmark(payload);

      if (res.message === "Bookmark Created Successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();
      } else {
        toast.error(res.message || `something went wrong`);
      }
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      // Clicked outside the div, so remove the class
      setShowSaveDD(false);
    }
  };

  return (
    <>
      {console.log("docData", docData)}
      <div className="gray_bg" ref={previewDivRef}>
        <Container className="main-container ">
          <div className="viewfull_head">
            {/* <ul className="flex">
              <li className="active" onClick={() => setViewFullViewer(false)}>
                <Link to="/">Home</Link>
              </li>
              <li className="active" onClick={() => setViewFullViewer(false)}>{query}</li>
              <li>{docData?.contract_title.toLowerCase()}</li>
            </ul> */}
            <div className="active" onClick={() => setViewFullViewer(false)}
              style={{ paddingLeft: '15px', paddingBottom: '10px', cursor: 'pointer' }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5 11.4998H1.089C1.164 11.2908 1.287 11.0988 1.453 10.9348L5.401 6.89175C5.594 6.69475 5.59 6.37775 5.392 6.18475C5.195 5.99275 4.879 5.99575 4.685 6.19375L0.744 10.2298C0.27 10.6968 0.006 11.3348 0 11.9998C0.002 12.6578 0.258 13.2958 0.716 13.7608L4.639 17.8468C4.737 17.9488 4.868 17.9998 5 17.9998C5.125 17.9998 5.25 17.9538 5.346 17.8598C5.545 17.6698 5.552 17.3518 5.361 17.1528L1.433 13.0618C1.273 12.8998 1.154 12.7078 1.082 12.4998H23.5C23.776 12.4998 24 12.2758 24 11.9998C24 11.7238 23.776 11.4998 23.5 11.4998Z" fill="#303030" />
              </svg>
            </div>
          </div>
          <Stack className="viewfull">
            <div className="viewfull_left">
              <div className="viewfull_left_wrp" ref={rootRef}>
                <div className="viewfull_left_top">
                  <div className="viewfull_left_top_resize ">
                    <IconButton
                      color="primary"
                      disabled={zoom === 200}
                      onClick={() => {
                        setFontSize(fontSize + 2);
                        setzoom(zoom + 20);
                      }}
                    >
                      {/* <CgMathPlus size={18} /> */}
                      <ZoomInIcon />
                    </IconButton>
                    <p> {zoom}% </p>
                    <IconButton
                      color="primary"
                      disabled={zoom === 60}
                      onClick={() => {
                        setFontSize(fontSize - 2);
                        setzoom(zoom - 20);
                      }}
                    >
                      {/* <CgMathMinus size={18} /> */}
                      <ZoomOutIcon />
                    </IconButton>
                  </div>
                  <div className="viewfull_left_top_save">
                    <Stack className="save_dropdwon" alignItems="center">
                      <span
                        onClick={() => {
                          if (localStorage.getItem("token")) {
                            setShowSaveDD(!showSaveDD);
                          } else {
                            toast.warning(`Please Login First to save contract`);
                          }
                        }}
                      >
                        {/* Save{" "} */}
                        {/* <MdOutlineBookmarkAdd
                          fontSize={24}
                          style={{ marginLeft: "5px" }}
                        /> */}
                        <BookmarkIcon />
                      </span>

                      {console.log(
                        "docDataaaaa",
                        docData,
                        bookmarkList,
                        bookmarkList?.my_library,
                        bookmarkList?.my_library?.filter(
                          (_) => _?.contract_id === docData?.contract_id
                        )[0]?.library_type === "my_library"
                      )}
                      <div
                        ref={divRef}
                        className={`save_dropdwon_wrp ${showSaveDD ? "active" : ""
                          }`}
                      >
                        <ul>


                          <li className="customcheck rightside">
                            <input type="checkbox"
                              id="My Library"
                              name="my_library"
                              checked={bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "my_library"}
                              onChange={(e) => handleBookmark(e, bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                            />
                            <label htmlFor="My Library"> My Library </label>
                          </li>

                          <li className="customcheck rightside">
                            <input type="checkbox"
                              id="Team Library"
                              name="team_library"
                              checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "team_library"}
                              onChange={(e) => handleBookmark(e, bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                            />
                            <label htmlFor="Team Library"> Team Library </label>
                          </li>
                          <li onClick={() => {
                            if (localStorage.getItem('token') !== null && userDetails.user_subscription.length) {
                              setSaveModal(true)
                            }
                            else {
                              dispatch({ type: UPGRADEPLAN, payload: true });
                            }
                          }}
                          >
                            <span>
                              Client Library
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                              </svg>
                            </span>
                          </li>
                          <li onClick={() => setCustomBookMarkModal(true)}>
                            <span>
                              Custom Library
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                              </svg>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Stack>
                  </div>
                  <h5 className="right_contracttitle">

                    {docData?.contract_title}
                  </h5>
                </div>
                <Box
                  className="viewfull_left_doc"
                  sx={{ "& *": { fontSize: `${fontSize}px !important` } }}
                >
                  {/* {docData?.raw_text ?
                                    <div dangerouslySetInnerHTML={{ __html: docData?.raw_text.replace(/\n/g, "<br />") }} ></div>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />") }} ></div>
                                } */}

                  {docData?.raw_text ? (
                    regex.test(docData?.raw_text) ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: docData?.raw_text }}
                      ></div>
                    ) : (
                      <pre>
                        <div>{docData?.raw_text}</div>
                      </pre>
                    )
                  ) : regex.test(docData?.raw_text) ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: docData?.formatted_text?.replace(
                          /\n/g,
                          "<br />"
                        ),
                      }}
                    ></div>
                  ) : (
                    <pre>
                      <div>{docData?.formatted_text}</div>
                    </pre>
                  )}
                </Box>

                <SaveBM
                  docData={docData}
                  bookmarkList={bookmarkList}
                  activeTab={activeTab}
                  fetchBookmarkList={fetchBookmarkList}
                  rootRef={() => rootRef?.current}
                  saveModal={saveModal}
                  setSaveModal={setSaveModal}
                  customBookMarkModal={customBookMarkModal}
                  setCustomBookMarkModal={setCustomBookMarkModal}
                />
              </div>

              <div className="viewfull_left_similar">
                <h4> Similar Content </h4>
                <div className="viewfull_left_similar_list">
                  <h3> Contract Types you might be interested in </h3>
                  <ul>
                    {console.log("contarctData", contarctData)}
                    {contarctData?.results?.length ? (
                      contarctData?.results?.map((item, i) => {
                        return (
                          <>
                            <li onClick={() => { setDocData({ ...item, ...contarctData.contract[item._id] }) }}>
                              {item?.contract_title.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())}
                            </li>
                          </>
                        );
                      })
                    ) : (
                      <li>
                        <span> No Data Found </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="viewfull_right">
              <div className="viewfull_right_meta">
                <h4> Contract Metadata </h4>
                <div className="viewfull_right_meta_list">
                  <ul>
                    {docData?.contract_title && (
                      <li>
                        <span> Contract type : </span>
                        <span> {docData?.contract_title.toLowerCase()} </span>
                      </li>
                    )}
                    {docData?.country && (
                      <li>
                        <span> Country : </span>
                        <span> {docData?.country.toLowerCase()} </span>
                      </li>
                    )}

                    {docData?.jurisdiction && (
                      <li>
                        <span> Jurisdiction : </span>
                        <span> {docData?.jurisdiction.toLowerCase()} </span>
                      </li>
                    )}

                    {docData?.industry && (
                      <li>
                        <span> Industry : </span>
                        <span> {docData?.industry.toLowerCase()} </span>
                      </li>
                    )}

                    {docData?.party && (
                      <li>
                        <span> Party : </span>
                        <span> {docData?.party.toLowerCase()} </span>
                      </li>
                    )}

                    {docData?.accession_number && (
                      <li>
                        <span> Accession Number :</span>
                        <span> {docData?.accession_number} </span>
                      </li>
                    )}

                    {docData?.filing_date && (
                      <li>
                        <span> Filing Date :</span>
                        <span> {docData?.filing_date} </span>
                      </li>
                    )}

                    {docData?.entity_type && entityTypeList?.length && (
                      <li>
                        <span> Entity Type : </span>
                        <span>

                          {
                            entityTypeList?.filter(
                              (_) => _?._id === docData?.entity_type
                            )[0]?.name
                          }
                        </span>
                      </li>
                    )}

                    {docData?.company && (
                      <li>
                        <span> Company Name : </span>
                        <span> {docData?.company.toLowerCase()} </span>
                      </li>
                    )}
                    {docData?.state_of_incorporation && (
                      <li>
                        <span> State Of Incorporation : </span>
                        <span> {docData?.state_of_incorporation} </span>
                      </li>
                    )}

                    {docData?.governing_law && (
                      <li>
                        <span> Governing Law :</span>
                        <span>

                          {docData?.governing_law
                            ? docData?.governing_law.toLowerCase()
                            : "-"}{" "}
                        </span>
                      </li>
                    )}

                    {docData?.department && (
                      <li>
                        <span> Department : </span>
                        <span>

                          {docData?.department
                            ?.map((d) => d?.department_type)
                            .join(", ")}{" "}
                        </span>
                      </li>
                    )}

                    {docData?.cik && (
                      <li>
                        <span> CIK : </span>
                        <span> {docData?.cik} </span>
                      </li>
                    )}
                    {docData?.exhibit && (
                      <li>
                        <span> Exhibit ID : </span>
                        <span> {docData?.exhibit} </span>
                      </li>
                    )}

                    <li className="source_link">
                      <span> Source : </span>
                      <span>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={1}
                          sx={{ textDecoration: "underline" }}
                        >
                          <a href={docData.source_url} target="_blank">

                            Link <RiExternalLinkFill />
                          </a>
                        </Stack>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="viewfull_right_meta agreement">
                <h4> Agreement Index </h4>
                <div className="viewfull_right_meta_list">
                  {/* No Data Found */}
                  {/* <span> {docData?.contra ct_title} </span> */}
                  {isLoading ? (
                    <div style={{ height: "40px", position: "relative" }}>

                      <Loader center content="Loading Data..." />{" "}
                    </div>
                  ) : agreementindex?.length ? (
                    <>
                      <ul>
                        {agreementindex?.map((item, i) => {
                          return (
                            <li key={i}>
                              <span> {item.headings} </span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <ul>
                      <li>
                        <span> No Data Found </span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </Stack>
        </Container>
      </div>
    </>
  );
}

export default ViewFullScreen;
