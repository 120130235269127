import React from "react";

import { StyledEngineProvider } from "@mui/material/styles";
import themes from "./themes";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import AccountProvider from "./component/context/AccountProvider";
import Routes from "./routes/Routes";
import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ToastContainer autoClose={1000} />
      <ChakraProvider>
        <ThemeProvider theme={themes()}>
          <AccountProvider>
            <Routes />
          </AccountProvider>
        </ThemeProvider>
      </ChakraProvider >
    </StyledEngineProvider>
  );
}

export default App;
