import React from "react";

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M0.5 9C0.5 8.724 0.724 8.5 1 8.5H9V0.5C9 0.224 9.224 0 9.5 0C9.776 0 10 0.224 10 0.5V8.5H18C18.276 8.5 18.5 8.724 18.5 9C18.5 9.276 18.276 9.5 18 9.5H10V17.5C10 17.776 9.776 18 9.5 18C9.224 18 9 17.776 9 17.5V9.5H1C0.724 9.5 0.5 9.276 0.5 9Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusIcon;
