import React, { useState } from "react";
import ModalComponent from "../../component/Modal";
import { makeStyles } from "@mui/styles";
import SaveDocModal from "./SaveDocModal";
import CustomBookMark from "./CustomBookMark";
import { createBookmark, removeBookmark } from "../../service/BookmarkService";
import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import { Button, List, Stack } from "rsuite";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  root: {
    position: "absolute",
  },
  backdrop: {
    position: "absolute",
  },
});

const CustomModal = ({ open, close, children, containerRef }) => {
  return (
    <ModalComponent
      width="calc(100% - 30px)"
      maxHeight="464px"
      maxWidth="400px"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      handleClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="custom-filter-modal-container"
    >
      <div className="custom-filter-modal">
        <Card>
          <Stack>
            <h3 className="modal-heading"> Select </h3>
          </Stack>
        </Card>

        {/* <div className="filter-search-container">
            <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />
          </div> */}
        <List
          ref={containerRef}
          sx={{
            height: "302px",
            overflow: "auto",
            width: "100%",
            // maxWidth: 360,
            bgcolor: "background.paper",
            padding: "10px 15px",
            borderBottom: "1px solid #d1d9e0",
            fontFamily: "Satoshi",
          }}
        >
          {children}
        </List>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          className="modal-footer-actions"
        >
          <Button color="primary" onClick={close} className="cancel-btn">
            Cancel
          </Button>

          <Button variant="contained" className="select-btn">
            Apply
          </Button>
        </Stack>
      </div>
    </ModalComponent>
  );
};
function SaveBM({
  docData,
  activeTab,
  saveModal,
  bookmarkList,
  fetchBookmarkList,
  setSaveModal,
  customBookMarkModal,
  setCustomBookMarkModal,
  rootRef,
  library,
}) {
  const classes = useStyles();
  const { openlibraryType } = useSelector((state) => state.library);
  const [isLoading, setIsLoading] = useState(false)


  const hadleSaveClientLibrary = async (check, id) => {
    debugger
    let payload;
    let res;
    if (check) {
      let activeType = activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : "definitions"
      let deleteId = bookmarkList.client_library.filter((_) => _.bookmark_type === activeType).filter((_) => _.client_id === id).find((_) => _.contract_id === docData?.contract_id).id
      let res = await removeBookmark(deleteId);
      if (res.message === "Bookmark deleted successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();
      } else {
        toast.error(res.message || `something went wrong`);
      }
    } else {
      payload = {
        contract_id: docData?.contract_id,
        library_type: "client_library",
        bookmark_type: activeTab?.contractType
          ? "contracts"
          : activeTab?.clause
            ? "clauses"
            : "definitions",
        bookmark_name:
          library === "library" ?
            openlibraryType : activeTab?.contractType
              ? docData?.contract_title : activeTab?.clause
                ? docData.heading : docData.define_term,
        client_id: id,
      };
      res = await createBookmark(payload);
      if (res.message === "Bookmark Created Successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();
      } else {
        toast.error(res.message || `something went wrong`);
      }
    }
  };

  // create custom bookmark popup

  const handleMultiBookMarkLibrary = async (check, name, selected) => {

    if (check) {
      let res = await removeBookmark(selected?.id);
      if (res.message === "Bookmark deleted successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();

        // setCustomBookMarkModal(false)
      } else {
        toast.error(res.message || res.respose.message || `something went wrong`);

      }
    } else {
      let payload = {
        contract_id: docData?.contract_id,
        library_type: name,
        bookmark_type: activeTab?.contractType
          ? "contracts"
          : activeTab?.clause
            ? "clauses"
            : "definitions",
        bookmark_name:
          library === "library"
            ? openlibraryType
            : activeTab?.contractType
              ? docData?.contract_title
              : activeTab?.clause
                ? docData.heading
                : docData.define_term,
      };

      const res = await createBookmark(payload);

      if (res.message === "Bookmark Created Successfully.") {
        toast.success(res.message || `success`);
        fetchBookmarkList();

      } else {
        toast.error(res.message || res.respose.message || `something went wrong`);

      }
    }
  };

  const handleCustomBM = async (name) => {
    let payload = {
      contract_id: docData?.contract_id,
      library_type: name,
    };

    if (typeof activeTab === "string") {
      payload.bookmark_type = activeTab?.contractType
        ? "contracts"
        : activeTab?.clause
          ? "clauses"
          : "definitions";
      payload.bookmark_name =
        activeTab === "contracts"
          ? docData?.contract_title
          : activeTab === "clauses"
            ? docData.heading
            : docData.define_term;
    } else {
      payload.bookmark_type = activeTab?.contractType
        ? "contracts"
        : activeTab?.clause
          ? "clauses"
          : "definitions";
      payload.bookmark_name = activeTab?.contractType
        ? docData?.contract_title
        : activeTab?.clause
          ? docData.heading
          : docData.define_term;
    }
    const res = await createBookmark(payload);

    if (res.message === "Bookmark Created Successfully.") {
      toast.success(res.message || `success`);

      fetchBookmarkList();
      setCustomBookMarkModal(false);
    } else {
      toast.error(res.message || `something went wrong`);
    }
  };

  return (
    <>
      {console.log("docData", docData)}
      <ModalComponent
        width="calc(100% - 30px)"
        maxWidth="346px"
        maxHeight="510px"
        open={saveModal}
        handleClose={() => setSaveModal(false)}
        className="client_assignmodal"
        container={rootRef}
        classes={{ root: classes.root }}
        BackdropProps={{ classes: { root: classes.backdrop } }}
        style={{ position: "absolute" }}
        padding="0"
      >
        <SaveDocModal
          handleClose={() => setSaveModal(false)}
          handleSave={hadleSaveClientLibrary}
          bookmarkList={bookmarkList}
          docData={docData}
          activeTab={activeTab}
        />
      </ModalComponent>
      {/* <CustomModal open={saveModal} close={() => setSaveModal(false)}>
        <SaveDocModal
          handleClose={() => setSaveModal(false)}
          handleSave={hadleSaveClientLibrary}
          bookmarkList={bookmarkList}
          docData={docData}
        />
      </CustomModal> */}



      {/* custom library popup*/}
      <ModalComponent
        width="calc(100% - 30px)"
        maxWidth="346px"
        maxHeight="510px"
        open={customBookMarkModal}
        handleClose={() => setCustomBookMarkModal(false)}
        className="client_assignmodal"
        container={rootRef}
        classes={{ root: classes.root }}
        BackdropProps={{ classes: { root: classes.backdrop } }}
        style={{ position: "absolute" }}
        padding="0"
      >
        <CustomBookMark
          handleClose={() => setCustomBookMarkModal(false)}
          handleSave={handleMultiBookMarkLibrary}
          handleCustomBM={handleCustomBM}
          bookmarkList={bookmarkList}
          docData={docData}
        />
      </ModalComponent>
    </>
  );
}

export default SaveBM;
