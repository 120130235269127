/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
    FaFacebookF,
    FaLinkedinIn,
    FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import logo2 from '../assets/images/lexops_logo.png';
import WhiteLogo from '../assets/images/lexops_white_logo.svg';
import { getAllPage } from "../service/CMSService";


function Footer() {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        getAllPageHanlder()
    }, [])

    const getAllPageHanlder = async () => {
        const res = await getAllPage();
        setPages(res.data.data || []);
    };

    return (
        <>
            <footer>
                <section className="footer">
                    <div className="container">
                        <div className="footer_item">
                            <div className="footer_item_left">
                                <div>
                                    <img
                                        src={WhiteLogo}
                                        alt="footer_logo"
                                        className="footer_logo"
                                    />
                                    <h6> KRONICLE RESEARCH PVT. LTD. </h6>
                                </div>
                                <ul className="social_buttons">
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=100079332637546" className="facebook" >
                                            <FaFacebookF size={25} />
                                        </a>
                                    </li>
                                    {/* <li>
                    <a href="https://www.instagram.com/kronicle_research/" className="instagram" >
                      <FaInstagram />
                    </a>
                  </li> */}
                                    <li>
                                        <a href="https://www.twitter.com/kronicle_research/" className="twitter" >
                                            <FaXTwitter size={25} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/kronicler1/" className="linkedin" >
                                            <FaLinkedinIn size={25} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCa34pWHOQR_7wUvRCWyDHaQ" className="youtube" >
                                            <FaYoutube size={25} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer_item_right">
                                <div className="footer_item_right_item">
                                    <h6>Company</h6>
                                    <ul className="footer-ul">
                                        {pages
                                            .filter((_) => _.isFooterOne === true)
                                            .map((page, i) => (
                                                <li key={i}>
                                                    <a href={`/forum/${page.seoURL}`}> {page.name}</a>
                                                </li>
                                            ))}
                                        {/* //
                    <li>  
                      // // <a href="/contactus"> Contact Us</a>
                      //
                    </li>
                    //
                    <li>
                      // // <a href="/Joinus">Join Our Team</a>
                      //
                    </li>
                    //
                    <li>
                      // //
                      <a href="/forum/Terms%20And%20Conditions">
                        // Terms And Conditions //
                      </a>
                      //
                    </li> */}
                                    </ul>
                                </div>
                                <div className="footer_item_right_item">
                                    <h6>Support</h6>
                                    <ul className="footer-ul">
                                        {pages
                                            .filter((_) => _.isFooterTwo === true)
                                            .map((page, i) => (
                                                <li key={i}>
                                                    <a href={`/forum/${page.seoURL}`}> {page.name}</a>
                                                </li>
                                            ))}
                                        <li>
                                            <a href="#">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="#">Tech Support</a>
                                        </li>
                                        <li>
                                            <a href="#"> Sales Desk</a>
                                        </li>
                                        <li>
                                            <a href="#"> FAQs</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer_item_right_item">
                                    <h6>Products</h6>
                                    <ul className="footer-ul">
                                        <li> <a>  Contracts </a> </li>
                                        <li> <a>  Practice Management </a> </li>
                                        <li> <a>  Document Automation </a> </li>
                                        <li> <a>  Legal Research </a> </li>
                                        <li> <a>  Analytics </a> </li>
                                        <li> <a>  Marketplace </a> </li>
                                    </ul>
                                </div>
                                <div className="footer_item_right_item">
                                    <h6>Resources </h6>
                                    <ul className="footer-ul">
                                        <li>
                                            <a href="/library" target="_blank" rel="noreferrer" >
                                                Contract Library
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/library" target="_blank" rel="noreferrer" >
                                                Clause Library
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/casestudies">Case Studies</a>
                                        </li>
                                        <li>
                                            <a href="/casestudies?page=Whitepapers">Whitepapers</a>
                                        </li>
                                        <li>
                                            <a href="/blogs"> Blogs</a>
                                        </li>
                                        {pages
                                            .filter((_) => _.isFooterFour === true)
                                            .map((page, i) => (
                                                <li key={i}>
                                                    <a href={`/forum/${page.seoURL}`}> {page.name}</a>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </>
    );
}

export default Footer;
