/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setSelectedLibraryList } from "../../../redux/librarySlice";
import FilterIcon from "../../../assets/images/SearchIcon/FilterIcon";

function LibraryMiuiSingleSelect({
  searchScreen,
  placeholder,
  loadOptions,
  activeTab,
  query,
  setSelected,
  genericFilterHandler,
  value,
}) {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  useEffect(() => {
    setSelectedOptions(value || []);
    getSelected();
  }, [value]);

  const handleToggleOption = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleClearOptions = () => {
    setSelectedOptions([]);
  };

  const handleSingleChange = (event, selectedOptions, reason) => {
    console.log(reason, selectedOptions, "reasonData");
    if (reason === "clear") {
      handleClearOptions();
      dispatch(setSelected([]));
      dispatch(genericFilterHandler(activeTab, query));
    } else if (reason === "selectOption" || reason === "removeOption") {
      console.log(activeTab, query);
      handleToggleOption([selectedOptions]);
      dispatch(setSelectedLibraryList([selectedOptions]));
      dispatch(genericFilterHandler(activeTab, query));
    }
  };
  const getSelected = () => {
    let res = loadOptions?.filter((_) => _._id === value[0]?._id)[0];
    setSelectedOptions(res);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={loadOptions}
        onChange={handleSingleChange}
        defaultValue={selectedOptions ? selectedOptions[0] : loadOptions[0]}
        disabled={searchScreen ? true : false}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{ fontSize: "12px" }}
            placeholder={placeholder}
            className="main_div"
            disabled={searchScreen ? true : false}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/* {params.InputProps.endAdornment} */}
                  <FilterIcon />
                </React.Fragment>
              ),
            }}
          />
        )}
        value={selectedOptions}
      />
    </>
  );
}

export default LibraryMiuiSingleSelect;
