import React from "react";

const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.855 21.3545V17.3545L12.677 12.9695L18.997 6.50949C19.55 5.88749 19.855 5.08549 19.855 4.25249C19.855 2.37849 18.331 0.854492 16.457 0.854492C16.457 0.854492 6.62225 0.854492 3.25296 0.854492C1.45996 0.854492 0.152437 2.50049 0.152345 4.25249C0.152296 5.16912 0.152344 5.72516 0.729959 6.52749L6.85496 12.7955V18.1715C6.85496 18.3425 6.94196 18.5015 7.08596 18.5935L12.086 21.7765C12.168 21.8285 12.262 21.8545 12.355 21.8545C12.438 21.8545 12.521 21.8335 12.596 21.7925C12.755 21.7055 12.855 21.5375 12.855 21.3545ZM1.45996 5.84549C1.23905 5.40283 1.23905 4.96035 1.23905 4.25249C1.23905 3.42061 2.03004 1.85449 3.25296 1.85449C6.64453 1.8545 16.457 1.85449 16.457 1.85449C17.779 1.85449 18.855 2.93049 18.855 4.25249C18.855 4.84049 18.64 5.40649 18.266 5.82849L12.2283 12.0489C11.9889 12.2956 11.855 12.6258 11.855 12.9695V17.3545V20.4435L7.85496 17.8975V12.5915C7.85496 12.4605 7.80296 12.3355 7.71196 12.2415L1.45996 5.84549Z"
        fill="#606060"
      />
    </svg>
  );
};

export default FilterIcon;
