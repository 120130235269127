/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { FaChevronDown } from "react-icons/fa6";
import Button from "../ui-component/button";
import { useAccount } from "./context/AccountProvider";
import { getAllProject } from "../service/CMSService";
import MenuIcon from '../assets/images/Login/Menu-dot.svg';
import SearchIcon from '../assets/images/icons/search_icon.svg';
import logo from '../assets/images/dealbaselogo.svg';
import { useNavigate } from "react-router-dom";

function Header({ pages, blogs }) {
  console.log("pages, blogs", pages, blogs);
  const { logout, userDetails } = useAccount();
  const [allProjects, setAllProjects] = useState([]);
  const navigate = useNavigate();

  const logoutHandler = () => {
    logout();
    window.location.href = '/';
    localStorage.clear();
  };

  useEffect(() => {
    getAllProjectHandler();
  }, []);


  const getAllProjectHandler = async () => {
    const res = await getAllProject();
    const resData = res?.data;
    if (resData?.status) {
      setAllProjects(resData.data);
    }
  };


  return (
    <nav className="header navbar sticky-top p-0" >
      {console.log("userDetails", userDetails)}
      <div className="home-container ">
        <Stack
          className="navbar_wrp"
          direction={"row"}
          fullWidth="true"
          flexWrap={"noWrap"}
        >
          <Stack direction={"row"} style={{ width: "100%" }} alignItems={'center'}>
            <Stack direction={"row"} alignItems="center" className="navbar_wrp_left" justifyContent="flex-start" gap={2}>
              <IconButton aria-label="Back" >
                <img src={MenuIcon} alt='back' width={'40'} />
              </IconButton>
              <a href={localStorage.getItem('token') ? '/' : '/'} style={{ display: "inline-block" }}>
                <img src={logo} alt="Kronicle" />
              </a>
            </Stack>


            <div className="navbar_wrp_center">
              <div className=" navbar_collapse">
                <ul className="navbar_nav">
                  <li className="nav_item dropdown">
                    <a className="nav_link" role="button">
                      Products <FaChevronDown />
                    </a>
                    <ul className="dropdown-menu ">

                      {allProjects.map((project, i) => (
                        <li key={i}>
                          <a
                            className="dropdown-item"
                            href={`/product/${project.masterData.seoURL}`}
                          >
                            {project.masterData.name}
                          </a>
                        </li>
                      ))}

                    </ul>
                  </li>
                  <li className="nav_item dropdown">
                    <a className="nav_link" role="button">
                      Who it's For <FaChevronDown />
                    </a>
                    <ul className="dropdown-menu animate__animated animate__zoomIn animate__faster">


                      {pages?.filter((_) => _.isHeader === true)?.map((item, i) => {
                        return (
                          <li key={i}>
                            <a
                              className="dropdown-item"
                              href={`/forum/${item.seoURL}`}
                            >
                              {item.name}
                            </a>
                          </li>
                        );
                      })}


                    </ul>
                  </li>
                  <li className="nav_item dropdown">
                    <a
                      className="nav_link nav_text mr-2 text-black d-flex align-items-center"
                      role="button"
                    >
                      Resources <FaChevronDown />
                    </a>
                    <ul className="dropdown-menu p-0 animate__animated animate__zoomIn animate__faster">
                      <li>
                        <a className="dropdown-item" href="/library">
                          Clause Library
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/library">Contract Library</a>
                      </li>
                      <li>
                        <a className="dorpdown_menu_item" href="/casestudies">
                          Case Studies
                        </a>
                      </li>
                      <li>
                        <a className="dorpdown_menu_item" href="/casestudies?page=Whitepapers" >
                          Whitepapers
                        </a>
                      </li>
                      <li>
                        <a className="dorpdown_menu_item" href="/blogs">
                          Blogs
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="nav_item dropdown">
                    <a className="nav_link " role="button">
                      Support <FaChevronDown />
                    </a>
                    <ul className="dropdown-menu p-0 animate__animated animate__zoomIn animate__faster">

                      <li>
                        <a className="dorpdown_menu_item" href="/techsupport">
                          Tech Support
                        </a>
                      </li>
                      <li>
                        <a className="dorpdown_menu_item" href="/salesdesk">
                          Sales Desk
                        </a>
                      </li>
                      <li>
                        <a className="dorpdown_menu_item" href="/faqs">
                          FAQs
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav_item dropdown">
                    <a className="nav_link " href="/pricing" role="button">
                      Pricing
                    </a>
                  </li>
                  <li className="nav_item dropdown">
                    <a className="nav_link " href="/contactus" role="button">
                      Contact Us
                    </a>
                  </li>
                  <li className="nav_item dropdown">
                    <a className="nav_link " href="/forum/about-us" role="button">
                      About Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="navbar_wrp_right">
              {!userDetails?.email ?
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  className="navbar_wrp_right"
                  fullWidth="true"
                >
                  {/* <Button variant="contained" className=" primary" href="/login" text="Login" /> */}
                  {/* <Button variant="outlined" href="/login" className="secondary" text="Signup" /> */}
                  <IconButton aria-label="Back" >
                    <img src={SearchIcon} alt='back' width={'40'} />
                  </IconButton>
                  <button className="signup_btn" onClick={() => navigate(`/register`)}>
                    Signup
                  </button>
                  <button className="submit_btn" onClick={() => navigate(`/login`)}>
                    Login
                  </button>
                </Stack>
                :
                <ul>
                  <li className="dropdown aftlog">
                    <span className="nav-link nav-text mr-2 text-black d-flex align-items-center" href="#" role="button">
                      {userDetails?.email}
                    </span>
                    <ul className="dropdown-menu p-0 animate__animated animate__zoomIn animate__faster">
                      <li onClick={() => logoutHandler()}>
                        <a className="dropdown-item" >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>}
            </div>
          </Stack>
        </Stack>
      </div>
    </nav>
  );
}

export default Header;
