/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Button, Card, Checkbox, List, ListItem, ListItemButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserModalToolbar from './UserModalToolbar';
import SearchNotFound from './SearchNotFound';
import { getAllCustomBMList } from '../../service/BookmarkService';
import { Loader } from 'rsuite';


const TABLE_HEAD = [
    { id: '', label: '', alignRight: false },
];



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // return array.filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        return array.filter((_) => _?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function CustomBookMark({ docData, bookmarkList, handleClose, handleSave, handleCustomBM }) {
    const [isLoading, setIsLoading] = useState(false)
    const [customBMlist, setCustomBMlist] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [bookmarkName, setBookmarkName] = useState('')
    const [create, setCreate] = useState(false)
    const [clData, setClData] = useState([])

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const filteredBMlist = applySortFilter(customBMlist, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredBMlist?.length === 0;


    useEffect(() => {
        fetchCustomBookmarkList()
        // checkSelectedList()
    }, [])

    useEffect(() => {
        getSelectedData()
    }, [bookmarkList])




    const fetchCustomBookmarkList = async () => {
        setIsLoading(true)
        const res = await getAllCustomBMList();

        if (res.message === 'User Library data fetch successfully') {
            setIsLoading(false)
            setCustomBMlist(res?.distinct_library)
            setClData(res?.library_name)
            checkSelected(res?.library_name, docData?.contract_id)
        }
        else {
            setIsLoading(false)
            setCustomBMlist([])
            clData([])
        }
    }


    const getSelectedData = async () => {
        const res = await getAllCustomBMList();
        if (res.message === 'User Library data fetch successfully') {
            setClData(res?.library_name)
            checkSelected(res?.library_name, docData?.contract_id)
        }
        else {
            setIsLoading(false)
            setCustomBMlist([])
            clData([])
        }
    }



    const checkSelected = (listData, id) => {
        let data = listData?.filter((_) => _?.contract_id === id)[0]?.library_type
        if (data) {
            const typesArray = data?.map(item => item?.type);
            setSelected(typesArray)
        }
    }


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };


    useEffect(() => {
        fetchCustomBookmarkList()
    }, [])

    return (
        <Stack style={{ height: '100%' }}>
            <Stack alignItems={'end'} style={{ padding: '10px' }}>
                <button className='blue_btn' style={{ width: "150px", }} onClick={() => setCreate(!create)}>{!create ? 'Create Bookmark' : 'Bookmark List'} </button>
            </Stack>

            {create ?
                <>
                    <Stack style={{ height: '100%' }}>
                        <Stack style={{ height: '100%', padding: '10px' }}>
                            <TextField
                                size='small'
                                id="outlined-basic"
                                label="BookMark Name"
                                variant="outlined"
                                onChange={(e) => setBookmarkName(e.target.value)}
                                InputProps={{
                                    style: {
                                        borderRadius: "10px",
                                    },
                                }}
                            />
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}
                            style={{ padding: '10px', border: '1px solid #D1D9E0' }}>
                            <button className='outline_btn' color="primary" onClick={handleClose}> Cancel </button>
                            <button className='blue_btn'
                                style={{ padding: '8px 15px' }}
                                onClick={() => handleCustomBM(bookmarkName)}> Create & Save </button>
                        </Stack>
                    </Stack>
                </>
                :
                <>
                    <Stack style={{ height: 'calc(100% - 128px)', padding: '10px', paddingBottom: '0' }}>
                        {console.log("filteredBMlist", filteredBMlist)}
                        <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />


                        {!isLoading ?
                            <Stack sx={{ height: "calc(100% - 70px)", overflow: 'auto', margin: '10px 0' }}>
                                <List>
                                    {filteredBMlist?.map((row) => {
                                        // const { item } = row;
                                        const isItemSelected = selected?.indexOf(row) !== -1;
                                        return (
                                            <ListItem
                                                disablePadding
                                                className="filter-list-item customcheck"
                                                key={row.id}
                                            >
                                                {row.length ?
                                                    <ListItemButton disablePadding sx={{ padding: '0 5px', }} >
                                                        <input type="checkbox"
                                                            id={row}
                                                            checked={isItemSelected}
                                                            onChange={(event) => {
                                                                handleSave(
                                                                    isItemSelected,
                                                                    row,
                                                                    clData?.filter((_) => _.contract_id === docData?.contract_id)[0]?.library_type?.filter((l) => l.type === row)[0]
                                                                )
                                                                handleClick(event, row)
                                                            }}

                                                        />
                                                        <label htmlFor={row} style={{ width: '100%', justifyContent: 'flex-start' }}> {row} </label>
                                                    </ListItemButton>
                                                    : ''
                                                }
                                            </ListItem>
                                        );
                                    })}


                                    {isUserNotFound && (
                                        <li>
                                            <Stack align="center" colSpan={4} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </Stack>
                                        </li>
                                    )}
                                </List>
                            </Stack>
                            :
                            <Stack justifyContent={'center'} alignItems={'center'} sx={{ overflow: 'auto', margin: '15px 0', height: '100%' }}>
                                <Loader />
                            </Stack>
                        }
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3} style={{ padding: '10px', border: '1px solid #D1D9E0' }}>
                        <button
                            className='outline_btn' color="primary" onClick={handleClose}
                            style={{ width: '100%' }}
                        >
                            Cancel
                        </button>
                    </Stack>
                </>
            }
        </Stack >
    )
}

export default CustomBookMark