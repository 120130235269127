import React from "react";

const GoBackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_138_12451)">
        <path
          d="M12 0C18.617 0 24 5.383 24 12C24 18.617 18.617 24 12 24C5.383 24 0 18.617 0 12C0 5.383 5.383 0 12 0ZM12 23C18.065 23 23 18.065 23 12C23 5.935 18.065 1 12 1C5.935 1 1 5.935 1 12C1 18.065 5.935 23 12 23Z"
          fill="#216DDE"
        />
        <path
          d="M7.387 9.52767L10.215 6.64867C10.409 6.45167 10.725 6.44867 10.922 6.64267C11.119 6.83667 11.122 7.15267 10.928 7.35067L8.097 10.2327C7.91 10.4197 7.649 10.7057 7.382 11.0067C7.251 11.1547 7.158 11.3227 7.096 11.4997H17.5C17.776 11.4997 18 11.7237 18 11.9997C18 12.2757 17.776 12.4997 17.5 12.4997H7.096C7.159 12.6767 7.251 12.8447 7.383 12.9927C7.649 13.2937 7.91 13.5797 8.1 13.7697L10.928 16.6487C11.122 16.8457 11.119 17.1627 10.922 17.3567C10.824 17.4517 10.698 17.4997 10.571 17.4997C10.442 17.4997 10.312 17.4497 10.215 17.3507L7.39 14.4747C7.192 14.2767 6.915 13.9747 6.634 13.6567C5.797 12.7127 5.797 11.2887 6.634 10.3447C6.916 10.0267 7.193 9.72367 7.387 9.52967V9.52767Z"
          fill="#216DDE"
        />
      </g>
      <defs>
        <clipPath id="clip0_138_12451">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoBackIcon;
