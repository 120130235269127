import '../../styles/scss/pages/_new_ui_modal.scss'
import React from "react";
import NewModalComponent from "../../ui-component/Popup/NewModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { Card, Stack } from "@mui/material";
import SignUpPopup from "../../ui-component/Popup/SignUpPopup";
import { IPSERAHCOUNT, UPGRADEPLAN, USERSEPURCHASEPLAN, USERSERACHCOUNT } from "../../redux/actions";
import UpgradePlanPopup from '../../ui-component/Popup/UpgradePlanPopup';
import NoPurchasePlan from '../../ui-component/Popup/NoPurchasePlan';
import FreeAccessReachedLimitModal from '../../ui-component/Popup/FreeAccessReachedLimitModal';
import { useNavigate } from 'react-router-dom';

function WarningPopups() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { ipSearchCountPopup, freePlanSeachCountPopup, purchasePlanToAccess, upgradePlan } = useSelector((state) => state.common);

  const handleClose = () => {
    dispatch({ type: IPSERAHCOUNT, payload: false });
    dispatch({ type: USERSERACHCOUNT, payload: false });
    dispatch({ type: USERSEPURCHASEPLAN, payload: false });
    dispatch({ type: UPGRADEPLAN, payload: false });
  }



  return (
    <>
      {ipSearchCountPopup &&
        <NewModalComponent
          width="100%"
          maxWidth="740px"
          maxHeight='457px'
          open={ipSearchCountPopup}
          handleClose={() => {
            handleClose();
            // navigate('/')
          }}
        >
          <Card>
            <Stack p={3} pacing={1}>
              <SignUpPopup />
            </Stack>
          </Card>
        </NewModalComponent>
      }



      {
        freePlanSeachCountPopup &&
        <NewModalComponent
          width="100%"
          maxWidth="740px"
          maxHeight='386px'
          open={freePlanSeachCountPopup}
          handleClose={() => {
            handleClose();
            navigate('/')
          }}
        >
          <Card>
            <Stack p={3} pacing={1}>
              <FreeAccessReachedLimitModal />
            </Stack>
          </Card>
        </NewModalComponent>
      }


      {
        purchasePlanToAccess &&
        <NewModalComponent
          width="100%"
          maxWidth="740px"
          maxHeight='386px'
          open={purchasePlanToAccess}
          handleClose={handleClose}
        >
          <Card>
            <Stack p={3} pacing={1}>
              <NoPurchasePlan />
            </Stack>
          </Card>
        </NewModalComponent>
      }

      {/* {freePlanSeachCountPopup &&
        <NewModalComponent
          width="100%"
          maxWidth="740px"
          maxHeight='450px'
          open={freePlanSeachCountPopup}
          handleClose={handleClose}
        >
          <Card>
            <Stack p={3} pacing={1} alignItems={'center'}>
              <YourPremiumSubsWillExpirySoonPopup />
            </Stack>
          </Card>
        </NewModalComponent>
      } */}

      {/* {freePlanSeachCountPopup &&
        <NewModalComponent
          width="100%"
          maxWidth="740px"
          maxHeight='450px'
          open={freePlanSeachCountPopup}
          handleClose={handleClose}
        >
          <Card>
            <Stack p={3} pacing={1} alignItems={'center'}>
              <YourSubsExpiredPopup />
            </Stack>
          </Card>
        </NewModalComponent>
      } */}

      {upgradePlan &&
        <NewModalComponent
          width="100%"
          maxWidth="740px"
          maxHeight='396px'
          open={upgradePlan}
          handleClose={handleClose}
        >
          <Card>
            <Stack p={3} pacing={1} alignItems={'center'}>
              <UpgradePlanPopup />
            </Stack>
          </Card>
        </NewModalComponent>
      }

    </>
  );
};


export default WarningPopups;