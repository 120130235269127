import * as React from "react";


let listData = [
  'Save in your libraries',
  'Access to over 2 million contracts',
  'Add-Free browsing',
  'Access to over 40 million clauses and definitions',
  '24/7 Support',
  'Uninterrupted access',
  'AI Powered Search'
]
const PremiumPlanFearureSection = () => {

  return (
    <>
      <h4 className="premium-plan-title">
        Premium Plan Includes helpful features
      </h4>
      <ul className="premium-plan-ul" >
        {listData.map((item, index) => {
          return (
            <li key={index}>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_138_24779)">
                  <path d="M19.8781 3.35362L8.5548 16.9756C8.00313 17.6366 7.27146 18.0006 6.4923 18.0006H6.48646C5.7048 17.9986 4.97146 17.6306 4.42063 16.9656L0.123128 11.8556C-0.0402051 11.6616 -0.0410385 11.3446 0.120628 11.1486C0.282295 10.9536 0.546462 10.9506 0.709795 11.1446L5.0098 16.2566C5.40563 16.7356 5.9298 16.9976 6.4873 16.9996H6.49146C7.04813 16.9996 7.57063 16.7396 7.9648 16.2676L19.2881 2.64663C19.4506 2.45063 19.7148 2.45163 19.8773 2.64663C20.0406 2.84163 20.0415 3.15863 19.8781 3.35362Z" fill="#48B681" />
                </g>
                <defs>
                  <clipPath id="clip0_138_24779">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              {item}
            </li>
          );
        })}
      </ul>

    </>
  );
};


export default PremiumPlanFearureSection;
