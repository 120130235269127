import React from "react";
// import PopupWithCheckList from "./PopupWithCheckList";
import Image from "../../assets/images/popups/upgrade_plan_asset.svg";
import { Link, Stack, Typography } from "@mui/material";
import PremiumPlanFearureSection from "./BasePopup";
const UpgradePlanPopup = () => {
  return (
    <>


      <h2 className="modal-title" style={{ marginBottom: '10px' }}>
        Upgrade Plan to access Library.
      </h2>

      <Typography id="modal-modal-description"> Upgrade your plan for uninterrupted access to  Dealbase. </Typography>

      <div className="popup-check-list-container">
        <Stack className="popupWithCheckModalBody" direction={'row'}>
          <Stack style={{ width: "40%" }} alignItems={''} justifyContent={'center'}>
            <img src={Image} alt={'Upgrade plan'} style={{ width: '200px' }} />
          </Stack>
          <div style={{ width: "60%" }}>
            <div className="premium-plan-title-container">
              <PremiumPlanFearureSection />

              <Link
                href={`https://lexops.co/plans?token=${localStorage.getItem('token')}`}
                target={'_blank'}>
                <button className="modal-primary-button"
                  style={{ marginTop: '15px', maxWidth: '340px', marginLeft: '10px' }}>
                  Renew
                </button>
              </Link>
            </div>
          </div>
        </Stack>
      </div>
    </>
  );
};

export default UpgradePlanPopup;
