import React, { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  directSearchFilter,
  genericFilterHandler,
  homeSearchHandler,
  setSearchItem,
  setSearchSuggestionValue,
  setSearchType,
  setSearchValue,
} from "../../redux/searchSlice";
import { setIsLoading } from "../../redux/librarySlice";
import { Loader } from "rsuite";
import { useAccount } from "../context/AccountProvider";
import { IPSERAHCOUNT, USERSERACHCOUNT } from "../../redux/actions";

const HomeSearch = ({ searchType, activeTab, setPreviewShow, fromSearchResult, selectedQuery, contractCount, clauseCount, definitionCount, getSearchQuery }) => {
  const theme = useTheme();
  const { userDetails } = useAccount()
  const [searchQuery, setSearchQuery] = useState(selectedQuery)
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchValue, searchCount, searchItemList, searchSuggestions, isLoading } = useSelector((state) => state.search);

  // searchValue useMemo
  console.log("userDetails search", userDetails.id)
  const searchValueMemo = React.useMemo(() => searchValue, [searchValue]);

  const debounced = useDebouncedCallback((value) => {
    console.log("searchCount", searchCount)
    if ((localStorage.getItem('token') === null && searchCount <= 4) ||
      (localStorage.getItem('token') !== null && searchCount <= 9)
      || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)
    ) {
      getSearchQuery(value)
      dispatch(setSearchValue(value));
      dispatch(setSearchType(searchType));
      if (!value) { dispatch(setSearchItem([])); }
      setPreviewShow && setPreviewShow(false)
      dispatch(homeSearchHandler(userDetails.id || '', searchType, activeTab, value, "search"));
    }
    else {
      if (localStorage.getItem('token') === null && searchCount >= 4) {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
      if (localStorage.getItem('token') !== null) {
        if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      }
      // if (localStorage.getItem('token') !== null && userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      // }
      // if (localStorage.getItem('token') !== null && !userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   dispatch({ type: USERSEPURCHASEPLAN, payload: true });
      // }
    }
  },
    // delay in ms
    1000
  );


  const suggestionDebounced = useDebouncedCallback((value) => {
    if ((localStorage.getItem('token') === null && searchCount <= 4) ||
      (localStorage.getItem('token') !== null && searchCount <= 9)
      || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)
    ) {
      dispatch(setSearchSuggestionValue(value));
      if (!value) {
        dispatch(setSearchItem([]));
      }
      setPreviewShow && setPreviewShow(false);
      dispatch(directSearchFilter(userDetails.id || '', searchType, activeTab, value));
    }
    else {
      if (localStorage.getItem('token') === null && searchCount >= 4) {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
      if (localStorage.getItem('token') !== null) {
        if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      }
    }
  },
    // delay in ms
    1000
  );


  const handleSearch = (value) => {
    setSearchText(value)
    getSearchQuery(value)
    dispatch(setIsLoading(true))
    dispatch(setSearchValue(value));
    debounced(value)
    suggestionDebounced(value)
  }

  const goToSearchPage = (value) => {
    debugger
    if ((localStorage.getItem('token') === null && searchCount <= 4) ||
      (localStorage.getItem('token') !== null && searchCount <= 9)
      || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)
    ) {
      setSearchText(value?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
      setSearchQuery(value?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
      navigate(`/search-result/${value}`);
      dispatch(setSearchItem([]));
    }
    else {
      if (localStorage.getItem('token') === null && searchCount >= 4) {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
      if (localStorage.getItem('token') !== null) {
        if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      }
      // if (localStorage.getItem('token') !== null && userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      // }
      // if (localStorage.getItem('token') !== null && !userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   dispatch({ type: USERSEPURCHASEPLAN, payload: true });
      // }
    }

  };

  useEffect(() => {
    setSearchText(selectedQuery?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
    if (selectedQuery) {
      dispatch(setSearchValue(selectedQuery));
    }
  }, [dispatch, selectedQuery, searchQuery]);


  const [isClassActive, setClassActive] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      // Clicked outside the div, so remove the class
      setClassActive(false);
    }
  };

  const BoldWordInText = ({ text, targetWord }) => {
    // Split the text into parts based on the target word
    const parts = text?.split(new RegExp(`(${targetWord})`, 'gi'));

    return (
      <p>
        {parts?.map((part, index) =>
          part?.toLowerCase() === targetWord?.toLowerCase() ? (
            <strong key={index}>{part}</strong>
          ) : (
            part
          )
        )}
      </p>
    );
  };



  return (
    <Box className="search-box">
      {console.log("searchValueMemo?.", searchValueMemo?.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase()))}
      <OutlinedInput
        size='small'
        sx={{
          ...theme.typography.customInput,
          width: "100%",
          fontSize: "24px",
          margin: "0",
          fontWeight: 500,
          paddingRight: 0,
          borderColor: '#005287',
          borderRadius: '10px',
          fontFamily: 'Satoshi',
          "& input::placeholder": {
            fontSize: "20px",
            fontWeight: 500,
            color: '#909090',
            fontFamily: 'Satoshi',
            opacity: '1',
          },
          "& input": {
            borderRadius: "10px",
            borderColor: '#005287',
            fontFamily: 'Satoshi',
          },
          "fieldset":
          {
            borderColor: '#005287',
            color: '#505050',
          }
        }}
        autoComplete='off'
        id="input-search-header"
        placeholder="Search..."
        onChange={(e) => handleSearch(e.target.value)}
        onClick={() => setClassActive(true)}
        value={searchText}
        defaultValue={searchValueMemo?.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase()) || searchQuery?.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
        endAdornment={
          <InputAdornment position="start" className="search_icon" >
            {console.log("isLoading", isLoading)}
            {isLoading ? <Loader />
              :
              <button onClick={() => searchValueMemo?.trim()?.length ? goToSearchPage(searchValueMemo) : ''}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none">
                  <rect width="40" height="38" rx="4" fill="#216DDE" />
                  <path d="M31.263 17.3254C31.73 17.7634 31.994 18.3685 32 19.0003C31.998 19.6254 31.742 20.2315 31.279 20.678L27.361 24.5549C27.263 24.6518 27.132 24.7003 27 24.7003C26.875 24.7003 26.75 24.6566 26.654 24.5673C26.455 24.3868 26.448 24.0847 26.639 23.8956L30.562 20.0139C30.725 19.8572 30.845 19.6738 30.917 19.4753H8.5C8.224 19.4753 8 19.2625 8 19.0003C8 18.7381 8.224 18.5253 8.5 18.5253H30.911C30.836 18.3277 30.715 18.1472 30.553 17.9952L26.598 14.1477C26.405 13.9605 26.409 13.6594 26.607 13.476C26.805 13.2936 27.121 13.2965 27.314 13.4846L31.262 17.3254H31.263Z" fill="white" />
                </svg>
              </button>
            }
          </InputAdornment>
        }
        aria-describedby="search-helper-text"
        inputProps={
          {
            "aria-label": "weight",
            style: {
              borderRadius: "10px",
            }
          }}
      />
      {console.log("activeTab", activeTab)}

      {searchSuggestions?.length > 0 && searchValueMemo?.trim()?.length ? (
        activeTab?.contractType ? (
          <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
            <ul>
              {searchSuggestions?.map((item) => (
                <li
                  // onClick={() => goToSearchPage(item?.contract_title)}
                  onClick={() => {
                    dispatch(setSearchValue(item?.contract_title?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())));
                    setSearchText(item?.contract_title?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
                    setClassActive(false)
                    // dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, item?.contract_title?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())));
                    dispatch(homeSearchHandler(userDetails.id || '', searchType, activeTab, item?.contract_title?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()), 'homeSearch'));
                    setTimeout(() => {
                      dispatch(setSearchItem(item))
                    }, 1100);
                  }}
                  className="search-item"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_4587_113275)">
                      <path d="M23.8525 23.1465L17.4245 16.7185C19.0315 14.9435 20.0205 12.5985 20.0205 10.0215C20.0195 4.50848 15.5335 0.0214844 10.0195 0.0214844C4.50553 0.0214844 0.0195312 4.50848 0.0195312 10.0215C0.0195312 15.5345 4.50553 20.0215 10.0195 20.0215C12.5965 20.0215 14.9415 19.0325 16.7165 17.4255L23.1445 23.8535C23.2425 23.9515 23.3705 23.9995 23.4985 23.9995C23.6265 23.9995 23.7545 23.9505 23.8525 23.8535C24.0475 23.6585 24.0475 23.3415 23.8525 23.1465ZM1.01953 10.0215C1.01953 5.05948 5.05753 1.02148 10.0195 1.02148C14.9815 1.02148 19.0195 5.05849 19.0195 10.0215C19.0195 14.9845 14.9825 19.0215 10.0195 19.0215C5.05653 19.0215 1.01953 14.9845 1.01953 10.0215Z" fill="#303030" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4587_113275">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <p>
                    <BoldWordInText text={item?.contract_title?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())} targetWord={searchValueMemo} />
                  </p>
                </li>
              ))}
            </ul>
          </Box>
        )
          :
          activeTab?.clause ? (
            <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
              <ul>
                {searchSuggestions?.map((item) => (
                  <li
                    // onClick={() => goToSearchPage(item?.heading)}
                    onClick={() => {
                      dispatch(setSearchValue(item?.clause_heading?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())));
                      setSearchText(item?.clause_heading?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
                      setClassActive(false)
                      // dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, item?.clause_heading?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())));
                      dispatch(homeSearchHandler(userDetails.id || '', searchType, activeTab, item?.clause_heading?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()), 'homeSearch'));
                      setTimeout(() => {
                        dispatch(setSearchItem(item))
                      }, 1100);
                    }}
                    className="search-item"

                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_4587_113275)">
                        <path d="M23.8525 23.1465L17.4245 16.7185C19.0315 14.9435 20.0205 12.5985 20.0205 10.0215C20.0195 4.50848 15.5335 0.0214844 10.0195 0.0214844C4.50553 0.0214844 0.0195312 4.50848 0.0195312 10.0215C0.0195312 15.5345 4.50553 20.0215 10.0195 20.0215C12.5965 20.0215 14.9415 19.0325 16.7165 17.4255L23.1445 23.8535C23.2425 23.9515 23.3705 23.9995 23.4985 23.9995C23.6265 23.9995 23.7545 23.9505 23.8525 23.8535C24.0475 23.6585 24.0475 23.3415 23.8525 23.1465ZM1.01953 10.0215C1.01953 5.05948 5.05753 1.02148 10.0195 1.02148C14.9815 1.02148 19.0195 5.05849 19.0195 10.0215C19.0195 14.9845 14.9825 19.0215 10.0195 19.0215C5.05653 19.0215 1.01953 14.9845 1.01953 10.0215Z" fill="#303030" />
                      </g>
                      <defs>
                        <clipPath id="clip0_4587_113275">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* <p> {item?.clause_heading} </p> */}
                    <p>
                      <BoldWordInText text={item?.clause_heading?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())} targetWord={searchValueMemo} />
                    </p>
                  </li>
                ))}
              </ul>
            </Box>
          )
            :
            activeTab?.definition ? (
              <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
                <ul>
                  {searchSuggestions?.map((item) => (
                    <li
                      // onClick={() => goToSearchPage(item?.define_term)}
                      onClick={() => {
                        dispatch(setSearchValue(item?.define_term?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())));
                        setSearchText(item?.define_term?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
                        setClassActive(false)
                        // dispatch(genericFilterHandler(userDetails.id || '', searchType, activeTab, item?.define_term?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())));
                        dispatch(homeSearchHandler(userDetails.id || '', searchType, activeTab, item?.define_term?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()), 'homeSearch'));
                        setTimeout(() => {
                          dispatch(setSearchItem(item))
                        }, 1100);
                      }}
                      className="search-item"
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_4587_113275)">
                          <path d="M23.8525 23.1465L17.4245 16.7185C19.0315 14.9435 20.0205 12.5985 20.0205 10.0215C20.0195 4.50848 15.5335 0.0214844 10.0195 0.0214844C4.50553 0.0214844 0.0195312 4.50848 0.0195312 10.0215C0.0195312 15.5345 4.50553 20.0215 10.0195 20.0215C12.5965 20.0215 14.9415 19.0325 16.7165 17.4255L23.1445 23.8535C23.2425 23.9515 23.3705 23.9995 23.4985 23.9995C23.6265 23.9995 23.7545 23.9505 23.8525 23.8535C24.0475 23.6585 24.0475 23.3415 23.8525 23.1465ZM1.01953 10.0215C1.01953 5.05948 5.05753 1.02148 10.0195 1.02148C14.9815 1.02148 19.0195 5.05849 19.0195 10.0215C19.0195 14.9845 14.9825 19.0215 10.0195 19.0215C5.05653 19.0215 1.01953 14.9845 1.01953 10.0215Z" fill="#303030" />
                        </g>
                        <defs>
                          <clipPath id="clip0_4587_113275">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* <p>  {item?.define_term} </p> */}
                      <p>
                        <BoldWordInText text={item?.define_term?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())} targetWord={searchValueMemo} />
                      </p>
                    </li>
                  ))}
                </ul>
              </Box>
            )
              :
              window.location.pathname === '/' && searchValueMemo?.trim()?.length ? (
                <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
                  <ul>
                    <li className="search-item">
                      No Result found
                    </li>
                  </ul>
                </Box>
              ) : null
      ) :
        searchItemList?.length === 0 && !isLoading && searchValue?.length > 0 &&
        <>
          {/* <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
            <ul>
              <li className="search-item" >
                <p style={{ textAlign: "center" }}>
                  Search Result Not Found
                </p>
              </li>
            </ul>
          </Box> */}
        </>

      }
    </Box >
  );
};

export default HomeSearch;
