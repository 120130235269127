/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, Button, CardContent, Stack, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { createBookmark, getAllCustomBMList, getAllbookmarkList, removeBookmark, removeGPBookmark } from "../../service/BookmarkService";
import CustomButton from "../../ui-component/button";
import WarningIcon from "@mui/icons-material/Warning";
import { MdClose, MdRemoveRedEye } from "react-icons/md";
import LibraryMiuiAsyncSelect from "./LibraryMiuiAsyncSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  genericFilterHandler,
  setDocData,
  setFullLibraryResult,
  setSelectedAccessionNumberList,
  setSelectedCIKList,
  setSelectedClause,
  setSelectedContractTitle,
  setSelectedCountry,
  setSelectedCounterPartyList,
  setSelectedDate,
  setSelectedDepartment,
  setSelectedEntityTypeList,
  setSelectedExcludeKeyword,
  setSelectedExhibitList,
  setSelectedFilters,
  setSelectedFormsList,
  setSelectedGoverningLaw,
  setSelectedIncludeKeyword,
  setSelectedIndustry,
  setSelectedStockExchangesList,
  setSelectedstateOfIncorporationList,
  setSelectedFilteredTitle,
  setSelectedGroupIDs,
  setSelectedLibraryList,
  setOpenLibraryType,
  setRemoveAllFilters,
  setIncludeKeyword,
  setExcludeKeyword,
  setCounterPartyList,
  setIsLoading,
} from "../../redux/librarySlice";
import { Dropdown, Loader } from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';
import SaveBM from "../SearchResult/SaveBM";
import ViewFullScreen from "./ViewFullScreen";
import LibraryMiuiSingleSelect from "./LibraryMiuiSingleSelect";
import { getAllClient } from "../../service/UserService";
import ModalComponent from "../../component/Modal";
import RemoveClient from "./RemoveClient";
import { createCustomClient } from "../../service/Library";
import MiuiLibrarySelectPopup from "../../ui-component/MiuiLibrarySelectPopup ";
import ChipInput from "material-ui-chip-input";
import MoreFiltersModalLibrary from "./MoreFiltersModalLibrary";
import GoPrevIcon from "../../assets/images/SearchIcon/GoPrevIcon";
import GoNextFilterIcon from "../../assets/images/SearchIcon/GoNextFilterIcon";
import FullPageIcon from "../../assets/images/SearchIcon/FullPageIcon";
import BookmarkIcon from "../../assets/images/SearchIcon/BookmarkIcon";
import DeleteIcon from "../../assets/images/SearchIcon/DeleteIcon";
import PlusIcon from "../../assets/images/SearchIcon/PlusIcon";
import BackIcon from "../../assets/images/SearchIcon/BackIcon";
import DropdownIcon from "../../assets/images/SearchIcon/DropdownIcon";
import NewModalComponent from "../../ui-component/Popup/NewModalComponent";
import { checkArrays } from "../../utils/commonFunctions";
import { toast } from "react-toastify";
import { getUserSeachCount } from "../../service/Global";
import { useAccount } from "../../component/context/AccountProvider";
import { setSearchCount } from "../../redux/searchSlice";
import { IPSERAHCOUNT, UPGRADEPLAN, USERSERACHCOUNT } from "../../redux/actions";

const data = [
  { label: "Sort by - Relevance", value: "relevance" },
  { label: "Sort by - Latest", value: "newest" },
  { label: "Sort by - Older", value: "oldest" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '100%',
  pt: 2,
  px: 4,
  pb: 3,
};


const LibHeadAction = [
  // { id: "1", title: "My Library", name: "my_library" },
  { id: "3", title: "Clients Library", name: "client_library" },
  { id: "2", title: "Team Library", name: "team_library" },
];

const SortByButton = ({ ref, ...props }) => {
  console.log(props, ref, "propsData");
  return (
    <button {...props} ref={ref}>
      {props?.title}
      <DropdownIcon />
    </button>
  );
};

const SortBySection = ({ userId, searchType, activeTab, CustomButton }) => {
  const dispatch = useDispatch();
  const [selectedSort, setSelectedSort] = useState(data[0]?.label);
  return (
    <Dropdown
      renderToggle={(props, ref) => {
        console.log(props, "propsData");
        return (
          <>
            {CustomButton ? (
              <CustomButton
                {...(Object.keys(props)?.length ? props : {})}
                ref={ref}
                title={selectedSort}
              />
            ) : (
              <SortByButton
                {...(Object.keys(props)?.length ? props : {})}
                ref={ref}
                title={selectedSort}
              />
            )}
          </>
        );
      }}
      className="sort-by-section"
    >
      {data?.map((item) => {
        return (
          <Dropdown.Item
            value={item.value}
            onClick={() => {
              setSelectedSort(item?.label);
              dispatch(genericFilterHandler("", "", null, 0, data));
            }}
          >
            {item.label}
          </Dropdown.Item>
        );
      })}
    </Dropdown >
  );
};


function Library() {
  const rootRef = useRef(null);
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const [bookmarkList, setBookmarkList] = useState([]);
  const { userDetails } = useAccount()
  const [moreFilter, setMoreFilter] = useState(false);
  const { libraryResult, fullLibraryResult, flteredTitle, docData, isLoading, governingLawList, selectedFilters,
    departmentList, industryList, contractTitleList, clausesList, cikList, accessionNumberList, entityTypeList,
    formsList, stockExchangesList, countryList, counterPartyList, stateOfIncorporationList, exhibitIDList } = useSelector((state) => state.library);
  let searchData = useSelector((state) => state.library);
  const { searchCount } = useSelector((state) => state.search);
  const [sellAllresults, setSellAllresults] = useState(false);
  const [fullResultData, setfullResultData] = useState([]);
  const [fullResultDataTitle, setfullResultDataTitle] = useState("");
  const [previewShow, setPreviewShow] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [customBookMarkModal, setCustomBookMarkModal] = useState(false);
  const [customCreateClient, setCustomCreateClient] = useState(false);
  const [viewFullViewer, setViewFullViewer] = useState(false);
  const [showSaveDD, setShowSaveDD] = useState(false);
  const [searchScreen, setSearchScreen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [groupIds, setGroupIds] = useState([]);
  const [libraryList, setLibraryList] = useState([
    {
      _id: 'my_library',
      name: 'my_library',
      label: 'My Library',
      value: 'my_library',
    },
  ]);

  const [clientList, setClientList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [customClientName, setCustomClientName] = useState("");
  const [CCmodal, setCCmodal] = useState(false);
  const [activeLibType, setActiveLibType] = useState();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };


  const handleCloseCCModal = (event, reason) => {
    setCCmodal(false);
  };

  // useEffect(() => {
  //     if (window.location.pathname.split('/')[2]) {
  //         window.location.pathname.split('/')[2] === 'my_library' && dispatch(setSelectedLibraryList([{ "_id": "my_library", "name": "my_library", "label": "My Library", "value": "my_library" }]))
  //         window.location.pathname.split('/')[2] === 'client_library' && dispatch(setSelectedLibraryList([{ "_id": "client_library", "name": "client_library", "label": "Client Library", "value": "client_library" }]))
  //         window.location.pathname.split('/')[2] === 'team_library' && dispatch(setSelectedLibraryList([{ "_id": "team_library", "name": "team_library", "label": "Team Library", "value": "team_library" }]))
  //     }
  // }, [window.location.pathname.split('/')[2]])

  //handle All fecth filterdata
  useEffect(() => {
    dispatch(setIsLoading(true))
    fetchClientList();
    let path = window.location.pathname.split("/")[2];
    if (path) {
      if (path === "my_library") {
        setActiveLibType('my_library')
        dispatch(setSelectedLibraryList([{ _id: "my_library", name: "my_library", label: "My Library", value: "my_library", },]));
      }
      else if (path === "client_library") {
        setActiveLibType('client_library')
        dispatch(setSelectedLibraryList([{ _id: "client_library", name: "client_library", label: "Client Library", value: "client_library", },]));
      }
      else {
        setActiveLibType('team_library')
        dispatch(setSelectedLibraryList([{ _id: "team_library", name: "team_library", label: "Team Library", value: "team_library", },]));
      }
    }

    hadleAccess()
  }, [userDetails]);


  //check access 
  const hadleAccess = async () => {
    const response = await getUserSeachCount(userDetails.id || '');
    dispatch(setSearchCount(response.data.click_count || 0));
    debugger
    if ((localStorage.getItem('token') !== null && response.data.click_count <= 9) || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)) {
      dispatch(genericFilterHandler());
      fetchBookmarkList();
      fetchCustomBookmarkList();
    }
    else if (userDetails.user_subscription === 0 && window.location.pathname.split("/")[2] === "my_library") {
      dispatch(genericFilterHandler());
      fetchBookmarkList();
      fetchCustomBookmarkList();
    }
    else {
      dispatch(setIsLoading(false))
      if (localStorage.getItem('token') === null && response.data.click_count >= 4) {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
      if (localStorage.getItem('token') !== null) {
        if (response.data.click_count >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      }
      // if (localStorage.getItem('token') !== null && userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      // }
      // if (localStorage.getItem('token') !== null && !userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   dispatch({ type: USERSEPURCHASEPLAN, payload: true });
      // }
    }
  }




  //fetch bookmark list
  const fetchBookmarkList = async () => {
    const res = await getAllbookmarkList();
    if (res.data) {
      setBookmarkList(res?.data);
    }
  };

  //fetch custom bookmarklist
  const fetchCustomBookmarkList = async () => {
    const res = await getAllCustomBMList();
    if (res) {
      const temp = res?.distinct_library.map((item) => {
        return {
          _id: item,
          name: item,
          label: item,
          value: item._id,
        };
      });
      setLibraryList([...libraryList, ...temp]);
    }
  };


  //fetch client list
  const fetchClientList = async () => {
    const res = await getAllClient();
    console.log(res, "resClient");
    if (res) {
      const temp = res?.map((item) => {
        return {
          _id: item.id,
          name: item.name,
          label: item.name,
          value: item.id,
        };
      });
      setClientList(temp);
    }
  };


  //handle search by title
  const handleWord = (value, name) => {
    // setPreviewShow(false)
    if (name === "Include Keywords") {
      const objectsArray = [...searchData?.includeKeyword, value]
        ?.map((str, index) => {
          if (str.trim() !== "") {
            return {
              _id: str.trim(),
              name: str.trim(),
              label: str.trim(),
              value: str.trim(),
            };
          }
          return null;
        })
        .filter((obj) => obj !== null);
      debugger;
      dispatch(setIncludeKeyword([...searchData?.includeKeyword, value]));
      dispatch(setSelectedIncludeKeyword([...searchData?.includeKeyword, value]));
      dispatch(setSelectedFilters({ data: "include", value: objectsArray }));
      dispatch(genericFilterHandler());
    }
    if (name === "Exclude Keywords") {
      const objectsArray = [...searchData?.excludeKeyword, value]
        ?.map((str, index) => {
          if (str.trim() !== "") {
            return {
              _id: str.trim(),
              name: str.trim(),
              label: str.trim(),
              value: str.trim(),
            };
          }
          return null;
        })
        .filter((obj) => obj !== null);
      debugger;
      dispatch(setExcludeKeyword([...searchData?.excludeKeyword, value]));
      dispatch(
        setSelectedExcludeKeyword([...searchData?.excludeKeyword, value])
      );
      dispatch(setSelectedFilters({ data: "exclude", value: objectsArray }));
      dispatch(genericFilterHandler());
    }

    if (name === "filterbytitle") {
      dispatch(setSelectedFilteredTitle(value));
      setTimeout(() => {
        dispatch(genericFilterHandler());
      }, 1000);
    }
  };

  //handle Date
  const handleDate = (date) => {
    dispatch(setSelectedDate(date));
    dispatch(genericFilterHandler());
  };

  const handleShortCutDate = (date) => {
    dispatch(setSelectedDate(date.value));
    dispatch(genericFilterHandler());
  };

  //handle remove Date
  const handleRemove = () => {
    dispatch(setSelectedDate(""));
    dispatch(genericFilterHandler());
  };

  // remove selected data
  const handleRemoveSelected = (arrayType, idToRemove) => {
    if (selectedFilters[arrayType]) {
      const updatedArray = selectedFilters[arrayType].filter((item) => item._id !== idToRemove);
      let finalData = { ...selectedFilters, [arrayType]: updatedArray };
      dispatch(setSelectedFilters({ replaceData: finalData }));

      if (arrayType === "Industry") {
        dispatch(setSelectedIndustry(updatedArray));
      } else if (arrayType === "ContractType") {
        dispatch(setSelectedContractTitle(updatedArray));
      } else if (arrayType === "Department") {
        dispatch(setSelectedDepartment(updatedArray));
      } else if (arrayType === "Clauses") {
        dispatch(setSelectedClause(updatedArray));
      } else if (arrayType === "GoverningLaw") {
        dispatch(setSelectedGoverningLaw(updatedArray));
      } else if (arrayType === "AccessionNumber") {
        dispatch(setSelectedAccessionNumberList(updatedArray));
      } else if (arrayType === "EntityType") {
        dispatch(setSelectedEntityTypeList(updatedArray));
      } else if (arrayType === "Forms") {
        dispatch(setSelectedFormsList(updatedArray));
      } else if (arrayType === "StockExchanges") {
        dispatch(setSelectedStockExchangesList(updatedArray));
      } else if (arrayType === "StateOfIncorporation") {
        dispatch(setSelectedstateOfIncorporationList(updatedArray));
      } else if (arrayType === "ExhibitID") {
        dispatch(setSelectedExhibitList(updatedArray));
      } else if (arrayType === "CIK") {
        dispatch(setSelectedCIKList(updatedArray));
      } else if (arrayType === "Country") {
        dispatch(setSelectedCountry(updatedArray));
      } else if (arrayType === "CounterParty") {
        dispatch(setSelectedCounterPartyList(updatedArray));
      } else if (arrayType === "include") {
        const updatedSelectInclude = searchData?.includeKeyword.filter(
          (item) => item !== idToRemove
        );
        dispatch(setIncludeKeyword(updatedSelectInclude));
        dispatch(
          setSelectedIncludeKeyword(updatedArray.map((obj) => obj.name))
        );
      } else if (arrayType === "exclude") {
        const updatedSelectExclude = searchData?.includeKeyword.filter(
          (item) => item !== idToRemove
        );
        dispatch(setExcludeKeyword(updatedSelectExclude));
        dispatch(
          setSelectedExcludeKeyword(updatedArray.map((obj) => obj.name))
        );
      }
      // else if (arrayType === 'FilterByTitle') {
      //     dispatch(setSelectedTitleList(updatedArray))
      // }
      dispatch(genericFilterHandler());
    }
  };

  const handleRemoveAllSelected = () => {
    setPreviewShow(false);
    dispatch(setRemoveAllFilters([]));
    dispatch(genericFilterHandler());
  };

  // handle click on group
  const handleClickedGroup = (item, name) => {
    setActiveTab(name);
    setSearchScreen(true);
    setGroupIds(item.contract_id);
    dispatch(setOpenLibraryType(name));
    localStorage.setItem('openLibraryType', name)
    dispatch(setSelectedGroupIDs(item.contract_id));
    dispatch(genericFilterHandler("contract_data", item.contract_id));
  };

  // handle remove group
  const hanleRemoveGroup = async (item) => {
    let res = await removeGPBookmark(item.bookmark_type, item.bookmark_name);
    if (res?.data?.message === "Bookmark deleted successfully.") {
      toast.success(res.data.message || `success`);
      dispatch(genericFilterHandler());
    } else {
      toast.error(res.message || res.data.message || `something went wrong`);
    }
  };

  // handle fullscreen result
  const handleFullResults = (data, type) => {
    setfullResultDataTitle(type);
    setfullResultData(data);
    setSellAllresults(true);
  };

  //handlePreview Click
  const handlePrviewClick = () => {
    if (previewShow === false) {
      let previewData =
        fullLibraryResult.contract[Object.keys(fullLibraryResult?.contract)[0]];
      let newData = { ...previewData, ...fullLibraryResult.results[0] };
      dispatch(setDocData(newData));
      setPreviewShow(!previewShow);
    } else {
      setPreviewShow(!previewShow);
    }
  };

  // select contarct
  const onSelectHandler = (item, index) => {
    setPreviewShow(true);
    setSaveModal(false);
    setCustomBookMarkModal(false);
    let previewData = fullLibraryResult?.contract[item?.contract_id];
    let newData = { ...previewData, ...item };
    dispatch(setDocData(newData));
    const _temp = [...fullLibraryResult.results];
    // add selected true to selected item using map
    const temp = _temp?.map((el, i) => {
      if (i === index) {
        return { ...el, selected: true };
      } else {
        return { ...el, selected: false };
      }
    });
    dispatch(setFullLibraryResult({ ...fullLibraryResult, results: temp }));
  };

  // handle bookmark
  const handleBookmark = async (e, item) => {
    const { name, checked } = e.target;
    if (!checked) {
      const res = await removeBookmark(item.id);
      if (res.message === "Bookmark deleted successfully.") {
        fetchBookmarkList();
        toast.success(res.message || `success`);
      } else {

        toast.error(res.message || res.data.message || `something went wrong`);
      }
    } else {
      let payload = {
        contract_id: docData?.contract_id,
        library_type: name,
        bookmark_type:
          activeTab === "contracts"
            ? "contracts"
            : activeTab === "clauses"
              ? "clauses"
              : "definitions",
        bookmark_name:
          activeTab === "contracts"
            ? docData?.contract_title
            : activeTab === "clauses"
              ? docData.heading
              : docData.define_term,
      };
      const res = await createBookmark(payload);
      if (res.message === "Bookmark Created Successfully.") {
        fetchBookmarkList();
        toast.success(res.message || `success`);
      } else {
        toast.error(res.message || res.data.message || `something went wrong`);
      }
    }
  };

  //handle remove single contartc from group
  // const handleRemoveSingleContarct = async (item) => {
  //     const res = await removeBookmark(item?.contract_id);
  //     if (res.message === 'Bookmark deleted successfully.') {
  //         setToastData({
  //             open: true,
  //             status: 'success',
  //             message: res.message || `success`
  //         });
  //         dispatch(genericFilterHandler('contract_data', groupIds))
  //     }
  //     else {
  //         setToastData({
  //             open: true,
  //             status: 'error',
  //             message: res.message || `something went wrong`
  //         });
  //     }
  // }

  // handle back to library navigation
  const handleLibraryNavigation = () => {
    setSellAllresults(false);
    setSearchScreen(false);
    setPreviewShow(false);
    dispatch(setSelectedGroupIDs([]));
    dispatch(genericFilterHandler());
  };

  //handle custom client
  const handleCustomClient = async () => {
    let payload = {
      client_name: customClientName,
    };
    const res = await createCustomClient(payload);
    if (
      res?.status === 200 &&
      res?.data?.message === "Custom Client Created Successfully."
    ) {
      toast.success(res.data.message || `success`);
      setCustomCreateClient(false);
    } else {
      toast.error(res?.response?.data?.message || `something went wrong`);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      // Clicked outside the div, so remove the class
      setShowSaveDD(false);
    }
  };

  const regex = new RegExp(/<html\b[^>]*>[\s\S]*<\/html>/i);
  const [contractTypePopup, setContractTypePopup] = useState(false);
  const [industryPopup, setIndustryPopup] = useState(false);
  const [departmentPopup, setDepartmentPopup] = useState(false);
  const [clausesPopup, setClausesPopup] = useState(false);
  const [governingLawPopup, setGoverningLawPopup] = useState(false);
  const [countryPopup, setCountryPopup] = useState(false);
  const [entityTypePopup, setEntityTypePopup] = useState(false);
  const [stockExchangesPopup, setStockExchangesPopup] = useState(false);
  const [exhibitIDPopup, setExhibitIDPopup] = useState(false);
  const [cikPopup, setCikPopup] = useState(false);
  const [accessionPopup, setAccessionPopup] = useState(false);
  const [formPopup, setFormPopup] = useState(false);
  const [stateOfIncoPopup, setStateOfIncoPopup] = useState(false);
  const [counterPartyPopup, setCounterPartyPopup] = useState(false);
  const [allFilters, setAllFilters] = useState({});
  const selectedFiltersScrollContainerRef = useRef(null);

  const renderFiltersBasedOnCondition = (id, comp) => { if (allFilters[id]?.checked) { return comp; } else { return null; } };

  const scroll = (direction) => {
    if (selectedFiltersScrollContainerRef.current) {
      const { scrollLeft, clientWidth } =
        selectedFiltersScrollContainerRef.current;
      const scrollAmount = direction === "next" ? clientWidth : -clientWidth;
      selectedFiltersScrollContainerRef.current.scrollTo({
        left: scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
  };



  //handle check library access
  const handleAccess = (value, item) => {
    if (localStorage.getItem('token') !== null) {
      if (value === 'my_library') {
        dispatch(setSelectedLibraryList([{
          _id: 'my_library',
          name: 'my_library',
          label: 'My Library',
          value: 'my_library',
        }]));
        setActiveLibType(value)
        dispatch(genericFilterHandler());
      }
      else if (userDetails.user_subscription.length) {
        dispatch(setSelectedLibraryList([{ _id: item.name, name: item.name, label: item.title, value: item.name, }]));
        setActiveLibType(value)
        dispatch(genericFilterHandler());
      }
      else {
        dispatch(setSelectedLibraryList([{
          _id: 'my_library',
          name: 'my_library',
          label: 'My Library',
          value: 'my_library',
        }]));
        setActiveLibType('my_library')
        dispatch(genericFilterHandler());
        dispatch({ type: UPGRADEPLAN, payload: true });
      }
    }
    else {
      dispatch({ type: IPSERAHCOUNT, payload: true });
    }

  }



  return (
    <>
      <div className="main-container library">
        {viewFullViewer ? (
          <ViewFullScreen
            docFullViewData={docData}
            handleLibrary={handleLibraryNavigation}
            setViewFullViewer={setViewFullViewer}
            activeTab={activeTab}
          />
        ) : (
          <>
            <div className="search-result-head">
              <div className="search-main">
                <Box className="search-head">
                  <div className="search-left">
                    <div className="search-result-head">
                      {/* <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleLibraryNavigation()}>
                            library</Link>
                        </li>
                        {
                          sellAllresults ?
                            <li>
                              All saved items
                            </li>
                            : ''
                        }
                      </ul> */}

                      <h4>
                        {searchData?.selectedLibraryList?.[0]?.label || ""}
                      </h4>
                    </div>
                  </div>
                  {console.log("activeLibType", activeLibType === 'my_library')}
                  <div className="home_banner_search_radio_left">
                    <div class="custom-radio-button">
                      <div
                        onClick={(e) => {
                          handleAccess('my_library')
                          setActiveLibType('my_library')
                        }}
                      >
                        <input
                          type="radio"
                          name='my_library'
                          value='my_library'
                          checked={activeLibType === 'my_library'}
                        />
                        <label for='my_library' onClick={() => setActiveLibType('my_library')} >
                          <span> My Library </span>
                        </label>
                      </div>
                      {LibHeadAction.map((item) => {
                        return (
                          <div
                            onClick={(e) => {
                              console.log(e.target.value, "targetValue");
                              handleAccess(item.name, item)
                            }}
                          >
                            <input
                              type="radio"
                              name={item.name}
                              value={item.name}
                              checked={searchData?.selectedLibraryList[0]?.value === item?.name}
                            />
                            <label for={item.name} onClick={() => setActiveLibType(item.name)} >
                              <span> {item.title} </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Box>
              </div>
              <div className="filters-main">
                <div className="filter-row">
                  <div className="filter-col">
                    <TextField
                      sx={{ width: "151px" }}
                      hiddenLabel
                      className="textfield"
                      id="exclude-keywords"
                      placeholder="Filter By Title"
                      variant="outlined"
                      size="small"
                      value={flteredTitle}
                      onChange={(e) => handleWord(e.target.value, "filterbytitle")}
                    />
                  </div>
                  {console.log("contractTitleList", contractTitleList)}
                  {/* <div className="filter-col">
                    <LibraryMiuiAsyncSelect
                      multiple
                      placeholder="Contract Type"
                      ddname="ContractType"
                      loadOptions={contractTitleList}
                      setSelected={setSelectedContractTitle}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedContractTitle}
                      isDropdownIcon={true}
                    />
                  </div> */}

                  <div className="filter-col" onClick={() => setContractTypePopup(true)} >
                    <LibraryMiuiAsyncSelect
                      multiple
                      readOnly
                      placeholder="Contract Type"
                      ddname="ContractType"
                      loadOptions={contractTitleList}
                      setSelected={setSelectedContractTitle}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedContractTitle}
                    />
                  </div>


                  {/* <div className="filter-col">
                    <LibraryMiuiAsyncSelect
                      multiple
                      placeholder="Industry"
                      ddname="Industry"
                      loadOptions={industryList}
                      setSelected={setSelectedIndustry}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedIndustry}
                      isDropdownIcon={true}
                    />
                  </div> */}

                  <div className="filter-col" onClick={() => setIndustryPopup(true)} >
                    <LibraryMiuiAsyncSelect
                      multiple
                      readOnly
                      placeholder="Industry"
                      ddname="Industry"
                      loadOptions={industryList}
                      setSelected={setSelectedIndustry}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedIndustry}
                      isDropdownIcon={true}
                    />
                  </div>



                  {/* <div className="filter-col">
                    <LibraryMiuiAsyncSelect
                      multiple
                      placeholder="Department"
                      ddname="Department"
                      groupIds={groupIds}
                      loadOptions={departmentList}
                      setSelected={setSelectedDepartment}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedDepartment}
                      isDropdownIcon={true}
                    />
                  </div> */}

                  <div className="filter-col" onClick={() => setDepartmentPopup(true)} >
                    <LibraryMiuiAsyncSelect
                      multiple
                      readOnly
                      placeholder="Department"
                      ddname="Department"
                      groupIds={groupIds}
                      loadOptions={departmentList}
                      setSelected={setSelectedDepartment}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedDepartment}
                      isDropdownIcon={true}
                    />
                  </div>


                  {/* <div className="filter-col">
                    <LibraryMiuiAsyncSelect
                      multiple
                      placeholder="clauses"
                      ddname="clauses"
                      groupIds={groupIds}
                      loadOptions={clausesList}
                      setSelected={setSelectedClause}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedClause}
                      isDropdownIcon={true}
                    />
                  </div> */}

                  {/* <div className="filter-col">
                    <LibraryMiuiAsyncSelect
                      multiple
                      placeholder="Governing Law"
                      ddname="GoverningLaw"
                      loadOptions={governingLawList}
                      setSelected={setSelectedGoverningLaw}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedGoverningLaw}
                      isDropdownIcon={true}
                    />
                  </div> */}

                  <div className="filter-col" onClick={() => setGoverningLawPopup(true)} >
                    <LibraryMiuiAsyncSelect
                      multiple
                      readOnly
                      placeholder="Governing Law"
                      ddname="GoverningLaw"
                      loadOptions={governingLawList}
                      setSelected={setSelectedGoverningLaw}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedGoverningLaw}
                      isDropdownIcon={true}
                    />
                  </div>



                  {/* <div className="filter-col">
                    <LibraryMiuiAsyncSelect
                      multiple
                      placeholder="Country"
                      ddname="Country"
                      loadOptions={countryList}
                      setSelected={setSelectedCountry}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedCountry}
                      isDropdownIcon={true}
                    />
                  </div> */}

                  <div className="filter-col" onClick={() => setCountryPopup(true)} >
                    <LibraryMiuiAsyncSelect
                      multiple
                      readOnly
                      placeholder="Country"
                      ddname="Country"
                      loadOptions={countryList}
                      setSelected={setSelectedCountry}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedCountry}
                      isDropdownIcon={true}
                    />
                  </div>

                  <div className="filter-col" onClick={() => setCounterPartyPopup(true)} >
                    <LibraryMiuiAsyncSelect
                      multiple
                      readOnly
                      placeholder="Party/Counter Party"
                      ddname='CounterParty'
                      loadOptions={counterPartyList}
                      setSelected={setSelectedCounterPartyList || []}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedCounterParty}

                    />
                  </div>

                  <div className="filter-col">
                    <ChipInput
                      id="includekeyword"
                      variant="outlined"
                      color="primary"
                      size="small"
                      className="taginput"
                      hiddenLabel
                      alwaysShowPlaceholder={true}
                      dataSource={searchData?.includeKeyword}
                      value={searchData?.includeKeyword}
                      placeholder={"Include Keywords"}
                      onAdd={(chip) => handleWord(chip, "Include Keywords")}
                    />
                  </div>

                  <div className="filter-col">
                    <ChipInput
                      id="excludekeyword"
                      variant="outlined"
                      color="primary"
                      size="small"
                      className="taginput"
                      hiddenLabel
                      alwaysShowPlaceholder={true}
                      dataSource={searchData?.excludeKeyword}
                      value={searchData?.excludeKeyword}
                      placeholder={"Exclude Keywords"}
                      onAdd={(chip) => handleWord(chip, "Exclude Keywords")}
                    />
                  </div>


                  {renderFiltersBasedOnCondition(
                    9,
                    <div className="filter-col" style={{ maxWidth: '223px' }}>
                      <DateRangePicker
                        style={{ width: '100%', height: '40px', fontSize: '15px' }}
                        cleanable
                        showOneCalendar
                        placeholder="Date Range"
                        defaultValue={
                          searchData?.selectedDate?.length
                            ? [
                              new Date(searchData.selectedDate[0]),
                              new Date(searchData.selectedDate[1]),
                            ]
                            : []
                        }
                        onOk={(date) => handleDate(date)}
                        onClean={(date) => handleRemove(date)}
                        onShortcutClick={(date) => handleShortCutDate(date)}
                      />
                    </div>
                  )}


                  {console.log("setSelectedLibraryList", searchData?.selectedLibraryList)}
                  <div className="filter-col singleselect">
                    <LibraryMiuiSingleSelect
                      placeholder="Library Type"
                      ddname="LibraryType"
                      loadOptions={libraryList}
                      setSelected={setSelectedLibraryList}
                      genericFilterHandler={genericFilterHandler}
                      value={searchData?.selectedLibraryList}
                      searchScreen={searchScreen}
                      url={window.location.pathname.split("/")[2]}
                      isDropdownIcon={true}
                    />
                  </div>


                  <>
                    {renderFiltersBasedOnCondition(
                      2,
                      <div className="filter-col" onClick={() => setCikPopup(true)} >
                        <LibraryMiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="CIK"
                          ddname="CIK"
                          loadOptions={cikList}
                          setSelected={setSelectedCIKList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedCIKList}
                        />
                      </div>
                    )}

                    {renderFiltersBasedOnCondition(
                      3,
                      <div className="filter-col" onClick={() => setAccessionPopup(true)} >
                        <LibraryMiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="Accession Number"
                          ddname="AccessionNumber"
                          loadOptions={accessionNumberList}
                          setSelected={setSelectedAccessionNumberList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedAccessionNumberList}
                        />
                      </div>
                    )}

                    {/* {renderFiltersBasedOnCondition(
                      4,
                      <div className="filter-col">
                        <LibraryMiuiAsyncSelect
                          multiple
                          placeholder="Entity Type"
                          ddname="EntityType"
                          loadOptions={entityTypeList}
                          setSelected={setSelectedEntityTypeList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedEntityTypeList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )} */}


                    {renderFiltersBasedOnCondition(
                      4,
                      <div className="filter-col" onClick={() => setEntityTypePopup(true)} >
                        <LibraryMiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="Entity Type"
                          ddname="EntityType"
                          loadOptions={entityTypeList}
                          setSelected={setSelectedEntityTypeList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedEntityTypeList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )}

                    {renderFiltersBasedOnCondition(
                      5,
                      <div
                        className="filter-col"
                        onClick={() => setFormPopup(true)}
                      >
                        <LibraryMiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="Form"
                          ddname="Forms"
                          loadOptions={formsList}
                          setSelected={setSelectedFormsList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedFormsList}
                        />
                      </div>
                    )}


                    {/* {renderFiltersBasedOnCondition(
                      6,
                      <div className="filter-col">
                        <LibraryMiuiAsyncSelect
                          multiple
                          placeholder="Stock Exchange"
                          ddname="StockExchanges"
                          loadOptions={stockExchangesList}
                          setSelected={setSelectedStockExchangesList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedStockExchangesList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )} */}


                    {renderFiltersBasedOnCondition(
                      6,
                      <div className="filter-col" onClick={() => setStockExchangesPopup(true)} >
                        <LibraryMiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="Stock Exchange"
                          ddname="StockExchanges"
                          loadOptions={stockExchangesList}
                          setSelected={setSelectedStockExchangesList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedStockExchangesList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )}

                    {/* {renderFiltersBasedOnCondition(
                      7,
                      <div className="filter-col">
                        <LibraryMiuiAsyncSelect
                          multiple
                          placeholder="Exhibit ID"
                          ddname="ExhibitID"
                          loadOptions={exhibitIDList}
                          setSelected={setSelectedExhibitList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedExhibitIDList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )} */}

                    {renderFiltersBasedOnCondition(
                      7,
                      <div className="filter-col" onClick={() => setEntityTypePopup(true)} >
                        <LibraryMiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="Exhibit ID"
                          ddname="ExhibitID"
                          loadOptions={exhibitIDList}
                          setSelected={setSelectedExhibitList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedExhibitIDList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )}


                    {/* {renderFiltersBasedOnCondition(
                      8,
                      <div className="filter-col">
                        <LibraryMiuiAsyncSelect
                          multiple
                          placeholder="State of Incorporation"
                          ddname="StateOfIncorporation"
                          loadOptions={stateOfIncorporationList}
                          setSelected={setSelectedstateOfIncorporationList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedStateOfIncorporationList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )} */}

                    {renderFiltersBasedOnCondition(
                      8,
                      <div className="filter-col" onClick={() => setStateOfIncoPopup(true)} >
                        <LibraryMiuiAsyncSelect
                          multiple
                          readOnly
                          placeholder="State of Incorporation"
                          ddname="StateOfIncorporation"
                          loadOptions={stateOfIncorporationList}
                          setSelected={setSelectedstateOfIncorporationList}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedStateOfIncorporationList}
                          isDropdownIcon={true}
                        />
                      </div>
                    )}
                  </>
                  {/* <div className="filter-col sort-by-library library">
                    <SortBySection
                      activeTab={""}
                      CustomButton={({ ref, title, ...props }) => {
                        return (
                          <button ref={ref} {...props}>
                            {title}
                            <DropdownIcon />
                          </button>
                        );
                      }}
                    />
                  </div> */}
                </div>

                {/* <div className="sort-by">
                  <div className="filter-row">
                    <div className="filter-col">
                      <SelectPicker
                        data={data}
                        cleanable={false}
                        defaultValue="relevance"
                        placeholder="Sort by"
                        searchable={false}
                        style={{ width: 224 }}
                        onChange={(data) => {
                          console.log(data, "data");
                          dispatch(genericFilterHandler("", "", null, 0, data));
                        }}
                      />
                    </div>
                  </div>
                </div> */}

                <MoreFiltersModalLibrary
                  onClick={() => setMoreFilter(!moreFilter)}
                  title={!moreFilter ? "More Filter" : "Less Filter"}
                  setAllFilters={setAllFilters}
                />
              </div>

              <div className="selected-filter">
                {console.log("selectedFilters", selectedFilters)}
                {!checkArrays(selectedFilters) ? (
                  <button
                    onClick={() => scroll("prev")}
                    className="selected-filter-scroll-button"
                  >
                    <GoPrevIcon />
                  </button>
                ) : null}
                <ul ref={selectedFiltersScrollContainerRef}>

                  {Object.keys(selectedFilters).map((item, i) => {
                    return selectedFilters[item].length ? (
                      <li key={i}>
                        <h6>{item}: </h6>
                        <ul>
                          {selectedFilters[item]?.map((selected, index) => {
                            return (
                              <li key={index}>
                                <div className="selected">
                                  <span>{selected?.name}</span>
                                  <MdClose
                                    onClick={() =>
                                      handleRemoveSelected(item, selected?._id)
                                    }
                                  />
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>
                {!checkArrays(selectedFilters) ? (
                  <button
                    onClick={() => scroll("next")}
                    className="selected-filter-scroll-button"
                  >
                    <GoNextFilterIcon />
                  </button>
                ) : null}
                {Object.values(selectedFilters).some(
                  (array) => array.length > 0
                ) ? (
                  <Button onClick={() => handleRemoveAllSelected()}> Clear All </Button>) : ("")}
              </div>

              {clientList?.length === 0 ? (
                <div>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    className="navbar_wrp_right"
                    fullWidth="true"
                    justifyContent={"flex-end"}
                    sx={{ mb: 2 }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<PlusIcon />}
                      onClick={() => setCustomCreateClient(true)}
                      sx={{
                        width: "174px",
                        backgroundColor: "#005287",
                        "&:hover": {
                          backgroundColor: "#005287",
                        },
                      }}
                      className="add-custom-client-btn"
                    >
                      Add Custom Client
                    </Button>
                    <Button
                      onClick={() => setCCmodal(true)}
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      sx={{
                        width: "199px",
                        backgroundColor: "#fff",
                        borderColor: "#868686",
                        color: "#868686",
                      }}
                      className="remove-custom-client-btn"
                    >
                      Remove Custom Client
                    </Button>
                  </Stack>
                </div>
              ) : (
                ""
              )}

              {searchScreen && (
                <>
                  <Stack justifyContent={'space-between'} flexDirection={'row'}>
                    <div className="head-left">
                      <div className="active" onClick={() => handleLibraryNavigation()}
                        style={{ paddingLeft: '15px', paddingBottom: '10px', cursor: 'pointer' }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.5 11.4998H1.089C1.164 11.2908 1.287 11.0988 1.453 10.9348L5.401 6.89175C5.594 6.69475 5.59 6.37775 5.392 6.18475C5.195 5.99275 4.879 5.99575 4.685 6.19375L0.744 10.2298C0.27 10.6968 0.006 11.3348 0 11.9998C0.002 12.6578 0.258 13.2958 0.716 13.7608L4.639 17.8468C4.737 17.9488 4.868 17.9998 5 17.9998C5.125 17.9998 5.25 17.9538 5.346 17.8598C5.545 17.6698 5.552 17.3518 5.361 17.1528L1.433 13.0618C1.273 12.8998 1.154 12.7078 1.082 12.4998H23.5C23.776 12.4998 24 12.2758 24 11.9998C24 11.7238 23.776 11.4998 23.5 11.4998Z" fill="#303030" />
                        </svg>
                      </div>
                    </div>
                    <div className="head-right">
                      <ul>
                        <li>
                          <span
                            className={`preview ${previewShow ? "active" : ""}`}
                            onClick={() => handlePrviewClick()}
                          >
                            <MdRemoveRedEye />
                            Preview
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Stack>
                </>
              )}
            </div>

            {console.log("libraryResult", libraryResult)}

            {!searchScreen ? (
              <>
                {sellAllresults ? (
                  <Card>
                    <CardContent className="save-library-list">
                      <div className="library_list">
                        <div className="library_list_header">
                          <div onClick={() => setSellAllresults(false)}>
                            <BackIcon />
                          </div>
                          <h6> Saved {fullResultDataTitle} </h6>
                        </div>
                        <div className="library_list_content">
                          {console.log("mk", libraryResult, fullResultDataTitle, libraryResult?.filter((_) => _?.bookmark_type === fullResultDataTitle))}
                          <ul>
                            {
                              libraryResult?.filter((_) => _?.bookmark_type === fullResultDataTitle)?.map((item, i) => {
                                // fullResultData.map((item, i) => {
                                return (
                                  <>
                                    <li>
                                      <div className="selected">
                                        <span
                                          onClick={() => handleClickedGroup(item)}
                                        >
                                          {item.bookmark_name
                                            .toLowerCase()
                                            .replace(/(^|\s)\S/g, (match) =>
                                              match.toUpperCase()
                                            )}
                                        </span>
                                        <p>({item.count})</p>
                                        <MdClose
                                          fill="#C6C6C6"
                                          size={24}
                                          onClick={() => hanleRemoveGroup(item)}
                                        />
                                      </div>
                                    </li>
                                  </>
                                );
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <Card>
                    <CardContent>
                      <div className="library_list">
                        <div className="library_list_header">
                          <h6> Contracts </h6>
                          <span
                            className={libraryResult?.filter((_) => _?.bookmark_type === "contracts")?.length < 15 ? "disable" : ""}
                            onClick={() => handleFullResults(libraryResult?.filter((_) => _?.bookmark_type === "contracts"), "contracts")}
                          >
                            See all saved items
                          </span>
                        </div>
                        <div className="library_list_content">
                          {isLoading ? (
                            <div style={{ height: "100%", position: "relative" }} >
                              <Loader center content="Loading Data..." />
                            </div>
                          ) : (
                            <ul>
                              {libraryResult?.filter((_) => _?.bookmark_type === "contracts")?.length ?
                                (libraryResult?.filter((_) => _?.bookmark_type === "contracts")?.slice(0, 15)?.map((item, i) => {
                                  return (
                                    <>
                                      <li>
                                        <div className="selected">
                                          <span
                                            onClick={() => handleClickedGroup(item, "contracts")}
                                          >
                                            {item.bookmark_name.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())} </span>
                                          <p>({item.count})</p>
                                          <MdClose
                                            fill="#C6C6C6"
                                            size={24}
                                            onClick={() => hanleRemoveGroup(item)}
                                          />
                                        </div>
                                      </li>
                                    </>
                                  );
                                })
                                ) : (
                                  <li>
                                    <div className="selected">No Data Found</div>
                                  </li>
                                )}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="library_list">
                        <div className="library_list_header">
                          <h6> Clauses </h6>
                          <span
                            className={libraryResult?.filter((_) => _?.bookmark_type === "clauses")?.length < 15 ? "disable" : ""}
                            onClick={() => handleFullResults(libraryResult?.filter((_) => _?.bookmark_type === "clauses"), "clauses")}
                          >

                            See all saved items
                          </span>
                        </div>
                        <div className="library_list_content">
                          {isLoading ? (
                            <div style={{ height: "100%", position: "relative" }} >
                              <Loader center content="Loading Data..." />
                            </div>
                          ) : (
                            <ul>
                              {libraryResult?.filter((_) => _?.bookmark_type === "clauses")?.length ?
                                (libraryResult?.filter((_) => _?.bookmark_type === "clauses")?.slice(0, 15)?.map((item, i) => {
                                  return (
                                    <>
                                      <li>
                                        <div className="selected">
                                          <span onClick={() => handleClickedGroup(item, "clauses")}
                                          >
                                            {item.bookmark_name.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
                                          </span>
                                          <p>({item.count})</p>
                                          <MdClose
                                            fill="#C6C6C6"
                                            size={24}
                                            onClick={() => hanleRemoveGroup(item)}
                                          />
                                        </div>
                                      </li>
                                    </>
                                  );
                                })
                                ) : (
                                  <li> <div className="selected">No Data Found</div> </li>)}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="library_list">
                        <div className="library_list_header">
                          <h6> Definitions </h6>
                          <span
                            className={libraryResult?.filter((_) => _?.bookmark_type === "definitions")?.length < 15 ? "disable" : ""}
                            onClick={() => handleFullResults(libraryResult?.filter((_) => _?.bookmark_type === "definitions"), "definitions")}
                          >
                            See all saved items
                          </span>
                        </div>
                        <div className="library_list_content">
                          {isLoading ? (
                            <div
                              style={{ height: "100%", position: "relative" }}
                            >
                              <Loader center content="Loading Data..." />
                            </div>
                          ) : (
                            <ul>
                              {libraryResult?.filter((_) => _?.bookmark_type === "definitions")?.length ?
                                (libraryResult?.filter((_) => _?.bookmark_type === "definitions")?.slice(0, 15)?.map((item, i) => {
                                  return (
                                    <>
                                      <li>
                                        <div className="selected">
                                          <span onClick={() => handleClickedGroup(item, "definitions")} >
                                            {item.bookmark_name.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
                                          </span>
                                          <p>({item.count})</p>
                                          <MdClose
                                            fill="#C6C6C6"
                                            size={24}
                                            onClick={() => hanleRemoveGroup(item)}
                                          />
                                        </div>
                                      </li>
                                    </>
                                  );
                                })
                                ) : (
                                  <li>
                                    <div className="selected">No Data Found</div>
                                  </li>
                                )}
                            </ul>
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                )}
              </>
            ) : (
              <Box className="search-result-body">
                <div className={`left ${previewShow ? "preview-open" : ""}`}>
                  <ul style={{ height: "100%" }}>
                    {isLoading ? (
                      <div style={{ height: "100%", position: "relative" }}>
                        <Loader center content="Loading Data..." />
                      </div>
                    ) : fullLibraryResult?.results?.length ? (
                      fullLibraryResult?.results?.map((item, i) => (
                        <li className={item?.selected && previewShow ? "selected" : ""} >
                          {/* <span className='remove'>
                                                            <MdDelete
                                                                fontSize={20}
                                                                fill='#505050'
                                                                onClick={() => handleRemoveSingleContarct(item)}
                                                            />
                                                        </span> */}
                          <div onClick={() => onSelectHandler(item, i)}>
                            <div className="title">
                              {/* <span className="number">{i + 1}. </span> */}
                              <h5>{item?.contract_title}</h5>
                            </div>
                            <div className="doc_content">
                              <div className="tags-main">
                                <ul>
                                  {item?.contract_title && (
                                    <li>
                                      <p>
                                        <b>Contract Type: </b>
                                        {item?.contract_title}
                                      </p>
                                    </li>
                                  )}
                                  {item?.industry && (
                                    <li>
                                      <p>
                                        <b>Industry: </b>
                                        {item?.industry || "NA"}
                                      </p>
                                    </li>
                                  )}
                                  {item?.department && (
                                    <li>
                                      <p>
                                        <b>Department: </b>
                                        {item?.department
                                          ?.map((d) => d.department_type)
                                          .join(", ")}
                                      </p>
                                    </li>
                                  )}

                                  {item?.governing_law && (
                                    <li>
                                      <p>
                                        <b>Governing Law: </b>
                                        {item?.governing_law || "NA"}
                                      </p>
                                    </li>
                                  )}

                                  {item?.filing_date && (
                                    <li>
                                      <p>
                                        <b>Filing Date: </b>
                                        {new Date(item?.filing_date).toISOString().split("T")[0]}
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              <div className="description">
                                {item?.description?.map((desc) => (
                                  <>
                                    <p>
                                      <div dangerouslySetInnerHTML={{ __html: desc, }} ></div>
                                    </p>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li>
                        <h3 style={{ textAlign: "center" }}>
                          No Result Found
                        </h3>
                      </li>
                    )}
                  </ul>
                </div>

                {previewShow && (
                  <div className="right" ref={rootRef}>
                    <div>
                      <Stack
                        style={{ padding: "0 10px" }}
                        justifyContent="space-between"
                        direction={"row"}
                      >
                        {/* <span
                          className="custombtn"
                          onClick={() => setViewFullViewer(!viewFullViewer)}
                        >
                          <CgScreenShot
                            fontSize={24}
                            style={{
                              transform: "rotate(90deg)",
                              marginRight: "5px",
                            }}
                          />
                          View Full Contract
                        </span> */}
                        <span
                          className="custombtn"
                          onClick={() => setViewFullViewer(!viewFullViewer)}
                        >
                          Go to Full Page
                          <FullPageIcon />
                        </span>
                        <Stack className="save_dropdwon" alignItems="center">
                          <span
                            onClick={() => {
                              if (localStorage.getItem("token")) {
                                setShowSaveDD(!showSaveDD);
                              } else {
                                toast.warning(`Please Login First to save contract`);
                              }
                            }}
                          >
                            {/* Save
                            <MdOutlineBookmarkAdd
                              fontSize={24}
                              style={{ marginLeft: "5px" }}
                            /> */}
                            <BookmarkIcon />
                          </span>
                          <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? "active" : ""}`} >
                            <ul>
                              <li className="customcheck rightside">
                                <input type="checkbox"
                                  id="My Library"
                                  name="my_library"
                                  checked={bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "my_library"}
                                  onChange={(e) => handleBookmark(e, bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                />
                                <label htmlFor="My Library"> My Library </label>
                              </li>

                              <li className="customcheck rightside">
                                <input type="checkbox"
                                  id="Team Library"
                                  name="team_library"
                                  checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === "team_library"}
                                  onChange={(e) => handleBookmark(e, bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                />
                                <label htmlFor="Team Library"> Team Library </label>
                              </li>

                              <li onClick={() => setSaveModal(true)}>
                                <span>
                                  Client Library
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                  </svg>
                                </span>
                              </li>

                              <li onClick={() => setCustomBookMarkModal(true)}>
                                <span>
                                  Custom Library
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4995 18.9996C10.3765 18.9996 10.2545 18.9546 10.1585 18.8646C9.95653 18.6766 9.94653 18.3596 10.1345 18.1576L14.2765 13.7276C14.7475 13.2566 14.9995 12.6476 14.9995 11.9986C14.9995 11.3496 14.7475 10.7406 14.2885 10.2816L10.1345 5.84063C9.94653 5.63863 9.95653 5.32163 10.1585 5.13363C10.3595 4.94663 10.6765 4.95463 10.8655 5.15663L15.0075 9.58663C15.6435 10.2226 16.0005 11.0826 16.0005 11.9986C16.0005 12.9146 15.6435 13.7746 14.9965 14.4226L10.8665 18.8406C10.7675 18.9456 10.6345 18.9986 10.5015 18.9986L10.4995 18.9996Z" fill="#303030" />
                                  </svg>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Stack>
                      </Stack>
                      {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                    </div>
                    {console.log("docData", docData)}
                    <div style={{ padding: "10px", overflow: "auto" }}>
                      {docData?.raw_text ? (regex.test(docData?.raw_text) ? (
                        <div dangerouslySetInnerHTML={{ __html: docData?.raw_text, }} ></div>) :
                        (
                          <pre>
                            <div>{docData?.raw_text}</div>
                          </pre>
                        )
                      ) : regex.test(docData?.raw_text) ? (
                        <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />"), }} ></div>
                      ) : (
                        <pre>
                          <div>{docData?.formatted_text}</div>
                        </pre>
                      )}
                    </div>

                    <SaveBM
                      docData={docData}
                      bookmarkList={bookmarkList}
                      activeTab={activeTab}
                      fetchBookmarkList={fetchBookmarkList}
                      rootRef={() => rootRef?.current}
                      saveModal={saveModal}
                      setSaveModal={setSaveModal}
                      customBookMarkModal={customBookMarkModal}
                      setCustomBookMarkModal={setCustomBookMarkModal}
                      library={"library"}
                    />
                  </div>
                )}
              </Box>
            )}
          </>
        )}
      </div >

      <NewModalComponent
        maxWidth={'400px'}
        maxHeight={'190px'}
        open={open}
        onClose={handleClose}
      >
        <Card sx={{
          padding: '10px', textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
          <Stack direction={"row"} justifyContent={"center"} gap={2} alignItems={"center"} mb={1} >
            <WarningIcon color="info" /> <h4> Alert </h4>
          </Stack>

          <p style={{ marginBottom: "25px !important" }}>
            Please Login First To See Library
          </p>

          <Stack direction={"row"} justifyContent={"center"} gap={2} mt={3}>
            <CustomButton variant="contained" href="/" text="Home" />
            <CustomButton variant="contained" href="/login" text="Login" />
          </Stack>
        </Card>
      </NewModalComponent >

      <ModalComponent
        width="calc(100% - 30px)"
        maxWidth="575px"
        open={customCreateClient}
        handleClose={() => setCustomCreateClient(false)}
        className="custom_client"
        padding={2}
      >
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            className="custom-client-header"
          >
            <h4 style={{ fontSize: "24px" }}>Add Custom Client</h4>
            {/* <IconButton
              className="close_modal"
              color="primary"
              onClick={() => setCustomCreateClient(false)}
              component="label"
            >
              <CloseIcon fill="#1C1B1F" />
            </IconButton> */}
          </Stack>

          <Stack mb={5} mt={2} className="custom-client-main-body">
            {/* <InputLabel mb={1}> Custom Client Name </InputLabel> */}
            <TextField
              id="outlined-basic"
              variant="outlined"
              hiddenLabel
              size="small"
              onChange={(e) => setCustomClientName(e.target.value)}
              placeholder="Custom Client Name"
            />
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
            className="custom-client-footer"
          >
            <Button
              color="primary"
              onClick={() => setCustomCreateClient(false)}
              sx={{ color: "#909090", }}
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleCustomClient()}
              sx={{
                backgroundColor: "#005287",
                "&:hover": {
                  backgroundColor: "#005287",
                },
              }}
              className="add-btn"
            >

              Add
            </Button>
          </Stack>
        </>
      </ModalComponent>

      {CCmodal && <RemoveClient open={CCmodal} onClose={handleCloseCCModal} />}


      {
        contractTypePopup && (
          <MiuiLibrarySelectPopup
            open={contractTypePopup}
            close={() => setContractTypePopup(false)}
            ddname="ContractType"
            placeholder="Contract Type"
            loadOptions={contractTitleList}
            setSelected={setSelectedContractTitle}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedContractTitle}
          />
        )
      }

      {
        industryPopup && (
          <MiuiLibrarySelectPopup
            open={industryPopup}
            close={() => setIndustryPopup(false)}
            placeholder="Industry"
            ddname="Industry"
            loadOptions={industryList}
            setSelected={setSelectedIndustry}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedIndustry}
          />
        )
      }

      {
        departmentPopup && (
          <MiuiLibrarySelectPopup
            open={departmentPopup}
            close={() => setDepartmentPopup(false)}
            placeholder="Department"
            ddname="Department"
            loadOptions={departmentList}
            setSelected={setSelectedDepartment}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedDepartment}
          />
        )
      }

      {
        clausesPopup && (
          <MiuiLibrarySelectPopup
            open={clausesPopup}
            close={() => setClausesPopup(false)}
            placeholder="Clauses"
            ddname="Clauses"
            loadOptions={clausesList}
            setSelected={setSelectedClause}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedClause}
          />
        )
      }

      {
        governingLawPopup && (
          <MiuiLibrarySelectPopup
            open={governingLawPopup}
            close={() => setGoverningLawPopup(false)}
            placeholder="Governing Law"
            ddname="GoverningLaw"
            loadOptions={governingLawList}
            setSelected={setSelectedGoverningLaw}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedGoverningLaw}
          />
        )
      }

      {
        countryPopup && (
          <MiuiLibrarySelectPopup
            open={countryPopup}
            close={() => setCountryPopup(false)}
            placeholder="Country"
            ddname="Country"
            loadOptions={countryList}
            setSelected={setSelectedCountry || []}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedCountry}
          />
        )
      }

      {
        counterPartyPopup && (
          <MiuiLibrarySelectPopup
            open={counterPartyPopup}
            close={() => setCounterPartyPopup(false)}
            placeholder="Party/Counter Party"
            ddname="CounterParty"
            loadOptions={counterPartyList}
            setSelected={setSelectedCounterPartyList || []}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedCounterParty}
          />
        )
      }

      {
        entityTypePopup && (
          <MiuiLibrarySelectPopup
            open={entityTypePopup}
            close={() => setEntityTypePopup(false)}
            placeholder="Entity Type"
            ddname="EntityType"
            loadOptions={entityTypeList}
            setSelected={setSelectedEntityTypeList}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedEntityTypeList}
          />
        )
      }

      {
        stockExchangesPopup && (
          <MiuiLibrarySelectPopup
            open={stockExchangesPopup}
            close={() => setStockExchangesPopup(false)}
            placeholder="Stock Exchange"
            ddname="StockExchanges"
            loadOptions={stockExchangesList}
            setSelected={setSelectedStockExchangesList}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedStockExchangesList}
          />
        )
      }

      {
        exhibitIDPopup && (
          <MiuiLibrarySelectPopup
            open={exhibitIDPopup}
            close={() => setExhibitIDPopup(false)}
            placeholder="Exhibit ID"
            ddname="ExhibitID"
            loadOptions={exhibitIDList}
            setSelected={setSelectedExhibitList}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedExhibitIDList}
          />
        )
      }

      {
        stateOfIncoPopup && (
          <MiuiLibrarySelectPopup
            open={stateOfIncoPopup}
            close={() => setStateOfIncoPopup(false)}
            placeholder="State of Incorporation"
            ddname="StateOfIncorporation"
            loadOptions={stateOfIncorporationList}
            setSelected={setSelectedstateOfIncorporationList}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedStateOfIncorporationList}
          />
        )
      }

      {
        cikPopup && (
          <MiuiLibrarySelectPopup
            open={cikPopup}
            close={() => setCikPopup(false)}
            placeholder="CIK"
            ddname="CIK"
            loadOptions={cikList}
            setSelected={setSelectedCIKList}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedCIKList}
          />
        )
      }

      {
        accessionPopup && (
          <MiuiLibrarySelectPopup
            open={accessionPopup}
            close={() => setAccessionPopup(false)}
            placeholder="Accession Number"
            ddname="AccessionNumber"
            loadOptions={accessionNumberList}
            setSelected={setSelectedAccessionNumberList}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedAccessionNumberList}
          />
        )
      }

      {
        formPopup && (
          <MiuiLibrarySelectPopup
            open={formPopup}
            close={() => setFormPopup(false)}
            placeholder="Form"
            ddname="Forms"
            loadOptions={formsList}
            setSelected={setSelectedFormsList}
            genericFilterHandler={genericFilterHandler}
            value={searchData?.selectedFormsList}
          />
        )
      }
    </>
  );
}

export default Library;
