import { Button } from '@mui/material'
import React from 'react'

function PlanTable({ frequency }) {

    return (
        <table>
            {console.log("frequency---", frequency)}
            <thead>
                <tr>
                    <th> </th>
                    <th className='starter'>
                        <h5> Starter</h5>
                        <Button fullWidth = "true" variant='contained'>
                            <span>
                                Buy for ₹{frequency === 'yearly' ? `${1299 * 4}` : '1299'}
                            </span>
                        </Button>
                    </th>
                    <th className='standard'>
                        <h5> Standard</h5>
                        <Button fullWidth = "true" variant='contained'>
                            <span>
                                Buy for ₹{frequency === 'yearly' ? `${3999 * 4}` : '3999'}
                            </span>
                        </Button>
                    </th>
                    <th className='premium'>
                        <h5> Premium</h5>
                        <Button fullWidth = "true" variant='contained'>
                            <span>
                                Buy for ₹{frequency === 'yearly' ? `${5200 * 4}` : '5200'}
                            </span>
                        </Button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className='heading'>
                    <td> Contracts </td> <td> </td> <td> </td> <td> </td> </tr>
                <tr>
                    <td> Abstract </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Assess </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Assemble </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Templatise </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> KronAI (Contract GenAI) </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>

                <tr className='heading'>
                    <td> Document Automation </td> <td> </td> <td> </td> <td> </td> </tr>
                <tr>
                    <td> Templatise </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> eSign (digibooks) </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Coreyo Integration </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>


                <tr className='heading'>
                    <td> Practice Management </td> <td> </td> <td> </td> <td> </td> </tr>
                <tr>
                    <td> Lexops Bill </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Lexops CRM </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Integration with billing applications (Zoho) </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Firm Analytics </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Sharepoint Integration </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Compliance Calender </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>




                <tr className='heading'>
                    <td> Legal Research </td> <td> </td> <td> </td> <td> </td> </tr>
                <tr>
                    <td> Dealbase (Contract DB) </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Judgement DB </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>


                <tr className='heading'>
                    <td> Litigation Management </td> <td> </td> <td> </td> <td> </td> </tr>
                <tr>
                    <td> Lexops Radar</td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>
                <tr>
                    <td> Lexops Casecheck </td>
                    <td> </td>
                    <td> </td>
                    <td> <img src='/images/mdi_decagram.svg' alt='tick' /> </td>
                </tr>

            </tbody>

        </table>
    )
}

export default PlanTable