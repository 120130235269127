import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
    >
      <path
        d="M2.5 3.5H17.5V18C17.5 19.933 15.933 21.5 14 21.5H6C4.067 21.5 2.5 19.933 2.5 18V3.5Z"
        stroke="#216DDE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 3.5C0 3.22386 0.213198 3 0.47619 3H19.5238C19.7868 3 20 3.22386 20 3.5C20 3.77614 19.7868 4 19.5238 4H0.47619C0.213198 4 0 3.77614 0 3.5Z"
        fill="#216DDE"
      />
      <path
        d="M7.5 2C7.5 1.17157 8.17157 0.5 9 0.5H11C11.8284 0.5 12.5 1.17157 12.5 2V3.5H7.5V2Z"
        stroke="#216DDE"
      />
      <path d="M10 18V6" stroke="#216DDE" stroke-linecap="round" />
      <path d="M14 18V6" stroke="#216DDE" stroke-linecap="round" />
      <path d="M6 18V6" stroke="#216DDE" stroke-linecap="round" />
    </svg>
  );
};

export default DeleteIcon;
