import axios from "axios";


const instance = axios.create({
  baseURL: process.env.REACT_APP_CONTRACT_API_URL,
});


export const getSearchCount = async (payload) => {
  try {
    const res = await instance.post(`/count`, payload);
    return res;
  } catch (error) {
    return error;
  }
};



export const fetchSearchData = async (payload, size, offset, sort) => {
  try {
    const res = await instance.post(`search/?limit=${size}&offset=${offset}&sort=${sort}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchCompany = async (payload, size = 10) => {
  try {
    const res = await instance.post(`company/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchIndustry = async (payload, size = 10) => {
  try {
    const res = await instance.post(`industry/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};
export const fetchDepartment = async (payload, size = 10) => {
  try {
    const res = await instance.post(`departments/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchGoverlingLaw = async (payload, size = 10) => {
  try {
    const res = await instance.post(`governing_law/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchCountry = async (payload, size = 10) => {
  try {
    const res = await instance.post(`country/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchContractTitle = async (payload, size = 10) => {
  try {
    const res = await instance.post(`contracts/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchClauses = async (payload, size = 10) => {
  try {
    const res = await instance.post(`clauses/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchAccession = async (payload, size = 10) => {
  try {
    const res = await instance.post(`accession_number/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};


export const fetchEntityType = async (payload, size = 10) => {
  try {
    const res = await instance.post(`entitytypes/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};


export const fetchForms = async (payload, size = 10) => {
  try {
    const res = await instance.post(`forms/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchStockExchanges = async (payload, size = 10) => {
  try {
    const res = await instance.post(`exchanges/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchStateOfIncorporation = async (payload, size = 10) => {
  try {
    const res = await instance.post(`stateofincorporation/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchExhibitID = async (payload, size = 10) => {
  try {
    const res = await instance.post(`exhibits/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const fetchCIK = async (payload, size = 10) => {
  try {
    const res = await instance.post(`cik/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};


export const fetchCounterParty = async (payload, size = 10) => {
  try {
    const res = await instance.post(`counter_party/?limit=${size}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};



export const fetchSingleContract = async (payload) => {
  try {
    const res = await instance.post(`corresponding`, payload);
    return res;
  } catch (error) {
    return error;
  }
};


export const getAgreementIndex = async (payload) => {
  try {
    const res = await axios.put(`kronicle/api/agreementindex/`, payload);
    return res;
  } catch (error) {
    return error;
  }
};



export const getSigleContarctData = async (id) => {
  try {
    const res = await instance.get(`/contract-data?contract_id=${id}`);
    return res;
  } catch (error) {
    return error;
  }
};


export const getUserSeachCount = async (id) => {
  try {
    const res = await instance.get(`/click_count/?user_id=${id}`);
    return res;
  } catch (error) {
    return error;
  }
};





export const particalSearch = async (payload, size) => {
  try {
    const res = await instance.post(`partial_search/?limit=${size || 100}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};




export const searchSuggestion = async (payload, size, offset, sort) => {
  try {
    const res = await instance.post(`suggestion_search/?limit=${size}&offset=${offset}&sort=${sort}`, payload);
    return res;
  } catch (error) {
    return error;
  }
};