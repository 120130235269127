import React, { useEffect, useState } from "react";
import { getAllBlog } from "../../service/CMSService";
import {
  Card,
  CardBody,
  CardHeader,
  Image,
  SimpleGrid,
  Heading,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SEO from "../../component/SEO";
import Footer from "../../component/Footer";

const BlogList = () => {
  // const targetURL = window.location.origin
  const targetURL = 'https://lexops.co/'
  const navigate = useNavigate();
  const [allBlogs, setAllBlogs] = useState([]);
  const getAllBlogHandler = async () => {
    const res = await getAllBlog();
    const filteredData = res.data.data.filter((item) => {
      if (item.sites) {
        return item.sites.some((site) => site.url === targetURL);
      }
      return false;
    });
    console.log("filteredData", filteredData);
    setAllBlogs(filteredData);
  };

  useEffect(() => {
    getAllBlogHandler();
  }, []);

  return (
    <>
      <SEO
        title={"Blogs" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box
        display="flex"
        p={5}
        alignItems="center"
        justifyContent="center"
        background="rgba(9,99,157,0.8)"
        color="#fff"
      >
        <Box textAlign="center">
          <h2>Blogs</h2>
        </Box>
      </Box>
      <div className="container ">
        <SimpleGrid columns={[1, 2, 3]} my={14} >
          {allBlogs.map((blog, i) => (
            <Card key={i} className="blog_item" p={3} onClick={() => navigate(`/blog/${blog.seoURL}`)}>
              <div className="blog_item_wrp">
                <CardHeader p={0} className="blog_item_wrp_img">
                  <Image src="gibbresh.png" fallbackSrc={blog?.image} />
                  <div className="blog_info">
                    <div>
                      {moment(blog.createdAt).format('DD MMM YYYY')}
                    </div>
                    <div>
                      {blog.subTitle}
                    </div>
                  </div>
                  <Heading
                    p={3}
                    pb={0}
                    className="blog_title"
                    cursor="pointer"
                    onClick={() => navigate(`/blog/${blog.seoURL}`)}
                  >
                    {blog.title}
                  </Heading>
                </CardHeader>
                <CardBody
                  p={3}
                  pt={0}
                >

                  <div
                    className="blog_content"
                    dangerouslySetInnerHTML={{ __html: blog.pageContent }}
                  />
                </CardBody>
              </div>

            </Card>
          ))}
        </SimpleGrid>
      </div>
      <Footer />
    </>
  );
};

export default BlogList;
