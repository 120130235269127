import React from "react";

const InputSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_138_14206)">
        <path
          d="M23.8545 23.1465L17.4265 16.7185C19.0335 14.9435 20.0225 12.5985 20.0225 10.0215C20.0215 4.50848 15.5355 0.021483 10.0215 0.0214835C4.50748 0.021484 0.0214848 4.50848 0.0214852 10.0215C0.0214857 15.5345 4.50749 20.0215 10.0215 20.0215C12.5985 20.0215 14.9435 19.0325 16.7185 17.4255L23.1465 23.8535C23.2445 23.9515 23.3725 23.9995 23.5005 23.9995C23.6285 23.9995 23.7565 23.9505 23.8545 23.8535C24.0495 23.6585 24.0495 23.3415 23.8545 23.1465ZM1.02149 10.0215C1.02148 5.05948 5.05948 1.02148 10.0215 1.02148C14.9835 1.02148 19.0215 5.05848 19.0215 10.0215C19.0215 14.9845 14.9845 19.0215 10.0215 19.0215C5.05849 19.0215 1.02149 14.9845 1.02149 10.0215Z"
          fill="#606060"
        />
      </g>
      <defs>
        <clipPath id="clip0_138_14206">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InputSearchIcon;
