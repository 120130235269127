import React from "react";

const BookmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M9.86568 18.5731C10.2344 18.2705 10.7656 18.2705 11.1343 18.5731L15.3657 22.045C16.0184 22.5805 17 22.1162 17 21.2719L17 11.5L18 11.5L18 21.2719C18 22.9605 16.0367 23.8891 14.7314 22.818L10.5 19.3462L6.26863 22.818C4.96326 23.8891 3 22.9605 3 21.2719L3 7.5C3 6.39543 3.89543 5.5 5 5.5L12 5.5L12 6.5L5 6.5C4.44772 6.5 4 6.94772 4 7.5L4 21.2719C4 22.1162 4.98163 22.5805 5.63432 22.045L9.86568 18.5731Z"
        fill="#303030"
      />
      <rect x="17" y="2.5" width="1" height="7" rx="0.5" fill="#303030" />
      <rect
        x="21"
        y="5.5"
        width="1"
        height="7"
        rx="0.5"
        transform="rotate(90 21 5.5)"
        fill="#303030"
      />
    </svg>
  );
};

export default BookmarkIcon;
