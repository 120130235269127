/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Stack, Tab, Tabs, Card, Link, } from "@mui/material";
import MoreFilters from "./MoreFilters";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { directSearchFilter, genericFilterHandler, setSearchItem, setSearchType, setSelectedDate } from "../../redux/searchSlice";
import { FaChevronRight } from "react-icons/fa";
import word from '../../assets/images/word.svg';
import Addin from '../../assets/images/Home/downloadAddin.svg';
import Draftwith from '../../assets/images/Home/draftwith.svg';
import Slider from "react-slick";
import { getAllBlog, getAllPage } from "../../service/CMSService";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";
import HomeSearch from "../../component/HomeSearch";
import Clasuses from '../../assets/images/Home/clasuses.svg';
import Contracts from '../../assets/images/Home/contracts.svg';
import Definitions from '../../assets/images/Home/definitions.svg';
import ClausesQurey from '../../assets/images/Home/Features/clauses_qurey.svg';
import ContactQurey from '../../assets/images/Home/Features/contact_qurey.svg';
import ContractDiscovery from '../../assets/images/Home/Features/contract_discovery.svg';
import DealAnalytics from '../../assets/images/Home/Features/deal_analytics.svg';
import SaveTime from '../../assets/images/Home/Benefit/save_time.svg';
import MinimiseRisk from '../../assets/images/Home/Benefit/minimise_risk.svg';
import MasterNegotiations from '../../assets/images/Home/Benefit/master_negotiations.svg';
import GainInsights from '../../assets/images/Home/Benefit/gain_insights.svg';
import Libraries from '../../assets/images/Home/libraries.svg';
import MyLibrary from '../../assets/images/Home/Libraries/books.svg';
import ClientLibrary from '../../assets/images/Home/Libraries/user-gear.svg';
import TeamsLibrary from '../../assets/images/Home/Libraries/users-medical.svg';
import { IPSERAHCOUNT, UPGRADEPLAN, USERSEPURCHASEPLAN, USERSERACHCOUNT } from "../../redux/actions";
import { useAccount } from "../../component/context/AccountProvider";

function Home() {
  const { userDetails } = useAccount()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { searchType, searchCount, searchValue, searchSuggestionValue } = useSelector((state) => state.search);
  const { ipSearchCountPopup, freePlanSeachCountPopup, purchasePlanToAccess, upgradePlan } = useSelector((state) => state.common);
  const [moreFilterShow, setMoreFilterShow] = useState(false);
  const [activeTab, setActiveTab] = useState(JSON.parse(localStorage.getItem('activeTab')));
  let searchData = useSelector((state) => state.search);
  console.log("userData", ipSearchCountPopup, freePlanSeachCountPopup)

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(setSelectedDate(''))
    if (!localStorage.getItem('activeTab')) {
      localStorage.setItem("activeTab", JSON.stringify({ contractType: true, clause: false, definition: false }))
      setActiveTab({ contractType: true, clause: false, definition: false })
    }
  })

  useEffect(() => {
    let showmore = searchData?.selectedCompany?.length || searchData?.selectedDepartment?.length || searchData?.selectedIndustry?.length || searchData?.selectedGoverningLaw?.length
    if (showmore) {
      setMoreFilterShow(true)
    }
  }, [searchData])


  const handleCheck = (value) => {
    if (value === 'concept_search') {
      if (localStorage.getItem('token') !== null) {
        if (userDetails.user_subscription.length) {
          dispatch(setSearchType(value));
          if (searchValue.length) { dispatch(genericFilterHandler(userDetails.id || '', value, activeTab, searchValue)) }
          if (searchSuggestionValue.length) { dispatch(directSearchFilter(userDetails.id || '', value, activeTab, searchSuggestionValue)); }
        }
        else if (userDetails.user_subscription.length === 0) {
          dispatch({ type: USERSEPURCHASEPLAN, payload: true });
        }
      }
      else {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
    }
    else {
      if (searchValue.length) { dispatch(genericFilterHandler(userDetails.id || '', value, activeTab, searchValue)) }
      if (searchSuggestionValue.length) { dispatch(directSearchFilter(userDetails.id || '', value, activeTab, searchSuggestionValue)); }
      dispatch(setSearchType(value));
    }
  };



  // const handleNavigation = (path) => {
  //   debugger
  //   if (localStorage.getItem('token') !== null) {
  //     if (userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
  //       navigate(path)
  //     }
  //     if (userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "STANDARD")) {
  //       if (path === '/Library/my_library') {
  //         navigate(path)
  //       }
  //     }
  //     if (
  //       userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "GROWTH") ||
  //       userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "PREMIUM")
  //     ) {
  //       if (path === '/Library/my_library' || path === '/Library/my_library') {
  //         navigate(path)
  //       }
  //     }
  //     else {
  //       dispatch({ type: UPGRADEPLAN, payload: true });
  //     }
  //   }
  //   else {
  //     dispatch({ type: IPSERAHCOUNT, payload: true });
  //   }
  // }



  const [searchQuery, setSearchQuery] = useState('')

  const [pages, setPages] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getAllPageHanlder = async () => {
    const res = await getAllPage();
    setPages(res.data.data || []);
  };

  useEffect(() => {
    dispatch(setSearchItem([]))
    getAllPageHanlder();
    getAllBlogHandler();
  }, []);

  const getAllBlogHandler = async () => {
    const res = await getAllBlog();
    setBlogs(res.data.data || []);
  };


  console.log("searchQuery", searchQuery)


  const handleClose = () => {
    dispatch({ type: IPSERAHCOUNT, payload: false });
    dispatch({ type: USERSERACHCOUNT, payload: false });
    dispatch({ type: USERSEPURCHASEPLAN, payload: false });
    dispatch({ type: UPGRADEPLAN, payload: false });
  }

  const handleNavigation = (path) => {
    debugger
    if (localStorage.getItem('token') !== null) {
      debugger
      if (path === '/Library/my_library' && userDetails.user_subscription.length === 0) {
        navigate(path)
      }
      else if (userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
        navigate(path)
      }
      else if (userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "STANDARD")) {
        if (path === '/Library/my_library') { navigate(path) }
      }
      else if (userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "GROWTH") || userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "PREMIUM")) {
        if (path === '/Library/my_library' || path === '/Library/my_library') { navigate(path) }
      }
      else if (userDetails.user_subscription.length === 0) {
        dispatch({ type: USERSEPURCHASEPLAN, payload: true });
      }
      else { dispatch({ type: UPGRADEPLAN, payload: true }); }
    }
    else {
      dispatch({ type: IPSERAHCOUNT, payload: true });
    }
  }


  const handleTabChangeSearch = (value) => {
    if ((localStorage.getItem('token') === null && searchCount <= 4) ||
      (localStorage.getItem('token') !== null && searchCount <= 9)
      || (localStorage.getItem('token') !== null && userDetails.user_subscription.length)
    ) {
      dispatch(genericFilterHandler(userDetails.id || '', searchType, value, searchValue, 'search'));
      dispatch(directSearchFilter(userDetails.id || '', searchType, value, searchSuggestionValue));
    }
    else {
      if (localStorage.getItem('token') === null && searchCount >= 4) {
        dispatch({ type: IPSERAHCOUNT, payload: true });
      }
      if (localStorage.getItem('token') !== null) {
        if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      }
      // if (localStorage.getItem('token') !== null && userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   if (searchCount >= 9) { dispatch({ type: USERSERACHCOUNT, payload: true }); }
      // }
      // if (localStorage.getItem('token') !== null && !userDetails.user_subscription.some(item => item.data.subscription.plan.plan_code === "FREE")) {
      //   dispatch({ type: USERSEPURCHASEPLAN, payload: true });
      // }
    }
  }



  return (
    <>
      <div style={{ background: "#fff" }}>
        <div className="sub_header ">
          <div className="home-container">
            <ul>
              <li> <a onClick={() => handleNavigation(`/Library/my_library`)}> My Library </a> </li>
              <li> <a onClick={() => handleNavigation(`/Library/client_library`)}> Client Library </a> </li>
              <li> <a onClick={() => handleNavigation(`/Library/team_library`)}> Teams Library </a> </li>
            </ul>
          </div>
        </div>



        <div className="home-container">
          <div className="home_banner">

            <div className="home_banner_left">
              <div className="home_banner_left_wrp">
                <h1> One search Infinite Insights </h1>

                {console.log("searchValue.length", searchValue.length)}
                <div className="home_banner_search">
                  <Tabs sx={{ width: '100%', height: "50px" }} aria-label="wrapped label tabs example" >
                    <Tab
                      value="contractType"
                      label="Contract Type"
                      sx={{ color: "white" }}
                      className={`${activeTab?.contractType ? "active" : ""} home_banner_left_wrp_tab`}
                      onClick={() => {
                        setActiveTab({ contractType: true, clause: false, definition: false });
                        localStorage.setItem("activeTab", JSON.stringify({ contractType: true, clause: false, definition: false }))
                        if (searchValue.length) {
                          handleTabChangeSearch({ contractType: true, clause: false, definition: false })
                        }
                      }}
                    />
                    <Tab
                      value="clause"
                      label="Clause"
                      sx={{ color: "white" }}
                      className={`${activeTab?.clause ? "active" : ""} home_banner_left_wrp_tab`}
                      onClick={() => {
                        setActiveTab({ contractType: false, clause: true, definition: false });
                        localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: true, definition: false }))
                        if (searchValue.length) {
                          handleTabChangeSearch({ contractType: false, clause: true, definition: false })
                        }
                      }}
                    />
                    <Tab
                      value="definition"
                      label="Definition"
                      sx={{ color: "white" }}
                      className={`${activeTab?.definition ? "active" : ""} home_banner_left_wrp_tab`}
                      onClick={() => {
                        setActiveTab({ contractType: false, clause: false, definition: true, });
                        localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: false, definition: true }))
                        if (searchValue.length) {
                          handleTabChangeSearch({ contractType: false, clause: false, definition: true })
                        }
                      }}
                    />
                  </Tabs>

                  <HomeSearch
                    searchType={searchType}
                    activeTab={activeTab}
                    getSearchQuery={setSearchQuery}
                  />

                  <div className="home_banner_search_radio ">
                    <div className="home_banner_search_radio_left">
                      <div class="custom-radio-button">
                        <div>
                          <input type="radio" id="textSearch" name="color" value="text_search" checked={searchType === 'text_search'} />
                          <label for="textSearch" onClick={() => handleCheck('text_search')}> <span> Text Search </span> </label>
                        </div>
                        <div>
                          <input type="radio" id="concepetSearch" name="color" value="concept_search" checked={searchType === 'concept_search'} />
                          <label for="concepetSearch" onClick={() => handleCheck('concept_search')}> <span> Concept Search </span> </label> </div>
                      </div>
                    </div>

                    <span className="more-filter" >
                      <div class="form-group">
                        <input type="checkbox" id="html" onChange={() => setMoreFilterShow(!moreFilterShow)} />
                        <label htmlFor="html"> More Filter </label>
                      </div>
                      {/* 
                      {moreFilterShow ? "Less Filter" : "More Filter"}
                      {moreFilterShow ? (<MdKeyboardArrowUp />) : (<MdOutlineExpandMore />)} */}
                    </span>
                  </div>
                </div>

                <div className="home_banner_search_morefilter">
                  {moreFilterShow && <MoreFilters activeTab={activeTab} searchQuery={searchQuery} />}
                </div>

                {/* <Stack direction={'row'} gap={3} flexWrap={"wrap"} className="home_banner_search_footer">
                  <Stack direction={'row'} gap={3} flexWrap={"wrap"}>
                    <div className="home_banner_search_footer_left">
                      <h4> Built for your productivity </h4>
                      <p> Download Kronicle Word Add-in Tool </p>
                    </div>
                    <a className="home_banner_search_footer_right" href="ms-word:https://api.addins.store.office.com/addinstemplate/en-US/b6f49734-5104-41d6-866e-cbebdfa528f1/WA200003817/none/Kronicle.docx?omexsrctype=1">
                      <span> <img src={word} alt="word" /> Download Now <FaChevronRight /> </span>
                    </a>
                  </Stack>
                </Stack> */}
              </div>
            </div>
          </div>

        </div>


        {/* download addin */}
        <div className="home-container home_addin">
          <div className="home_addin_left">
            <img src={Addin} alt="Download Addin" />
          </div>
          <div className="home_addin_right">
            <h2> Max your productivity with our Add-in’s </h2>
            <p> Download Kronicle Word Add-in from Microsoft store </p>
            <a className="home_banner_search_footer_right" href="ms-word:https://api.addins.store.office.com/addinstemplate/en-US/b6f49734-5104-41d6-866e-cbebdfa528f1/WA200003817/none/Kronicle.docx?omexsrctype=1">
              <span> <img src={word} alt="word" /> Download Now <FaChevronRight /> </span>
            </a>
          </div>
        </div>


        {/* Draft with Precision using Dealbase */}

        <div className="home-container home_draft">
          <div className="home_draft_left">
            <h2> Draft with Precision using Dealbase </h2>
            <p> Save time and refine your contracts with Kronicle Dealbase </p>

            <div className="home_draft_left_content">
              <label> Subscribe to unlock the premium features </label>
              <ul>
                <li>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33818 0.499569C9.49734 0.665403 9.49318 0.92957 9.32651 1.08874L5.37735 4.8954C4.98985 5.28374 4.46651 5.5004 3.90985 5.5004C3.35318 5.5004 2.82985 5.28374 2.43651 4.8904L0.549013 3.13707C0.38068 2.9804 0.37068 2.71707 0.527346 2.5479C0.684846 2.37874 0.94818 2.37124 1.11651 2.52624L3.01485 4.2904C3.50901 4.78374 4.32151 4.7729 4.79318 4.30124L8.74818 0.48957C8.91318 0.330403 9.17735 0.33457 9.33735 0.500403L9.33818 0.499569Z" fill="#48B681" />
                  </svg>
                  <span> Access to over 2 million contracts  </span>
                </li>
                <li>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33818 0.499569C9.49734 0.665403 9.49318 0.92957 9.32651 1.08874L5.37735 4.8954C4.98985 5.28374 4.46651 5.5004 3.90985 5.5004C3.35318 5.5004 2.82985 5.28374 2.43651 4.8904L0.549013 3.13707C0.38068 2.9804 0.37068 2.71707 0.527346 2.5479C0.684846 2.37874 0.94818 2.37124 1.11651 2.52624L3.01485 4.2904C3.50901 4.78374 4.32151 4.7729 4.79318 4.30124L8.74818 0.48957C8.91318 0.330403 9.17735 0.33457 9.33735 0.500403L9.33818 0.499569Z" fill="#48B681" />
                  </svg>
                  <span> Access to over 40 million clauses and definitions  </span>
                </li>
                <li>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33818 0.499569C9.49734 0.665403 9.49318 0.92957 9.32651 1.08874L5.37735 4.8954C4.98985 5.28374 4.46651 5.5004 3.90985 5.5004C3.35318 5.5004 2.82985 5.28374 2.43651 4.8904L0.549013 3.13707C0.38068 2.9804 0.37068 2.71707 0.527346 2.5479C0.684846 2.37874 0.94818 2.37124 1.11651 2.52624L3.01485 4.2904C3.50901 4.78374 4.32151 4.7729 4.79318 4.30124L8.74818 0.48957C8.91318 0.330403 9.17735 0.33457 9.33735 0.500403L9.33818 0.499569Z" fill="#48B681" />
                  </svg>
                  <span> AI Powered Search </span>
                </li>
                <li>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33818 0.499569C9.49734 0.665403 9.49318 0.92957 9.32651 1.08874L5.37735 4.8954C4.98985 5.28374 4.46651 5.5004 3.90985 5.5004C3.35318 5.5004 2.82985 5.28374 2.43651 4.8904L0.549013 3.13707C0.38068 2.9804 0.37068 2.71707 0.527346 2.5479C0.684846 2.37874 0.94818 2.37124 1.11651 2.52624L3.01485 4.2904C3.50901 4.78374 4.32151 4.7729 4.79318 4.30124L8.74818 0.48957C8.91318 0.330403 9.17735 0.33457 9.33735 0.500403L9.33818 0.499569Z" fill="#48B681" />
                  </svg>
                  <span> 24/7 Support  </span>
                </li>
                <li>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33818 0.499569C9.49734 0.665403 9.49318 0.92957 9.32651 1.08874L5.37735 4.8954C4.98985 5.28374 4.46651 5.5004 3.90985 5.5004C3.35318 5.5004 2.82985 5.28374 2.43651 4.8904L0.549013 3.13707C0.38068 2.9804 0.37068 2.71707 0.527346 2.5479C0.684846 2.37874 0.94818 2.37124 1.11651 2.52624L3.01485 4.2904C3.50901 4.78374 4.32151 4.7729 4.79318 4.30124L8.74818 0.48957C8.91318 0.330403 9.17735 0.33457 9.33735 0.500403L9.33818 0.499569Z" fill="#48B681" />
                  </svg>
                  <span> Save in your libraries  </span>
                </li>

                <li>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33818 0.499569C9.49734 0.665403 9.49318 0.92957 9.32651 1.08874L5.37735 4.8954C4.98985 5.28374 4.46651 5.5004 3.90985 5.5004C3.35318 5.5004 2.82985 5.28374 2.43651 4.8904L0.549013 3.13707C0.38068 2.9804 0.37068 2.71707 0.527346 2.5479C0.684846 2.37874 0.94818 2.37124 1.11651 2.52624L3.01485 4.2904C3.50901 4.78374 4.32151 4.7729 4.79318 4.30124L8.74818 0.48957C8.91318 0.330403 9.17735 0.33457 9.33735 0.500403L9.33818 0.499569Z" fill="#48B681" />
                  </svg>
                  <span> Add-Free browsing  </span>
                </li >
                <li>
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.33818 0.499569C9.49734 0.665403 9.49318 0.92957 9.32651 1.08874L5.37735 4.8954C4.98985 5.28374 4.46651 5.5004 3.90985 5.5004C3.35318 5.5004 2.82985 5.28374 2.43651 4.8904L0.549013 3.13707C0.38068 2.9804 0.37068 2.71707 0.527346 2.5479C0.684846 2.37874 0.94818 2.37124 1.11651 2.52624L3.01485 4.2904C3.50901 4.78374 4.32151 4.7729 4.79318 4.30124L8.74818 0.48957C8.91318 0.330403 9.17735 0.33457 9.33735 0.500403L9.33818 0.499569Z" fill="#48B681" />
                  </svg>
                  <span> Uninterrupted access  </span>
                </li >
              </ul >
              <div className="home_draft_left_content_btn">
                <button className="bg_btn">
                  <Link
                    href={`https://lexops.co/plans`}
                    target={'_blank'}
                  >
                    Buy Now
                  </Link>
                </button>
                <button className="normal_btn" onClick={() => navigate('/contactus')}>

                  Request Demo
                </button>

              </div>
            </div>


          </div >
          <div className="home_draft_right">
            <img src={Draftwith} alt="Download Addin" />
          </div>
        </div >



        {/* See what’s popular? */}
        < div className="home-container home_popular" >
          <div className="section_title">
            <h2> See what’s popular? </h2>
          </div>
          <Stack direction={"row"} flexWrap={"wrap"} gap={3} className="home_popular_wrp">
            <div className="home_popular_wrp_item">
              <h4>  <img src={Contracts} alt="Contracts" />    Contracts </h4>
              <ul>
                <li> <a> Employment Agreement  </a></li>
                <li> <a> Lease Agreement  </a></li>
                <li> <a> Service Agreement  </a></li>
                <li> <a> Loan Agreement  </a></li>
                <li> <a> License Agreement  </a></li>
              </ul>
            </div>

            <div className="home_popular_wrp_item">
              <h4>  <img src={Clasuses} alt="Clauses" /> Clauses </h4>
              <ul>
                <li> <a> Confidentiality </a></li>
                <li> <a> Indemnity </a></li>
                <li> <a> Force Majeure </a></li>
                <li> <a> Non Compete </a></li>
                <li> <a> Non Solicitation </a></li>
              </ul>
            </div>

            <div className="home_popular_wrp_item">
              <h4> <img src={Definitions} alt="Definitions" />  Definitions </h4>
              <ul>
                <li> <a> Tax Identification Number  </a></li>
                <li> <a> Per Annum  </a></li>
                <li> <a> Confidential Information  </a></li>
                <li> <a> Business Day  </a></li>
                <li> <a> Registered Office  </a></li>
              </ul>
            </div>

          </Stack>
        </div >


        {/* Features */}
        < div className="home-container home_features" >
          <div className="section_title">
            <h2> Features </h2>
          </div>
          <div className="home_features_wrp">
            <div className="home_features_wrp_item">
              <img src={ContactQurey} alt="ContractQuery" />
              <div className="home_features_wrp_item_content">
                <h3> Contract Query </h3>
                <p> Over 2 million executed contracts in a searchable format with over 15 filters across deal points </p>
              </div>
            </div>

            <div className="home_features_wrp_item">
              <img src={ClausesQurey} alt="ClauseQuery" />
              <div className="home_features_wrp_item_content">
                <h3> Clause Query </h3>
                <p> Query from over /40 million unique clauses/ definitions. Kronicle indexes all the clauses in contracts as per SALI clause identifiers </p>
              </div>
            </div>

            <div className="home_features_wrp_item">
              <img src={ContractDiscovery} alt="ContractDiscovery" />
              <div className="home_features_wrp_item_content">
                <h3> Contract Discovery </h3>
                <p> Identify contracts based on how they are organized, what clauses they contain, the standard language for their clauses, and the full range of deal-specific variations. </p>
              </div>
            </div>

            <div className="home_features_wrp_item">
              <img src={DealAnalytics} alt="DealAnalytics" />
              <div className="home_features_wrp_item_content">
                <h3> Deal Analytics </h3>
                <p> Get extensive insights through specialised contract review of executed contracts and comprehensive deal data. </p>
              </div>
            </div>
          </div>
        </div >



        {/* Benefit */}
        < div className="home-container home_benefit" >
          <div className="section_title">
            <h2> How you can benefit? </h2>
          </div>
          <div className="home_benefit_wrp">
            <Stack className="home_benefit_wrp_item">
              <div className="home_benefit_wrp_item_img">
                <div className="home_benefit_wrp_item_img_wrp">
                  <img src={SaveTime} alt="save time " />
                </div>
                <h3> Save Time </h3>
              </div>
              <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
            </Stack>

            <Stack className="home_benefit_wrp_item">
              <div className="home_benefit_wrp_item_img">
                <div className="home_benefit_wrp_item_img_wrp">
                  <img src={MinimiseRisk} alt="MinimiseRisk " />
                </div>
                <h3> Minimise Risk </h3>
              </div>
              <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
            </Stack>

            <Stack className="home_benefit_wrp_item">
              <div className="home_benefit_wrp_item_img">
                <div className="home_benefit_wrp_item_img_wrp">
                  <img src={GainInsights} alt="GainInsights" />
                </div>
                <h3> Gain Insights </h3>
              </div>
              <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
            </Stack>

            <Stack className="home_benefit_wrp_item">
              <div className="home_benefit_wrp_item_img">
                <div className="home_benefit_wrp_item_img_wrp">
                  <img src={MasterNegotiations} alt="MasterNegotiations " />
                </div>
                <h3> Master Negotiations </h3>
              </div>
              <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
            </Stack>
          </div>
        </div >




        {/* Libraries */}
        < div className="home-container home_libraries" >
          <div className="home_libraries_left">
            <img src={Libraries} alt="Libraries" />
          </div>
          <div className="home_libraries_right">
            <div className="section_title">
              <h2> Your Libraries </h2>
            </div>
            <div className="home_libraries_right_content">
              <div className="home_libraries_right_content_item">
                <img src={MyLibrary} alt="My Library" />
                <h4> My Library</h4>
              </div>
              <div className="home_libraries_right_content_item">
                <img src={ClientLibrary} alt="Client Library" />
                <h4> Client Library</h4>
              </div>
              <div className="home_libraries_right_content_item">
                <img src={TeamsLibrary} alt="Teams Library" />
                <h4> Teams Library</h4>
              </div>
            </div>
          </div>
        </div>


        {/* our tools */}
        < div className="home_ourtools" >
          <div className="home-container ">
            <div className="section_title">
              <h2> Speed contract drafting & review with our tools </h2>
            </div>
            <Slider {...settings}>
              <div className="home_ourtools_item">
                <div className="home_ourtools_item_wrp">
                  <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                    <div className="home_ourtools_item_top_image">
                      <img src="/images/product_img/KronicleAutomate.svg" alt="KronicleAutomate" />
                    </div>
                    <div className="home_ourtools_item_top_title">
                      Automate
                    </div>
                  </Stack>
                  <div className="home_ourtools_item_content">
                    <p>
                      Templatise contracts and automate your workflows
                    </p>
                    <a> Know More <FaChevronRight /> </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="home_ourtools_item">
                  <div className="home_ourtools_item_wrp">
                    <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                      <div className="home_ourtools_item_top_image">
                        <img src="/images/product_img/KronicleReview.svg" alt="KronicleReview" />
                      </div>
                      <div className="home_ourtools_item_top_title">
                        Review
                      </div>
                    </Stack>
                    <div className="home_ourtools_item_content">
                      <p>
                        Review inconsistent terms, definitions and potential errors
                      </p>
                      <a> Know More <FaChevronRight /> </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="home_ourtools_item">
                  <div className="home_ourtools_item_wrp">
                    <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                      <div className="home_ourtools_item_top_image">
                        <img src="/images/product_img/KronicleDraft.svg" alt="KronicleDraft" />
                      </div>
                      <div className="home_ourtools_item_top_title">
                        Draft
                      </div>
                    </Stack>
                    <div className="home_ourtools_item_content">
                      <p>
                        Create your contract
                      </p>
                      <a> Know More <FaChevronRight /> </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="home_ourtools_item">
                  <div className="home_ourtools_item_wrp">
                    <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                      <div className="home_ourtools_item_top_image">
                        <img src="/images/product_img/MyLibrary.svg" alt="MyLibrary" />
                      </div>
                      <div className="home_ourtools_item_top_title">
                        Kronicle Automate
                      </div>
                    </Stack>
                    <div className="home_ourtools_item_content">
                      <p>
                        Enjoy fast, compliant document creation, e-signatures, and with electronic proof of delivery records with real-time tracking on any device.
                      </p>
                      <a> Know More <FaChevronRight /> </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div >


        {/* our tools */}
        < div className="home-container home_blog " >
          <div className="section_title">
            <h2> Our Blogs </h2>
          </div>
          <div className="home_blog_wrp">
            {blogs.filter((_) => _.is_latest_blog === true)
              .map((item, i) => {
                return (
                  <>
                    <div className="home_blog_item">
                      <a href={`/blog/${item.seoURL}`} target="_blank" rel="noreferrer" >
                        <div className="home_blog_item_img">
                          <img src={item.image} alt="blog_img" />
                        </div>
                        <div className="home_blog_item_content">
                          <h4> {item.title} </h4>
                          <p dangerouslySetInnerHTML={{ __html: item.pageContent }} ></p>
                        </div>
                      </a>
                    </div>
                  </>
                );
              })}
          </div>

        </div >

      </div >

      {/* footer */}
      < Footer pages={pages} />

    </>
  );
}

export default Home;
