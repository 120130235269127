import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_CONTRACT_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
});


instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (token) {
            // Set the token in the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



//create bookmark list 
export const createBookmark = async (payload) => {
    try {
        const { data } = await instance.post(`bookmarks`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


//create bookmark list 
export const upddateBookmark = async (payload, id) => {
    try {
        const { data } = await instance.put(`bookmarks/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

//delete bookmark list 
export const deleteBookmark = async (id) => {
    try {
        const { data } = await instance.delete(`bookmarks/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};



//get bookmark list 
export const getAllbookmarkList = async () => {
    console.log("REACT_CONTRACT_API_URL", process.env.REACT_APP_CONTRACT_API_URL);

    try {
        const { data } = await instance.get(`bookmarks`);
        return data;
    } catch (err) {
        return err;
    }
};


//get all custom bookmark list 
export const getAllCustomBMList = async () => {
    try {
        const { data } = await instance.get(`library`);
        return data;
    } catch (err) {
        return err;
    }
};

//get library list 

export const getLibraryList = async (payload, size, offset, sort) => {
    try {
        const res = await instance.post(`/es/bookmarks?limit=${size}&offset=${offset}&sort=${sort}`, payload);
        return res;
    } catch (error) {
        return error;
    }
};


// remove bookmark id wise
export const removeBookmark = async (id) => {
    try {
        const { data } = await instance.delete(`bookmarks/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};



// remove group library
export const removeGPBookmark = async (bk_type, bk_name) => {
    try {
        const res = await instance.delete(`bookmarks?btype=${bk_type}&bname=${bk_name}`);
        return res;
    } catch (error) {
        return error;
    }
};


