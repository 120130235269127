import { Heading, Image } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogByUrl } from "../../service/CMSService";
import moment from "moment";
import SEO from "../../component/SEO";
import Footer from "../../component/Footer";

const BlogRenderPage = () => {
  const navigate = useNavigate();
  const { url } = useParams();
  const [pageContentData, setPageContentData] = useState({});
  const [SeoShow, setSeoShow] = useState(false)
  const getPageData = useCallback(async () => {
    const res = await getBlogByUrl(url);
    if (res) {
      setPageContentData(res.data.data || []);
      setSeoShow(true)
    }
  }, [url]);
  useEffect(() => {
    if (url) {
      getPageData();
    }
  }, [url, getPageData]);

  return (
    <>
      {console.log("pageContentData", pageContentData)}
      {SeoShow ?
        <SEO
          title={pageContentData?.title || ''}
          description={pageContentData?.metaDiscription || ''}
          // url={`/blog/${pageContentData?.seoURL}`}
          url={window.location.href}
          ogTag={pageContentData?.ogTag || ''}
          metaKeywords={pageContentData.metaKeywords || ''}
        />
        : ""
      }
      <div className=" blog-page">
        <div className="container">
          <div py={14} className="blog_details">
            <div className="blog_details_left ">
              {pageContentData?.blogContent?.image !== "NA" &&
                pageContentData?.blogContent?.image !== "" ? (
                <Image
                  src="gibbresh.png"
                  fallbackSrc={pageContentData?.blogContent?.image}
                />
              ) : (
                <Image
                  src="gibbresh.png"
                  fallbackSrc={pageContentData?.image}
                />
              )}
              <div className="blog_info">
                <div>
                  {moment(pageContentData?.createdAt).format("DD MMM YYYY")}
                </div>
                <div>{pageContentData?.subTitle}</div>
              </div>
              <Heading
                className="blog-title"
                fontSize="2rem !important"
                mt={3}
                mb={5}
              >
                {pageContentData?.title}
              </Heading>
              {pageContentData?.pageContent ?
                <div style={{ marginBottom: "20px" }}
                  dangerouslySetInnerHTML={{
                    __html: pageContentData?.pageContent,
                  }}
                />
                :
                ""
              }
              {pageContentData?.blogContent?.blogContent && (
                <>
                  {/* <Heading mt={5} mb={3} fontSize="large">
                    Blog Content
                  </Heading> */}
                  <div className="blog_content sun-editor-editable" dangerouslySetInnerHTML={{ __html: pageContentData?.blogContent?.blogContent, }} />
                </>
              )}
            </div>
            <div className="blog_details_right">
              {pageContentData?.related_blogs?.length > 0 && (
                <div className="popular_post">
                  <h4>Related Blogs</h4>
                  {pageContentData?.related_blogs.map((item, i) => (
                    <div
                      key={i}
                      style={{ cursor: "pointer" }}
                      className="popular_post_item"
                    >
                      <div onClick={() => navigate(`/blog/${item.seoURL}`)}>
                        <h6>{item.title} </h6>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogRenderPage;
