import React from "react";

const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
    >
      <path
        d="M23.2406 8.35066C23.4169 8.79124 23.4414 9.26643 23.3059 9.71196C22.9779 10.7902 22.1897 12.7391 20.4729 14.414C18.7686 16.0768 16.1191 17.5 12 17.5C7.88091 17.5 5.23142 16.0768 3.52709 14.414C1.81034 12.7391 1.0221 10.7902 0.694144 9.71196C0.558632 9.26643 0.583125 8.79124 0.759361 8.35066C1.63147 6.17042 4.60747 0.5 12 0.5C19.3925 0.5 22.3685 6.17042 23.2406 8.35066Z"
        stroke="#404040"
      />
      <circle cx="12" cy="9" r="4.5" stroke="#404040" />
    </svg>
  );
};

export default EyeIcon;
