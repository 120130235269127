import React from "react";

const ZoomOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="1"
      viewBox="0 0 12 1"
      fill="none"
    >
      <path
        d="M11.6667 0.333007L0.333333 0.333008C0.149333 0.333008 1.30551e-08 0.482341 2.91409e-08 0.666341C4.52267e-08 0.850341 0.149333 0.999674 0.333333 0.999674L11.6667 0.999673C11.8507 0.999673 12 0.85034 12 0.66634C12 0.48234 11.8507 0.333007 11.6667 0.333007Z"
        fill="#606060"
      />
    </svg>
  );
};

export default ZoomOutIcon;
