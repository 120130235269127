import React from "react";

const GoNextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_138_12452)">
        <path
          d="M12 0C5.383 0 0 5.383 0 12C0 18.617 5.383 24 12 24C18.617 24 24 18.617 24 12C24 5.383 18.617 0 12 0ZM12 23C5.935 23 1 18.065 1 12C1 5.935 5.935 1 12 1C18.065 1 23 5.935 23 12C23 18.065 18.065 23 12 23Z"
          fill="#216DDE"
        />
        <path
          d="M16.613 9.52767L13.785 6.64867C13.591 6.45167 13.275 6.44867 13.078 6.64267C12.881 6.83667 12.878 7.15267 13.072 7.35067L15.903 10.2327C16.09 10.4197 16.351 10.7057 16.618 11.0067C16.749 11.1547 16.842 11.3227 16.904 11.4997H6.5C6.224 11.4997 6 11.7237 6 11.9997C6 12.2757 6.224 12.4997 6.5 12.4997H16.904C16.841 12.6767 16.749 12.8447 16.617 12.9927C16.351 13.2937 16.09 13.5797 15.9 13.7697L13.072 16.6487C12.878 16.8457 12.881 17.1627 13.078 17.3567C13.176 17.4517 13.302 17.4997 13.429 17.4997C13.558 17.4997 13.688 17.4497 13.785 17.3507L16.61 14.4747C16.808 14.2767 17.085 13.9747 17.366 13.6567C18.203 12.7127 18.203 11.2887 17.366 10.3447C17.084 10.0267 16.807 9.72367 16.613 9.52967V9.52767Z"
          fill="#216DDE"
        />
      </g>
      <defs>
        <clipPath id="clip0_138_12452">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoNextIcon;
