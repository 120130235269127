import React from "react";

const GoPrevIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="14"
      viewBox="0 0 6 14"
      fill="none"
    >
      <path
        d="M5.50047 13.9996C5.62347 13.9996 5.74547 13.9546 5.84147 13.8646C6.04347 13.6766 6.05347 13.3596 5.86547 13.1576L1.72347 8.72763C1.25247 8.25663 1.00047 7.64763 1.00047 6.99863C1.00047 6.34963 1.25247 5.74063 1.71147 5.28163L5.86547 0.84063C6.05347 0.63863 6.04347 0.32163 5.84147 0.13363C5.64047 -0.0533701 5.32347 -0.0453698 5.13447 0.15663L0.992472 4.58663C0.356472 5.22263 -0.000526905 6.08263 -0.000526905 6.99863C-0.000526905 7.91463 0.356473 8.77463 1.00347 9.42263L5.13347 13.8406C5.23247 13.9456 5.36547 13.9986 5.49847 13.9986L5.50047 13.9996Z"
        fill="#303030"
      />
    </svg>
  );
};

export default GoPrevIcon;
