/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { setSelectedFilters } from "../../../redux/librarySlice";
import FilterIcon from "../../../assets/images/SearchIcon/FilterIcon";
import DropdownIcon from "../../../assets/images/SearchIcon/DropdownIcon";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function LibraryMiuiAsyncSelect({
  multiple,
  readOnly,
  ddname,
  placeholder,
  loadOptions,
  activeTab,
  query,
  setSelected,
  genericFilterHandler,
  value,
  isDropdownIcon,
}) {
  const { groupIDs } = useSelector((state) => state.library);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  // const loading = open && loadOptions?.length === 0;
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  const handleToggleOption = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleClearOptions = () => {
    setSelectedOptions([]);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "clear") {
      handleClearOptions();
      dispatch(setSelected([]));
      dispatch(
        setSelectedFilters({
          data: ddname,
          value: selectedOptions ? selectedOptions : [],
        })
      );
      if (groupIDs?.length > 0) {
        dispatch(genericFilterHandler("contract_data", groupIDs));
      } else {
        dispatch(genericFilterHandler(""));
      }
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions && Array.isArray(selectedOptions)) {
        handleToggleOption(selectedOptions);
        dispatch(setSelected(selectedOptions));
        dispatch(setSelectedFilters({ data: ddname, value: selectedOptions }));
        if (groupIDs?.length > 0) {
          dispatch(genericFilterHandler("contract_data", groupIDs));
        } else {
          dispatch(genericFilterHandler(""));
        }
      } else {
        handleToggleOption(selectedOptions);
        dispatch(
          setSelectedFilters({ data: ddname, value: [selectedOptions] })
        );
        if (groupIDs?.length > 0) {
          dispatch(genericFilterHandler("contract_data", groupIDs));
        } else {
          dispatch(genericFilterHandler(""));
        }
      }
    }
  };

  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        siz="small"
        readOnly={readOnly || false}
        multiple={multiple || false}
        limitTags={0}
        value={selectedOptions}
        open={open}
        disableCloseOnSelect
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        options={loadOptions?.data}
        onChange={handleChange}
        renderOption={(props, option, { selected }) => (
          <li {...props} className="customlist">
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <p>{option.label}</p>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{ fontSize: "12px" }}
            placeholder={placeholder}
            className="main_div"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/* {params.InputProps.endAdornment} */}
                  <FilterIcon />
                </React.Fragment>
              ),
            }}
          />
        )}
        className="filter-dropdown-list-lib"
      />
    </>
  );
}

export default LibraryMiuiAsyncSelect;
