import React from "react";

const DropdownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 15C11.084 15 10.224 14.644 9.57603 13.996L5.15803 9.86503C4.95703 9.67703 4.94603 9.36003 5.13503 9.15803C5.32303 8.95703 5.64003 8.94603 5.84203 9.13503L10.272 13.278C11.213 14.217 12.799 14.206 13.717 13.29L18.158 9.13603C18.36 8.94803 18.677 8.95803 18.865 9.15903C19.053 9.36103 19.043 9.67803 18.842 9.86603L14.412 14.009C13.776 14.645 12.916 15.001 12 15.001V15Z"
        fill="#606060"
      />
    </svg>
  );
};

export default DropdownIcon;
