import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import DropdownIcon from "../../assets/images/SearchIcon/DropdownIcon";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "350px",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#005287",
  backgroundColor: "transparent",
  borderColor: "#005287",
}));

const allFiltersList = [
  { id: 1, title: "Select All", checked: false },
  { id: 2, title: "CIK", checked: false },
  { id: 3, title: "Accession Number", checked: false },
  { id: 4, title: "Entity Type", checked: false },
  { id: 5, title: "Form", checked: false },
  { id: 6, title: "Stock Exchange", checked: false },
  { id: 7, title: "Exhibit ID", checked: false },
  { id: 8, title: "State of Incorporation", checked: false },
  { id: 9, title: "Date Range", checked: false },
];

export default function MoreFiltersModalLibrary({
  onClick,
  title,
  setAllFilters,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterList, setFilterList] = React.useState(allFiltersList);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClick();
  };

  const handleFilterClick = (id) => {
    let updatedFilters = filterList.map((filter) => {
      if (id === 1) {
        // Toggle Select All
        return { ...filter, checked: !filterList[0].checked };
      } else if (filter.id === id) {
        // Toggle individual checkbox
        return { ...filter, checked: !filter.checked };
      }
      return filter;
    });

    if (id !== 1) {
      // Check if all checkboxes are checked except Select All
      const allCheckedExceptSelectAll = updatedFilters.slice(1).every(filter => filter.checked);
      updatedFilters[0].checked = allCheckedExceptSelectAll;
    }

    setFilterList(updatedFilters);
    const allFiltersObject = () => {
      return updatedFilters.reduce((obj, filter) => {
        obj[filter.id] = { ...filter }; // Copy each filter item into the object with id as key
        return obj;
      }, {});
    };
    setAllFilters(allFiltersObject);
  };

  return (
    <div>
      <ColorButton
        size="large"
        variant="outlined"
        endIcon={<DropdownIcon />}
        onClick={handleClick}
        className="more-filter-btn"
      >
        {title}
      </ColorButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="more-filter-list-container"
      >
        <ul
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          className="more-filter-list"
        >
          {filterList.map((value) => {
            return (
              <li
                key={value.id}
                disablePadding
                sx={{ width: "50%" }}
                onClick={() => handleFilterClick(value.id)}
                className="customcheck"
              >
                <input type="checkbox"
                  id={value.id}
                  checked={value.checked}
                />
                <label htmlFor={value.title} style={{ width: '100%', justifyContent: 'flex-start' }} > {value.title} </label>
              </li>
            );
          })}
        </ul>
      </StyledMenu>
    </div>
  );
}
