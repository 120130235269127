// action - state management
import * as actionTypes from './actions';

export const initialState = {
    opened: true,
    userData: [],
    token: "",
    ipSearchCountPopup: false,
    freePlanSeachCountPopup: false,
    purchasePlanToAccess: false,
    upgradePlan: false,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.USER_DATA:
            return { ...state, userData: action.payload };
        case actionTypes.USER_TOKEN:
            return { ...state, token: action.payload };
        case actionTypes.IPSERAHCOUNT:
            return { ...state, ipSearchCountPopup: action.payload };
        case actionTypes.USERSERACHCOUNT:
            return { ...state, freePlanSeachCountPopup: action.payload };
        case actionTypes.USERSEPURCHASEPLAN:
            return { ...state, purchasePlanToAccess: action.payload };
        case actionTypes.UPGRADEPLAN:
            return { ...state, upgradePlan: action.payload };
        default:
            return state;
    }
};

export default customizationReducer;
