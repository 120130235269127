/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Button, Card, Checkbox, List, ListItem, ListItemButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserModalToolbar from './UserModalToolbar';
import UserListHead from './UserListHead';
import Scrollbar from '../../component/Scrollbar';
import { getAllClient } from '../../service/UserService';
import SearchNotFound from './SearchNotFound';
import { getCustomClient } from '../../service/Library';
import { Loader } from 'rsuite';


const TABLE_HEAD = [
    { id: '', label: '', alignRight: false },
];



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // return array.filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        return array.filter((_) => _?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function SaveDocModal({ docData, activeTab, bookmarkList, handleClose, handleSave }) {
    const [isLoading, setIsLoading] = useState(false)
    const [clientList, setClientList] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [isUpdate, setIsUpdate] = useState(false)
    const [updateId, setupdateId] = useState('')
    const [useCustomClient, setUseCustomClient] = useState(false)

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    console.log("docDatammmmm", docData);

    const filteredClient = applySortFilter(clientList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredClient.length === 0;


    useEffect(() => {
        clientsHandler();
        checkSelected(docData?.contract_id)
    }, [])

    const clientsHandler = async () => {
        setIsLoading(true)
        const res = await getAllClient();
        console.log(res, 'resClient');
        debugger
        if (res?.response?.status === 403) {
            setIsLoading(false)
            setClientList([]);
        }
        else {
            if (res.length === 0) {
                setIsLoading(false)
                setUseCustomClient(true)
                customClientsHandler()
            }
            else if (res) {
                setClientList(res);
                setIsLoading(false)
            }
        }
    };




    const customClientsHandler = async () => {
        const res = await getCustomClient();
        console.log(res.data.data, 'resClient');
        if (res) {
            setClientList(res.data.data);
        }
    };


    const checkSelected = (id) => {
        // let data = bookmarkList?.client_library?.filter((_) => _?.contract_id === id)[0]?.client_id
        debugger
        let data = bookmarkList?.client_library?.filter((_) => _?.contract_id === id)
        const clientIds = data?.map(item => item.client_id);
        if (clientIds) {
            if (Array.isArray(clientIds)) {
                setSelected(clientIds)
            }
            else {
                setSelected([clientIds])
            }
        }
        else {
            setupdateId('')
            setIsUpdate(false)
        }
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    return (
        <Stack style={{ height: '100%' }}>
            <Stack style={{ height: 'calc(100% - 64px)', padding: '10px', paddingBottom: '0' }}>
                {console.log("filteredClient", filteredClient.length)}
                <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />
                {!isLoading ?
                    <Stack sx={{ height: "calc(100% - 70px)", overflow: 'auto', margin: '15px 0' }}>
                        <List>
                            {filteredClient?.map((row) => {
                                const isItemSelected = selected?.indexOf(row.id) !== -1;
                                return (
                                    <ListItem
                                        disablePadding
                                        className="filter-list-item customcheck"
                                        key={row.id}
                                    >
                                        <ListItemButton disablePadding sx={{ padding: '0 5px', }} >
                                            <input type="checkbox"
                                                id={row.name || row.client_name}
                                                checked={isItemSelected}
                                                onChange={(event) => {
                                                    handleSave(isItemSelected, row?.id)
                                                    handleClick(event, row?.id)
                                                }}
                                            />
                                            <label htmlFor={row.name || row.client_name}
                                                style={{ width: '100%', justifyContent: 'flex-start' }}
                                            > {row.name || row.client_name || '-'} </label>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}


                            {isUserNotFound && (
                                <li>
                                    <Stack align="center" colSpan={4} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </Stack>
                                </li>
                            )}
                        </List>
                    </Stack>
                    :
                    <Stack justifyContent={'center'} alignItems={'center'} sx={{ overflow: 'auto', margin: '15px 0', height: '100%' }}>
                        <Loader />
                    </Stack>
                }
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3} style={{ padding: '10px', border: '1px solid #D1D9E0' }}>
                <button
                    className='outline_btn' color="primary" onClick={handleClose}
                    style={{ width: '100%' }}
                >
                    Cancel
                </button>
            </Stack>
        </Stack >
    )
}

export default SaveDocModal